import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Box,
  Button,
  IconButton,
  Tooltip,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Chip,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
  // From
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';

import {
    Alert,
    AlertTitle
} from '@material-ui/lab';

import { IoIosMenu, IoIosTrash, IoMdKey, IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosLock, IoIosJournal, IoMdPrint } from "react-icons/io";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { withSnackbar } from 'notistack';
import axios from "axios";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import moment from 'moment';
import 'moment/locale/es';

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { EmptyList, LoadingList } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API, color } = env;

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableColumns = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Caja' },
  { id: 'opening', label: 'Fecha de apertura' },
  { id: 'iconStatus', label: 'Estado' },
  { id: 'actions', label: 'Opciones', align: 'right' },
];

// Tab panels config
function tabProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

class CashiersList extends Component {
    constructor(props){
        super(props)
        this.state = {
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${this.props.authSession.token}`
          },
          permission: null,
          loading: false,
          loadingList: false,
          showDialogDelete: false,
          showDialogPrint: false,
          showDialogDetails: false,
          tabActive: 0,
          cashiers: [],
          tableRows: [],
          cashierStatus: null,
          cashierSelected: {},
          cashierDetails: [],
          cashierSales: [],
          sidebarToggled: false,
          page: 0,
          rowsPerPage: 10,
          deleteId: 0,
          deleteRegisterId: 0,
          // form
          cashierId: null,
          inputAmount: 0,
          inputDescription: '',
          inputType: 1
        }
    }

    createData(id, name, branch, user, status, opening_amount, closing_amount, missing_amount, real_amount, details, sales, date) {
      let tableOptions = (
        <>
          <Tooltip title="Movimiento de caja" placement="top" style={{margin: 5}}>
            <Fab aria-label="Movimiento de caja" size='small' onClick={ () => this.setState({ showDialogDetails: true, cashierDetails: details, cashierSales: sales, cashierStatus: status, cashierId: id, tabActive: 0 }) }>
              <IoIosJournal size={25} color={ color.secondary } />
            </Fab>
          </Tooltip>

          { status == 1 && details.length > 0 &&
            <Link to={`/dashboard/cashiers/${id}/close`} style={{margin: 5}}>
              <Tooltip title="Cerrar caja" placement="top">
                <Fab aria-label="Cerrar caja" size='small'>
                  <IoIosLock size={25} color={ color.primary } />
                </Fab>
              </Tooltip>
            </Link>
          }
          { status == 1 && details.length == 0 &&
            <Tooltip title="Eliminar caja" placement="top" style={{margin: 5}}>
              <Fab aria-label="Eliminar caja" size='small' onClick={ () => this.setState({ showDialogDelete: true, deleteId: id }) }>
                <IoIosTrash size={25} color={ color.red } />
              </Fab>
            </Tooltip>
          }
          { details.length > 0 && 
            <Tooltip title="Imprimir detalles" placement="top" style={{marginLeft: 10}} >
              <Fab aria-label="Imprimir detalles" size='small' onClick={ () => this.setState({showDialogPrint: true, cashierDetails: details, cashierSales: sales, cashierSelected: {name, branch, user, status, opening_amount, closing_amount, missing_amount, real_amount, date}}) }>
                <IoMdPrint size={25} color={ color.red } />
              </Fab>
            </Tooltip>
          }
        </>
      )
      let title = <>
                    <b>{name}</b> - <small>{branch}</small><br/>
                    <small>Abierta por {user}</small>
                  </>
      let iconStatus = status == 1 ? <Chip label="Abierta" style={{backgroundColor: color.green, color: 'white'}} icon={<IoMdKey size={15} color="white" />} /> : <Chip label="Cerrada" icon={<IoIosLock size={18} />} />
      let opening = <><span>{ moment(date).format('DD [de] MMMM YYYY, h:mm:ss a') }</span><br/><Typography variant="body2" color="textSecondary">{ moment(date).fromNow() }</Typography></>;
      return { id, name: title, opening, iconStatus, status, opening_amount, closing_amount, missing_amount, real_amount, details, sales, actions: tableOptions };
    }

    componentDidMount(){
      // Obtener el rol del usuario
      let { roles } = this.props.authSession.user;
      let role = roles.length > 0 ? roles[0] : {};
      this.setState({permission: [2, 3, 4].find(element => element === role.id)});

      document.title = "Cajas | " + env.appName;
        this.getData()
    }

    getData(){
      this.setState({loadingList: true});
      let { company, user } = this.props.authSession;      
      fetch(`${API}/api/company/${company.id}/${user.id}/cashier/list`, {headers: this.state.headers})
      .then(res => res.json())
      .then(res => {
        this.renderRowsTable(res.cashiers);
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => this.setState({loadingList: false}));
    }

    renderRowsTable(cashiers){
      if(cashiers){
        let rows = [];
        cashiers.map(cashier => {
          rows.push(this.createData(cashier.id, cashier.name, cashier.branch.name, cashier.user.name, cashier.status, cashier.opening_amount, cashier.closing_amount, cashier.missing_amount, cashier.real_amount, cashier.details, cashier.sales, cashier.created_at));
        });
        this.setState({tableRows: rows, cashiers});
      }
    }

    handleDelete = () => {
      let options = {
        headers: this.state.headers
      }
      axios.get(`${API}/api/cashier/${this.state.deleteId}/delete`, options)
      .then(res => {
        if(res.data.cashier_id){
          let { cashier_id } = res.data;

          // Update cashier list
          let { cashiers } = this.state;
          let rows = []
          cashiers.map((item) => {
            if(item.id != cashier_id){
              rows.push(item);
            }
          });

          this.renderRowsTable(rows);

          this.props.enqueueSnackbar('Caja eliminada correctamente!', { variant: 'success' });
        }else{
          this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
          console.log(res.data.message);
        }
      })
      .catch(error => {
        console.log(error)
      })
      .finally( () => {
        this.setState({showDialogDelete: false});
      });

      this.setState({showDialogDelete: false});
    }

    handleSubmit = (event) => {
      event.preventDefault();

      this.setState({loading: true, showDialogDetails: false});
      let { cashierId, inputAmount, inputDescription, inputType } = this.state;
      let { user } = this.props.authSession;
      let params = {
          amount: inputAmount,
          description: inputDescription,
          type: inputType,
          user_id: user.id
      }

      axios({
          method: 'post',
          url: `${API}/api/cashier/${cashierId}/detail/store`,
          data: params,
          headers: this.state.headers
      })
      .then(res => {
        if(res.data.cashier){
          let { cashier } = res.data;
          // Update cashier info
          let { cashiers } = this.state;
          cashiers.map((item, index) => {
            if(cashier.id == item.id){
              cashiers[index] = cashier;
            }
          });
          this.renderRowsTable(cashiers);

          this.props.enqueueSnackbar('Movimiento de caja registrado correctamente!', { variant: 'success' });
        }else{
          this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
          console.log(res.data.message);
        }
      })
      .catch((err) => this.props.enqueueSnackbar('Ocurrió un error en nuestro servidor!', { variant: 'error' }))
      .then(() => this.setState({loading: false, inputAmount: '', inputDescription: '', inputType: 1}));
    }

    render() {
      // Si no tiene permiso redirecciona al dashboard
      if(this.state.permission === undefined){
        return <Redirect to="/dashboard" />
      }
      
      return (
        <>
          { this.state.loading &&
            <Backdrop open={true} style={{ zIndex: 20 }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          }
          <div className='app'>
              <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
              <main style={{padding: 10}}>
                  <Navbar
                    title={
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                            </Grid>
                            <Grid item>
                                <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                  Cajas
                                </h1>
                            </Grid>
                        </Grid>
                    }
                  />
                  
                  <AlertSubscription />

                  <Grid style={{marginTop: 20}}>
                      <div style={{ marginTop: 10, marginBottom: 50 }}>
                          <Paper >
                            { this.state.loadingList && <LoadingList /> }
                            { this.state.tableRows.length === 0 && !this.state.loadingList &&
                              <EmptyList
                                subtitle="No has aperturado ninguna caja hasta el momento."
                              />
                            }
                            { this.state.tableRows.length > 0 &&
                              <>
                                <TableContainer>
                                  <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell style={{backgroundColor: color.primary}} />
                                        {tableColumns.map((column) => (
                                          <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: color.primary, color: 'white', fontWeight: 500 }}
                                          >
                                            {column.label}
                                          </TableCell>
                                        ))}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.tableRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                        return (
                                          <Row key={row.id} row={row} />
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                <TablePagination
                                  rowsPerPageOptions={[10, 25, 50, 100]}
                                  component="div"
                                  count={this.state.tableRows.length}
                                  rowsPerPage={this.state.rowsPerPage}
                                  page={this.state.page}
                                  labelRowsPerPage='Items por página'
                                  onChangePage={(event, newPage) => this.setState({page: newPage})}
                                  onChangeRowsPerPage={(event) => this.setState({rowsPerPage: +event.target.value, page: 0})}
                                />
                              </>
                            }
                          </Paper>
                      </div>
                  </Grid>
              </main>

              {/* Details dialog */}
              <Dialog
                open={ this.state.showDialogDetails }
                TransitionComponent={ transition }
                onClose={ () => this.setState({ showDialogDetails: false }) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth='md'
                scroll='paper'
              >
                <form onSubmit={ this.handleSubmit } >
                  <DialogTitle id="alert-dialog-title">{"Movimientos de caja"}</DialogTitle>
                  <DialogContent id="alert-dialog-description" dividers>
                    <div style={{marginTop: -10}}>
                      <AppBar position="static" style={{backgroundColor: color.secondary, color: 'white', margin: 0}}>
                        <Tabs value={ this.state.tabActive } onChange={(event, newValue) => { this.setState({tabActive: newValue})}} aria-label="Movimientos de caja">
                          <Tab label="Registros de caja" {...tabProps(0)} />
                          {this.state.cashierStatus == 1 && <Tab label="Crear nuevo" {...tabProps(1)} />}
                        </Tabs>
                      </AppBar>
                      <TabPanel value={ this.state.tabActive } index={0}>
                        <TableDetails details={ this.state.cashierDetails } status={ this.state.cashierStatus } headers={ this.state.headers } sales={ this.state.cashierSales } />
                      </TabPanel>
                      <TabPanel value={ this.state.tabActive } index={1}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} style={{ marginBottom: 20 }}>
                            <Alert severity="info">
                              <AlertTitle>Información</AlertTitle>
                              Puedes registrar los ingresos y egresos adicionales a la caja, luego ésta información se reflejará al momento de realizar el arqueo de caja.
                            </Alert>
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                                name="amount"
                                variant="outlined"
                                required
                                fullWidth
                                id="input-amount"
                                label="Monto"
                                placeholder='100'
                                autoFocus
                                helperText="Monto de dinero"
                                value={ this.state.inputAmount }
                                inputProps={{ type: 'number', min: '0.5', step: '0.5' }}
                                onChange={ event => this.setState({inputAmount: event.target.value}) }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="outlined-adornment-password">Tipo de registro</InputLabel>
                                  <Select
                                      labelId="demo-simple-select-filled-label"
                                      id="demo-simple-select-filled"
                                      variant="outlined"
                                      label="Tipo de registro"
                                      inputProps={{ 'aria-label': 'Tipo de registro' }}
                                      required
                                      fullWidth
                                      value={ this.state.inputType }
                                      onChange={ event => this.setState({inputType: event.target.value}) }
                                      >
                                        <MenuItem key={1} value="1">Ingreso</MenuItem>
                                        <MenuItem key={2} value="2">Egreso</MenuItem>
                                  </Select>
                              </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12}>
                              <TextField
                                  variant="outlined"
                                  required
                                  fullWidth
                                  id="input-description"
                                  label="Descripción del registro"
                                  placeholder='Gasto para pago de servicios báasicos'
                                  name="description"
                                  helperText="Debes escribir el motivo por el cual realizaste el ingreso o egreso."
                                  multiline
                                  rows={2}
                                  value={ this.state.inputDescription }
                                  onChange={ event => this.setState({inputDescription: event.target.value}) }
                              />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={ () => this.setState({ showDialogDetails: false }) }>
                      Cerrar
                    </Button>
                    { this.state.tabActive == 1 &&
                      <Button type="submit" style={{ color: color.primary}}>
                        Guardar registro
                      </Button>
                    }
                  </DialogActions>
                </form>
              </Dialog>

              {/* Delete dialog */}
              <Dialog
                open={ this.state.showDialogDelete }
                TransitionComponent={ transition }
                onClose={ () => this.setState({ showDialogDelete: false }) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Ésta acción eliminará de forma permanente el registro y no podrás usarlo en el futuro.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={ () => this.setState({ showDialogDelete: false }) }>
                    Cancelar
                  </Button>
                  <Button onClick={ this.handleDelete } style={{color: color.primary}}>
                    Eliminar
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Print dialog */}
              <Dialog
                open={ this.state.showDialogPrint }
                TransitionComponent={ transition }
                onClose={ () => this.setState({ showDialogPrint: false }) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{"Lista de impresión"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Desea generar la lista de impresión?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={ () => this.setState({ showDialogPrint: false }) }>
                    Cancelar
                  </Button>
                  <ReactToPrint
                    content={() => this.componentRef}
                    documentTitle="Reporte de cajas"
                    pageStyle="margin: 10px;"
                    onBeforePrint={() => this.setState({ showDialogPrint: false })}
                  >
                    <PrintContextConsumer>
                      {({ handlePrint }) => (
                        <Button onClick={ handlePrint } style={{color: color.red}}>
                          Si, generar
                        </Button>
                      )}
                    </PrintContextConsumer>
                  </ReactToPrint>
                </DialogActions>
              </Dialog>

              <div style={{ display: "none" }}>
                <PrintReport
                  company={this.props.authSession.company}
                  data={ this.state.cashierDetails }
                  cashier={ this.state.cashierSelected }
                  sales={ this.state.cashierSales }
                  ref={el => (this.componentRef = el)}
                />
              </div>
          </div>
        </>
      );
    }
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  // Calcular ingresos y gastos
  var opening_amount = parseFloat(row.opening_amount);
  var closing_amount = parseFloat(row.closing_amount);
  var real_amount = parseFloat(row.real_amount);
  var missing_amount = parseFloat(row.missing_amount);

  var surplus_amount =real_amount - closing_amount;
  
  var income = 0;
  var expenses = 0;
  var incomeQr = 0;
  // Obtener todos los ingresos y gastos
  row.details.map(detail => {
    if(detail.deleted_at == null){
      if(detail.type == 1){
        income += parseFloat(detail.amount);
      }else if(detail.type == 2){
        expenses += parseFloat(detail.amount);
      }
    }
  });

  // Obtener todas las ventas pagadas con Qr
  row.sales.map(sale => {
    if(sale.deleted_at == null){
      if(sale.payment_type == 2){
        incomeQr += parseFloat(sale.total - sale.discount);
      }
    }
  });

  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <IoIosArrowDropupCircle /> : <IoIosArrowDropdownCircle />}
          </IconButton>
        </TableCell>
        {tableColumns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow style={{ backgroundColor: color.secondary }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0,  }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Detalle de caja
              </Typography> */}
              <Table size="small" aria-label="purchases" style={{marginBottom: 30}}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{color: 'white'}}><b><small>Monto de apertura</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Ingresos</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Egresos</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Monto de cierre</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Faltante</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Sobrante</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Pagos con Qr/Trasnasacción</small></b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" style={{color: 'white'}}>{ opening_amount }</TableCell>
                    <TableCell component="th" scope="row" style={{color: 'white'}}>{ income.toFixed(2) }</TableCell>
                    <TableCell component="th" scope="row" style={{color: 'white'}}>{ expenses.toFixed(2) }</TableCell>
                    <TableCell component="th" scope="row" style={{color: 'white'}}><b>{ (closing_amount).toFixed(2) }</b></TableCell>
                    <TableCell component="th" scope="row" style={{color: 'white'}}>{ row.status == 2 ? <b style={{color: missing_amount == 0 ? 'white' : color.red}}>{missing_amount.toFixed(2)}</b> : 'no definido' }</TableCell>
                    <TableCell component="th" scope="row" style={{color: 'white'}}><b style={{color: surplus_amount <= 0 ? 'white' : color.primary }}>{ (surplus_amount > 0 ? surplus_amount : 0).toFixed(2) }</b></TableCell>
                    <TableCell component="th" scope="row" style={{color: 'white'}}><b>{ incomeQr.toFixed(2) }</b></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const TableDetails = props => {
  function createData(id, description, type, amount, deleted) {
    let labelType = null;
    if(deleted == null){
      labelType = type == 1 ? <Chip size="small" label="Ingreso" style={{backgroundColor: color.green, color: 'white'}} /> : <Chip size="small" label="Egreso" style={{backgroundColor: color.blue, color: 'white'}} />;
    }else{
      labelType = <Chip size="small" label="Eliminado" style={{backgroundColor: color.red, color: 'white'}} />
    }
    
    return {id, description, labelType, type, amount, deleted };
  }

  const [rows, setRows] = useState([]);
  const [showDialogDeleteRegister, setShowDialogDeleteRegister] = useState(false);
  const [id, setId] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  
  useEffect(() => {
    var items = [];
    let total = 0;
    let totalSales = 0;
    // Obtener monto total de ingresos y egresos
    props.details.map(row => {
      items.push(createData(row.id, row.description, row.type, row.amount, row.deleted_at));
      if(row.deleted_at == null){
        if(row.type == 1){
          total += parseFloat(row.amount);
        }else{
          total -= parseFloat(row.amount);
        }
      }
    });
    // Obtener todas las ventas pagadas con Qr
    props.sales.map(sale => {
      if(sale.deleted_at == null){
        if(sale.payment_type == 2){
          totalSales += parseFloat(sale.total - sale.discount);
        }
      }
    });
    setRows(items);
    setTotal(total);
    setTotalSales(totalSales);
  }, []);

  const handleDeleteRegister = () => {
    setShowDialogDeleteRegister(false);
    axios({
      method: 'post',
      url: `${API}/api/cashier/detail/delete`,
      data: {id},
      headers: props.headers
    })
    .then(res => {
      if(res.data.cashier_detail_id){
        let { cashier_detail_id, deleted_at } = res.data;
        var items = [];
        let total = 0;
        // Obtener monto total de ingresos y egresos
        props.details.map(row => {
          items.push(createData(row.id, row.description, row.type, row.amount, cashier_detail_id == row.id ? deleted_at : row.deleted_at));
          if(cashier_detail_id != row.id){
            if(row.type == 1){
              total += parseFloat(row.amount);
            }else{
              total -= parseFloat(row.amount);
            }
          }
        });
        setRows(items);
        setTotal(total);
      }
    });
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell><b>Detalle</b></TableCell>
              <TableCell align="right"><b>Tipo</b></TableCell>
              <TableCell align="right"><b>Monto</b></TableCell>
              <TableCell style={{ width: 50 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row" style={{ maxWidth: 300 }}>
                  { row.description }
                </TableCell>
                <TableCell align="right">{row.labelType}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                {
                  props.status == 1 ?
                  <TableCell align="right">{row.type == 2 && row.deleted == null ? <Fab aria-label="Eliminar registro" size='small'> <IoIosTrash size={25} color={ color.red } onClick={ () => {setShowDialogDeleteRegister(true); setId(row.id)} } /> </Fab> : '' }</TableCell> : 
                  <TableCell />
                }
              </TableRow>
            ))}
            <TableRow key='00'>
              <TableCell component="th" scope="row"><b>TOTAL PAGOS CON QR/TRANSACCIÓN Bs.</b></TableCell>
              <TableCell />
              <TableCell align="right"><b>{totalSales.toFixed(2)}</b></TableCell>
              <TableCell />
            </TableRow>
            <TableRow key='0'>
              <TableCell component="th" scope="row"><b>TOTAL EN EFECTIVO Bs.</b></TableCell>
              <TableCell />
              <TableCell align="right"><b>{total.toFixed(2)}</b></TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete dialog */}
      <Dialog
        open={ showDialogDeleteRegister }
        TransitionComponent={ transition }
        onClose={ () => setShowDialogDeleteRegister(false) }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ésta acción eliminará de forma permanente el registro y no podrás usarlo en el futuro.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setShowDialogDeleteRegister(false) }>
            Cancelar
          </Button>
          <Button onClick={ handleDeleteRegister } style={{color: color.primary}}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const DetailsCashier = props => {
  const { data, cashier, sales } = props;
  if(data.length == 0){
      return(
          <div>
              <Typography variant="h6" color="textSecondary">No hay datos para mostrar!</Typography>
          </div>
      );
  }

  let total = 0;
  let salesQr = 0;
  data.map(row => {
    // Obtener el total de ingresos y egresos
    if(row.deleted_at == null){
      if(row.type == 1){
        total += parseFloat(row.amount);
      }else{
        total -= parseFloat(row.amount);
      }
    }
  });

  // Obtener todas las ventas pagadas con Qr
  sales.map(sale => {
    if(sale.deleted_at == null){
      if(sale.payment_type == 2){
        salesQr += parseFloat(sale.total - sale.discount);
      }
    }
  });

  return(
    <>
      <div>
        <table width="100%">
          <tr>
            <td>
              <small><b>Sucursal</b></small>
              <Typography variant="body2" color="textSecondary">{ cashier.branch }</Typography>
            </td>
            <td>
              <small><b>Nombre de caja</b></small>
              <Typography variant="body2" color="textSecondary">{ cashier.name }</Typography>
            </td>
            <td>
              <small><b>Abierta por</b></small>
              <Typography variant="body2" color="textSecondary">{ cashier.user }</Typography>
            </td>
          </tr>
          <tr>
            <td>
              <small><b>Monto de apertura</b></small>
              <Typography variant="body2" color="textSecondary">{ cashier.opening_amount }</Typography>
            </td>
            <td>
              <small><b>Monto de cierre</b></small>
              <Typography variant="body2" color="textSecondary">{ cashier.closing_amount }</Typography>
            </td>
            <td>
              <small><b>Estado</b></small>
              <Typography variant="body2" color="textSecondary">{ cashier.status == 1 ? 'Abierta' : 'Cerrada' }</Typography>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead style={{backgroundColor: color.primary, color: 'white', fontWeight: 500}}>
            <tr>
              <th>N&deg;</th>
              <th>Descripción</th>
              <th>Tipo</th>
              <th style={{ textAlign: 'right' }}>Monto</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((item, index) => {
                return(
                  <tr key={item.id}>
                    <td>{ index +1 }</td>
                    <td>{ item.description }</td>
                    <td>{ item.type == 1 ? 'Ingreso' : 'Egreso' }</td>
                    <td style={{ textAlign: 'right', textDecoration: item.deleted_at ? 'line-through' : 'none' }}>{ item.amount }</td>
                  </tr>
                )
              })
            }
            <tr>
              <td colSpan={3} style={{ textAlign: 'right' }}><b>TOTAL EN QR/TRANSACCIONES Bs.</b></td>
              <td style={{ textAlign: 'right' }}><b>{ salesQr.toFixed(2) }</b></td>
            </tr>
            <tr>
              <td colSpan={3} style={{ textAlign: 'right' }}><b>TOTAL EN EFECTIVO Bs.</b></td>
              <td style={{ textAlign: 'right' }}><b>{ total.toFixed(2) }</b></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

const PrintReport = React.forwardRef((props, ref) => {
  const { company, cashier } = props;
  return(
      <div ref={ref}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{marginTop: 10, marginBottom: 40}}>
              {/* Información del restaurante */}
              {
                  company != undefined &&
                  <Grid item>
                      <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                          <Grid item>
                            <img src={ company.logos ? `${API}/storage/${company.logos.replace('.', '-cropped.')}` : `${API}/images/logo.png`} alt='logo' style={{width: 80}} />
                          </Grid>
                          <Grid item style={{marginLeft: 10}}>
                            <b style={{fontSize: 20}}>{ company.name }</b><br/>
                            {
                                company.phones && <><small>{ company.phones }</small><br/></>
                            }
                            {
                                company.address && <><small>{ company.address }</small><br/></>
                            }
                            { company.city && <small>{ company.city.name } - { company.city.state } - { company.city.country }</small> }
                          </Grid>
                      </Grid>
                  </Grid>
              }

              {/* Descripción del reporte */}
              <Grid item style={{textAlign: 'right'}}>
                  <b style={{fontSize: 30}}>Cierre de caja</b><br/>
                  <small>{ moment(cashier.date).format('DD [de] MMMM YYYY, h:mm:ss a') }</small>
              </Grid>
          </Grid>
          <DetailsCashier {...props} />
      </div>
  );
})

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

export default connect(mapStateToProps)(withSnackbar(CashiersList));