import React, { Component } from 'react';
import {
    Grid,
    Paper,
    TextField,
    OutlinedInput,
    InputAdornment,
    Avatar,
    Fab,
    Backdrop,
    CircularProgress,
    Typography,
    CardMedia,
    IconButton,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    // Tables
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
}from '@material-ui/core';
import {
    Autocomplete,
} from '@material-ui/lab';
import { IoIosMenu, IoIosCamera, IoIosBasket, IoIosTrash } from "react-icons/io";
import { Redirect } from "react-router-dom";
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import axios from "axios";
import moment from 'moment';

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { FormButtons } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API, color } = env;
const defaultImg = `${API}/images/default-image.png`;
const DAYS = [
    {id: 1, name: 'Lunes'},
    {id: 2, name: 'Martes'},
    {id: 3, name: 'Miercoles'},
    {id: 4, name: 'Jueves'},
    {id: 5, name: 'Viernes'},
    {id: 6, name: 'Sábado'},
    {id: 7, name: 'Domingo'}
];
const TYPE_DISCOUNT = [
    {id: 'monto', name: 'Monto'},
    {id: 'porcentaje', name: 'Porcentaje'}
];

class OffertsCreateEdit extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            sidebarToggled: false,
            loading: false,
            redirect: false,
            categories: [],
            id: this.props.match.params.id,
            inputType: 'none',
            inputTitle: '',
            inputStart: moment().format('YYYY-MM-DD'),
            inputFinish: moment().format('YYYY-MM-DD'),
            inputNumberDay: '',
            inputObservations: '',
            image: `${API}/images/default-image.png`,
            fileImage: null,
            productsList: [],
            productsDetailsList: [],
        }
    }

    componentDidMount(){
        // Obtener el rol del usuario
        let { company } = this.props.authSession;
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({
            permission: [2, 3].find(element => element === role.id),
            categories: [
                {
                    id: 'diario',
                    name: "Rango de fechas"
                },
                {
                    id: 'semanal',
                    name: "Semanal"
                },
                {
                    id: 'mensual',
                    name: "Mensual"
                }
            ]
        });

        document.title = `${this.state.id ? 'Editar' : 'Crear '} oferta | ${env.appName}`;
        this.getProducts();

        // If edit get data product
        if(this.state.id){
            this.setState({loading: true});
            fetch(`${API}/api/offert/${this.state.id}`, {headers: this.state.headers})
            .then(res => res.json())
            .then(res => {
                let { offert } = res;
                if(offert.company_id !== company.id) {
                    this.setState({permission: undefined});
                    return;
                }
                let productsDetailsList = [];
                offert.details.map(detail => {
                    productsDetailsList.push({
                        id: detail.product.id,
                        name: detail.product.name,
                        price: detail.product.price,
                        type: detail.discount_type,
                        amount: detail.discount_amount
                    });
                });
                this.setState({
                    inputTitle: offert.title,
                    inputType: offert.type,
                    inputStart: offert.start,
                    inputFinish: offert.finish,
                    inputNumberDay: offert.number_day,
                    inputObservations: offert.observations ? offert.observations : '',
                    image: offert.banner ? `${API}/storage/${offert.banner.replace('.', '-medium.')}`: `${API}/images/default-image.png`,
                    productsDetailsList
                });
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => this.setState({loading: false}));
        }
    }

    getProducts(){
        let { company } = this.props.authSession;
        fetch(`${API}/api/company/${company.id}/products/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            this.setState({productsList: res.products});
        })
        .catch(error => {
            console.log(error);
        });
    }

    handleSelectProduct = (event, value, reason) => {
        if(value) {
            if(!this.state.productsDetailsList.find(item => item.id == value.id)) {
                let { productsDetailsList } = this.state;
                let product = this.state.productsList.find(item => item.id == value.id);
                productsDetailsList.push({
                    id: product.id,
                    name: product.name,
                    price: product.price,
                    type: 'monto',
                    amount: 0
                });
                this.setState({productsDetailsList});
            } else {
                console.log('Error')
            }
        }
    }

    hanldeImage = e => {
        try {
            this.setState({image: URL.createObjectURL(e.target.files[0]), fileImage: e.target.files[0]})
        } catch (error) {console.log(error)}
    }

    handleUpdateItem(index, column, value) {
        let { productsDetailsList } = this.state;
        let item = productsDetailsList[index];
        if (column == 'type') {
            productsDetailsList[index] = {
                id: item.id,
                name: item.name,
                price: item.price,
                type: value,
                amount: 0
            }
        } else if (column == 'amount') {

            if (item.type == 'monto') {
                if (item.price - value < 0) {
                    this.props.enqueueSnackbar('No puede exceder el precio', { variant: 'warning' });
                    return 0;
                }
            }else {
                if (value > 100) {
                    this.props.enqueueSnackbar('No puede exceder el 100%', { variant: 'warning' });
                    return 0;
                }
            }

            productsDetailsList[index] = {
                id: item.id,
                name: item.name,
                price: item.price,
                type: item.type,
                amount: value
            }
        }
        this.setState({productsDetailsList});
    }

    handleDeleteItem(index) {
        let { productsDetailsList } = this.state;
        productsDetailsList.splice(index, 1);
        this.setState({productsDetailsList});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if(this.state.inputType === 'none'){
            this.props.enqueueSnackbar('Debes seleccionar una categoría!', { variant: 'warning' });
            return false;
        }

        this.setState({loading: true});
        let { company, user } = this.props.authSession;
        let { id, inputType, inputTitle, inputStart, inputFinish, inputNumberDay, inputObservations, productsDetailsList, fileImage } = this.state;

        let formData = new FormData();
        formData.append("user_id", user.id);
        formData.append("type", inputType);
        formData.append("title", inputTitle);
        formData.append("start", inputStart);
        formData.append("finish", inputFinish);
        formData.append("number_day", inputNumberDay);
        formData.append("observations", inputObservations);
        formData.append('banner', fileImage);
        formData.append("details", JSON.stringify(productsDetailsList));

        // Change URL for update or create
        let url = id ? `${API}/api/offerts/${id}/update` : `${API}/api/company/${company.id}/offerts/create`;

        axios({
            method: 'post',
            url,
            data: formData,
            headers: this.state.headers
        })
        .then(res => {
            if(res.data.offert){
                this.props.enqueueSnackbar(`Oferta ${this.state.id ? 'editada' : 'registrada'} correctamente!`, { variant: 'success' });
                this.props.setProductsTPV([]);
                
                // if(this.state.id){
                    this.setState({redirect: true});
                    return 0;
                // }
            }else{
                this.props.enqueueSnackbar(res.data.error, { variant: 'error' })
                console.log(res.data.message);
            }
        })
        .catch((err) => this.props.enqueueSnackbar('Ocurrió un error en nuestro servidor!', { variant: 'error' }))
        .then(() => this.setState({loading: false}));
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }

        if (this.state.redirect) {
           return <Redirect to='/dashboard/offerts'/>;
        }

        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{paddingTop: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            { this.state.id ? 'Editar' : 'Crear' } oferta
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <AlertSubscription />

                        <Paper style={{ marginTop: 20, backgroundColor: 'white', padding: 30 }}>
                            <form onSubmit={ this.handleSubmit } >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={5}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel htmlFor="select-category">Categoría</InputLabel>
                                                <Select
                                                    id="select-category"
                                                    variant="outlined"
                                                    label="Categoría"
                                                    inputProps={{ 'aria-label': 'Categoría' }}
                                                    required
                                                    fullWidth
                                                    value={ this.state.inputType }
                                                    onChange={ event => {this.setState({inputType: event.target.value, inputNumberDay: ''})} }
                                                    style={{ marginBottom: 20 }}
                                                >
                                                        <MenuItem disabled key='none' value="none">
                                                            <em>Selecciona la categoría</em>
                                                        </MenuItem>
                                                        {
                                                            this.state.categories.map(category => 
                                                                <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                                            )
                                                        }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="name"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="input-name"
                                                label="Título"
                                                placeholder='Viernes de 50% de descuento'
                                                helperText="Título de tu promoción"
                                                value={ this.state.inputTitle }
                                                onChange={ event => this.setState({inputTitle: event.target.value}) }
                                                style={{ marginBottom: 15 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="input-short_description"
                                                label="Descripción"
                                                placeholder='Descripción corta de su promoción'
                                                name="short_description"
                                                // helperText="Ej: Pan, carne, ensalada, huevo, salsas, etc."
                                                value={ this.state.inputObservations }
                                                onChange={ event => this.setState({inputObservations: event.target.value}) }
                                                style={{ marginBottom: 15 }}
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                        {
                                            this.state.inputType == 'diario' && 
                                            <Grid item xs={12}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="input-start"
                                                            name="start"
                                                            label="Desde"
                                                            value={ this.state.inputStart }
                                                            onChange={ event => this.setState({inputStart: event.target.value}) }
                                                            inputProps={{ type: 'date', min: this.state.id ? null : moment().format('YYYY-MM-DD') }}
                                                            style={{ marginBottom: 15 }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="input-finish"
                                                            name="finish"
                                                            label="Hasta"
                                                            value={ this.state.inputFinish }
                                                            onChange={ event => this.setState({inputFinish: event.target.value}) }
                                                            inputProps={{ type: 'date', min: this.state.id ? null : moment().format('YYYY-MM-DD') }}
                                                            style={{ marginBottom: 15 }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                        {
                                            this.state.inputType == 'semanal' && 
                                            <Grid item xs={12}>
                                                <FormControl fullWidth variant="outlined">
                                                    <InputLabel htmlFor="select-day">Día de la semana</InputLabel>
                                                    <Select
                                                        id="select-day"
                                                        variant="outlined"
                                                        label="Día de la semana"
                                                        inputProps={{ 'aria-label': 'Día de la semana' }}
                                                        required
                                                        fullWidth
                                                        value={ this.state.inputNumberDay }
                                                        onChange={ event => {this.setState({inputNumberDay: event.target.value})} }
                                                        style={{ marginBottom: 20 }}
                                                    >
                                                            <MenuItem disabled key='none' value="none">
                                                                <em>Selecciona el día de la semana</em>
                                                            </MenuItem>
                                                            {
                                                                DAYS.map(day => 
                                                                    <MenuItem key={day.id} value={day.id}>{day.name}</MenuItem>
                                                                )
                                                            }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        }
                                        {
                                            this.state.inputType == 'mensual' && 
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="input-number_day"
                                                    name="number_day"
                                                    label="Día del mes"
                                                    placeholder='Día del mes en que es válida la promoción'
                                                    value={ this.state.inputNumberDay }
                                                    onChange={ event => this.setState({inputNumberDay: event.target.value}) }
                                                    inputProps={{ type: 'number', step: 1, min: 1, max: 31 }}
                                                    style={{ marginBottom: 15 }}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} style={{paddingLeft: 20, paddingRight: 20,}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={3} style={{paddingTop: 30, paddingLeft: 20, paddingRight: 20,}}>
                                                    <div style={{ display: 'flex', justifyContent:'center' }}>
                                                        <label htmlFor="input-image">
                                                            <Tooltip title={`Click para ${ this.state.id ? 'editar' : 'agregar' } Banner de la promoción`} placement="top">
                                                                <IconButton aria-label={`Click para ${ this.state.id ? 'editar' : 'agregar' } Banner de la promoción`} component="span">
                                                                    <IoIosCamera size={30} color="gray" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </label>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} sm={9} style={{paddingLeft: 20, paddingRight: 20,}}>
                                                    <input type="file" style={{ display: 'none' }} id="input-image" name="image" accept="image/*" onChange={ this.hanldeImage }/>
                                                    <CardMedia
                                                        style={{ width: '200px', height: '100px', position: 'relative', zIndex: 1, border: '3px solid #E2E3E3', marginBottom: 10 }}
                                                        image={ this.state.image }
                                                        title="Banner de la promoción"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={7}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    id="combo-products"
                                                    fullWidth
                                                    options={ this.state.productsList }
                                                    getOptionLabel={(option) => `${option.name}`}
                                                    renderInput={(params) => <TextField {...params} label="Nombre del producto" variant="outlined" />}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Grid container>
                                                                <Grid item style={{marginRight: 10}}>
                                                                    <Avatar src={option.image ? `${API}/storage/${option.image.replace('.', '-cropped.')}` : defaultImg} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <div>
                                                                        <b>{ option.name }</b>
                                                                        <br/>
                                                                        <small>{ option.type }</small>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )}
                                                    // inputValue={false}
                                                    noOptionsText='No hay resultados'
                                                    style={{ marginBottom: 4 }}
                                                    onChange={ this.handleSelectProduct }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                {
                                                    !this.state.productsDetailsList.length &&
                                                    <Grid
                                                        container
                                                        direction="column"
                                                        justify="center"
                                                        alignItems="center"
                                                        style={{marginTop: 50}}
                                                    >
                                                        {/* <div style={{marginBottom: 10}}>
                                                            <img src="/img/dashboard/cart-empty.png" style={{width: 200}}/>
                                                        </div> */}
                                                        <Typography color="textSecondary" variant='h5'>Lista de productos vacía <IoIosBasket size={30} /> </Typography>
                                                    </Grid>
                                                }
                                                {
                                                    this.state.productsDetailsList.length > 0 &&
                                                    <TableContainer component={Paper} style={{maxHeight: 380}}>
                                                        <Table aria-label="simple table">
                                                            <TableHead>
                                                                <TableRow style={{backgroundColor: color.primary}}>
                                                                    <TableCell style={{color: 'white'}}>N&deg;</TableCell>
                                                                    <TableCell style={{color: 'white'}}>Producto</TableCell>
                                                                    <TableCell align="right" style={{color: 'white'}}>Tipo</TableCell>
                                                                    <TableCell align="right" style={{color: 'white'}}>Cantidad</TableCell>
                                                                    <TableCell align="right" style={{color: 'white'}}>Precio&nbsp;(Bs)</TableCell>
                                                                    <TableCell></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                            {this.state.productsDetailsList.map((row, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{ index +1 }</TableCell>
                                                                    <TableCell component="th" scope="row">
                                                                        {row.name} <br />
                                                                        <Typography variant='caption'>{ row.price }</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <FormControl size="small">
                                                                            <Select
                                                                                variant="outlined"
                                                                                required
                                                                                fullWidth
                                                                                value={ row.type }
                                                                                onChange={ event => { this.handleUpdateItem(index, 'type', event.target.value) } }
                                                                            >
                                                                                {
                                                                                    TYPE_DISCOUNT.map(item => 
                                                                                        <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                                                    )
                                                                                }
                                                                            </Select>
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <FormControl variant="outlined" fullWidth size="small">
                                                                            <OutlinedInput
                                                                                placeholder='10'
                                                                                value={ row.amount }
                                                                                endAdornment={<InputAdornment position="end">{ row.type == 'monto' ? <Typography variant='caption'>Bs.</Typography> : '%' }</InputAdornment>}
                                                                                onChange={ event => { this.handleUpdateItem(index, 'amount', event.target.value) } }
                                                                                style={{ maxWidth: 120}}
                                                                                inputProps={{ type: 'number', step: 0.5, min: 0.5 }}
                                                                            />
                                                                        </FormControl>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Typography variant='h6'>{ Number.parseFloat(row.price - (row.type == 'porcentaje' ? row.price * (row.amount /100) : row.amount)).toFixed(2) }</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="right">
                                                                        <Tooltip title="Eliminar item" placement="top">
                                                                            <Fab aria-label="Eliminar item" size='small' onClick={ () => this.handleDeleteItem(index) }>
                                                                                <IoIosTrash size={25} style={{color: color.red}} />
                                                                            </Fab>
                                                                        </Tooltip>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <FormButtons back='/dashboard/offerts' titleSuccess={ this.state.id ? 'Actualizar' : 'Guardar' } />
                            </form>
                        </Paper>
                    </main>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProductsTPV : (productsTPV) => dispatch({
            type: 'SET_PRODUCTS_TPV',
            payload: productsTPV
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(OffertsCreateEdit));