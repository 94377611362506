import React, { Component } from 'react';
import {
    Grid,
    Paper,
    FormControl,
    Backdrop,
    CircularProgress,
    Typography,
    Select,
    OutlinedInput,
    TextField,
    InputAdornment,
    IconButton,
    Divider,
    AppBar,
    Tabs,
    Tab,
    Box,
    Switch,
    MenuItem
}from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import PropTypes from 'prop-types';
import { IoIosMenu, IoMdPrint } from "react-icons/io";
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import 'moment/locale/es';
import axios from "axios";

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { env } from '../../../env';

const { API, color } = env;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }


class Settings extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            sidebarToggled: false,
            loading: false,
            tabIndex: 0,
            helpTour: this.props.globalConfig.help.tour,
            salesPrint: this.props.globalConfig.sales.print,
            helpTips: this.props.globalConfig.help.tips,
            platform: this.props.globalConfig.platform,
            // Impresión
            printerType: this.props.globalConfig.sales.printerType,
            printerName: this.props.globalConfig.sales.printerName,
            printerServerUrl: this.props.globalConfig.sales.printerServerUrl,
            printKitchenTickets: this.props.globalConfig.sales.printKitchenTickets,
            // Pantalla de tickets
            alertTickets: this.props.globalConfig.sales.alertTickets,
            timeSliderTickets: this.props.globalConfig.sales.timeSliderTickets,
            // Pedidos desde la mesa
            tableOrders: this.props.globalConfig.sales.tableOrders
        }
    }

    componentDidMount(){
        document.title = "Configuración | " + env.appName;
    }

    async handleHelpConfig(){
        let { platform, helpTour, helpTips, salesPrint, printerType, printerName, printerServerUrl, printKitchenTickets, alertTickets, timeSliderTickets, tableOrders } = this.state;
        let config = {
            ...this.props.globalConfig,
            platform: platform,
            help: {
                ...this.props.globalConfig.help,
                tour: helpTour,
                tips: helpTips
            },
            sales: {
                ...this.props.globalConfig.sales,
                printerType,
                printerName,
                printerServerUrl,
                printKitchenTickets,
                print: salesPrint,
                alertTickets,
                timeSliderTickets,
                tableOrders
            }
        }
        await AsyncStorage.setItem('sessionGlobalConfig', JSON.stringify(config));
        this.props.setGlobalConfig(config);
    }

    updateOrdersTable(status){
        // Actualizar estado local
        this.setState({tableOrders: status}, () => this.handleHelpConfig());

        let { company } = this.props.authSession;
        axios({
            method: 'post',
            url: `${API}/api/company/${company.id}/update_orders_table`,
            data: JSON.stringify({orders_table: status ? 1 : 0}),
            headers: this.state.headers
        })
        .then(res => {
            if(res.error){
                // Si existe un error se debe volver al estado anterior
                this.setState({tableOrders: !status}, () => this.handleHelpConfig());
                this.props.enqueueSnackbar(res.error, { variant: 'error' });
                console.log(res.message);
            }
        });
    }

    printTesting(){
        let body = {
            "platform": this.props.globalConfig.platform,
            "printer_name": this.state.printerName
          };
        let options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: this.state.headers
        }
        let slash = this.state.printerServerUrl.substr(this.state.printerServerUrl.length -1) != '/' ? '/' : '';
        fetch(`${this.state.printerServerUrl}${slash}api/test`, options)
        .then(function(response) {
            if(response.ok)
            {
            return response.text();         
            }
            throw new Error('Something went wrong.');
        })
        .then(function(text) {
            console.log('Request successful', text);  
        })  
        .catch(function(error) {
            console.log('Request failed', error);
        });
    }

    render() {
        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            Configuración
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />
                        
                            <form onSubmit={ this.handleSubmit } >
                                <Grid container spacing={2} direction="row" justify="center" alignItems="flex-start">
                                    <Grid item xs={12} sm={12}>
                                        <Paper style={{ backgroundColor: 'white', paddingBottom: 30, marginTop: 50}}>
                                            {/* <Grid container direction="column" justify="center" alignItems="center">
                                                <Grid item xs={12}>
                                                    
                                                </Grid>
                                            </Grid> */}

                                            <div>
                                                <AppBar position="static" style={{backgroundColor: color.secondary, color: 'white', margin: 0}}>
                                                    <Tabs
                                                        value={this.state.tabIndex}
                                                        onChange={(event, newValue) => this.setState({tabIndex: newValue})}
                                                        variant="scrollable"
                                                        scrollButtons="auto"
                                                        aria-label="full width tabs example"
                                                    >
                                                    <Tab label="Ventas" {...a11yProps(0)} style={{ fontSize: 11 }} />
                                                    <Tab label="Pantalla de Tickes" {...a11yProps(1)} style={{ fontSize: 11 }} />
                                                    <Tab label="Otros" {...a11yProps(2)} style={{ fontSize: 11 }} />
                                                    </Tabs>
                                                </AppBar>
                                                <SwipeableViews
                                                    // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                    index={this.state.tabIndex}
                                                    onChangeIndex={index => this.setState({tabIndex: index})}
                                                >
                                                    <TabPanel value={this.state.tabIndex} index={0}>
                                                        <Grid container>
                                                            <Grid item md={10} xs={12}>
                                                                <Typography variant="body1">Recibo de ventas</Typography>
                                                                <Typography variant="caption">Habilita o deshabilita la impresión de recibos al realizar una venta.</Typography>
                                                            </Grid>
                                                            <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                <Switch checked={ this.state.salesPrint } onChange={ (e) => this.setState({salesPrint: !this.state.salesPrint}, () => this.handleHelpConfig()) } name="switch-salesPrint" color="primary" />
                                                            </Grid>
                                                        </Grid>
                                                        {
                                                            this.state.salesPrint &&
                                                            <>
                                                                <Divider style={{width: '100%', margin: '10px 0px'}} />
                                                                <Grid container>
                                                                    <Grid item md={10} xs={12}>
                                                                        <Typography variant="body1">Tipo de impresora</Typography>
                                                                        <Typography variant="caption">Puedes seleccionar el tipo de impresora que usas.</Typography>
                                                                    </Grid>
                                                                    <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                        <Select
                                                                            labelId="select-print-type-label"
                                                                            id="select-print-type"
                                                                            value={ this.state.printerType }
                                                                            onChange={ event => this.setState({printerType: event.target.value}, () => this.handleHelpConfig()) }
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        >
                                                                            <MenuItem value="normal">Normal</MenuItem>
                                                                            <MenuItem value="termica">Térmica</MenuItem>
                                                                            {/* <MenuItem value="ethernet">Térmica - Red</MenuItem> */}
                                                                            {/* <MenuItem value="test">Testing</MenuItem> */}
                                                                        </Select>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        }
                                                        {
                                                            this.state.salesPrint && this.state.printerType == 'termica' &&
                                                            <>
                                                                <Divider style={{width: '100%', margin: '10px 0px'}} />
                                                                <Grid container>
                                                                    <Grid item md={6} xs={12}>
                                                                        <Typography variant="body1">Datos de la impresora térmica</Typography>
                                                                        <Typography variant="caption">Ingrese la url del servidor local y el nombre de la impresora.</Typography>
                                                                    </Grid>
                                                                    <Grid item md={4} xs={12} style={{ textAlign: 'right' }}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            placeholder='http://localhost/print/public/'
                                                                            value={ this.state.printerServerUrl }
                                                                            onChange={ event => this.setState({printerServerUrl: event.target.value}, () => this.handleHelpConfig()) }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                        <OutlinedInput
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            placeholder='ZKP8008'
                                                                            value={ this.state.printerName }
                                                                            onChange={ event => this.setState({printerName: event.target.value}, () => this.handleHelpConfig()) }
                                                                            endAdornment={
                                                                                this.state.printerServerUrl && this.state.printerName &&
                                                                                <InputAdornment position="end">
                                                                                    <IconButton
                                                                                        aria-label="Impresión de prueba"
                                                                                        title='Impresión de prueba'
                                                                                        onClick={ () => this.printTesting() }
                                                                                    >
                                                                                        <IoMdPrint />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                              }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        }
                                                        {
                                                            this.state.salesPrint &&
                                                            <>
                                                                <Divider style={{width: '100%', margin: '10px 0px'}} />
                                                                <Grid container>
                                                                    <Grid item md={10} xs={12}>
                                                                        <Typography variant="body1">Imprimir comanda</Typography>
                                                                        <Typography variant="caption">Habilita o deshabilita la impresión de la comanda para la cocina.</Typography>
                                                                    </Grid>
                                                                    <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                        <Switch checked={ this.state.printKitchenTickets } onChange={ (e) => this.setState({printKitchenTickets: !this.state.printKitchenTickets}, () => this.handleHelpConfig()) } name="switch-print-kitchen-tickets" color="primary" />
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        }
                                                        <Divider style={{width: '100%', margin: '10px 0px'}} />
                                                        <Grid container>
                                                            <Grid item md={10} xs={12}>
                                                                <Typography variant="body1">Pedidos desde la mesa</Typography>
                                                                <Typography variant="caption">Habilita o deshabilita la opción de que el cliente escanee el código QR de las mesas y realice el pedido desde su celular.</Typography>
                                                            </Grid>
                                                            <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                <Switch checked={ this.state.tableOrders } onChange={ (e) => this.updateOrdersTable(!this.state.tableOrders) } name="switch-print-kitchen-tickets" color="primary" />
                                                            </Grid>
                                                        </Grid>
                                                    </TabPanel>
                                                    <TabPanel value={this.state.tabIndex} index={1}>
                                                        <Grid container>
                                                            <Grid item md={10} xs={12}>
                                                                <Typography variant="body1">Tipo de alerta</Typography>
                                                                <Typography variant="caption">Puedes seleccionar el tipo de alerta que deseas que se reproduzca en la pantalla de tickeks cuando un pedido esté listo.</Typography>
                                                            </Grid>
                                                            <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                <Select
                                                                    labelId="select-print-type-label"
                                                                    id="select-alert-type"
                                                                    fullWidth
                                                                    value={ this.state.alertTickets }
                                                                    onChange={ event => this.setState({alertTickets: event.target.value}, () => this.handleHelpConfig()) }
                                                                    variant="outlined"
                                                                >
                                                                    <MenuItem value="none">Ninguna</MenuItem>
                                                                    <MenuItem value="voice">Voz</MenuItem>
                                                                    <MenuItem value="sound">Sonido</MenuItem>
                                                                    {/* <MenuItem value="test">Testing</MenuItem> */}
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{width: '100%', margin: '10px 0px'}} />
                                                        <Grid container>
                                                            <Grid item md={10} xs={12}>
                                                                <Typography variant="body1">Tiempo de publicidad</Typography>
                                                                <Typography variant="caption">Tiempo que tarda la publicidad de la pantalla de tickets en transicionar a la siguiente.</Typography>
                                                            </Grid>
                                                            <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    placeholder='10'
                                                                    inputProps={{ type: 'number', min: '3', max: 300, step: '1' }}
                                                                    value={ this.state.timeSliderTickets ? this.state.timeSliderTickets : 10 }
                                                                    onChange={ event => this.setState({timeSliderTickets: event.target.value}, () => this.handleHelpConfig()) }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TabPanel>
                                                    <TabPanel value={this.state.tabIndex} index={2}>
                                                        <Grid container>
                                                            <Grid item md={10} xs={12}>
                                                                <Typography variant="body1">Sistema operativo</Typography>
                                                                <Typography variant="caption">Elija el sistema operativo que está usando (Windows o Linux).</Typography>
                                                            </Grid>
                                                            <Grid item md={2} xs={12} style={{ textAlign: 'right' }}>
                                                                <Select
                                                                    labelId="select-print-type-label"
                                                                    id="select-alert-type"
                                                                    fullWidth
                                                                    value={ this.state.platform }
                                                                    onChange={ event => this.setState({platform: event.target.value}, () => this.handleHelpConfig()) }
                                                                    variant="outlined"
                                                                >
                                                                    <MenuItem value="windows">Windows</MenuItem>
                                                                    <MenuItem value="linux">Linux</MenuItem>
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                        <Divider style={{width: '100%', margin: '10px 0px'}} />
                                                    </TabPanel>
                                                </SwipeableViews>
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </form>
                    </main>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
        globalConfig: state.globalConfig,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthSession : (authSession) => dispatch({
            type: 'SET_AUTH_SESSION',
            payload: authSession
        }),
        setGlobalConfig : (globalConfig) => dispatch({
            type: 'SET_GLOBAL_CONFIG',
            payload: globalConfig
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Settings));