import React, { Component } from 'react'
import { CircularProgress } from '@material-ui/core';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { GuardProvider, GuardedRoute } from 'react-router-guards';
import { connect } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

// LandingPage
import Index from "./landingpage/views/index";

// Admin
import Login from "./dashboard/views/auth/login/login";
import Register from "./dashboard/views/auth/register/register";
import Home from "./dashboard/views/home/home";
import MyCompany from "./dashboard/views/mycompany/mycompany";
// Branches
import BranchesList from "./dashboard/views/branches/branchesList";
import BranchesCreateEdit from "./dashboard/views/branches/branchesCreateEdit";

// Products
import ProductsList from "./dashboard/views/products/productsList";
import ProductsCreateEdit from "./dashboard/views/products/productsCreateEdit";

// Products Cateories
import ProductsCategoriesList from "./dashboard/views/productsCategories/productsCategoriesList";
import ProductsCategoriesCreateEdit from "./dashboard/views/productsCategories/productsCategoriesCreateEdit";

// Offerts
import OffertsList from "./dashboard/views/offerts/offertsList";
import OffertsCreateEdit from "./dashboard/views/offerts/offertsCreateEdit";

// Cashiers
import CashiersList from "./dashboard/views/cashiers/cashiersList";
import CashiersClose from "./dashboard/views/cashiers/cashiersClose";

// Purchases
import PurchasesList from "./dashboard/views/purchases/purchasesList";
import PurchasesCreateEdit from "./dashboard/views/purchases/purchasesCreateEdit";

// Sales
import SalesList from "./dashboard/views/sales/salesList";
import SalesKitchenList from "./dashboard/views/sales/salesKitchenList";
import SalesCreate from "./dashboard/views/sales/salesCreate";
import Receipt from "./dashboard/views/sales/print/receipt";
import Tickets from "./dashboard/views/tickets/tickets";
import PublicationsList from "./dashboard/views/tickets/publicationsList";

// Order
import OrderIndex from "./dashboard/views/order/orderIndex";
import QrGenerator from "./dashboard/views/order/qrGenerator";

// Employes
import EmployesList from "./dashboard/views/employes/employesList";
import EmployesCreateEdit from "./dashboard/views/employes/EmployesCreateEdit";

// Reports
import SalesReports from "./dashboard/views/reports/sales";
import CashiersReports from "./dashboard/views/reports/cashiers";
import PurchasesReports from "./dashboard/views/reports/purchases";
// import ProductsReports from "./dashboard/views/reports/products";

// Config
import Profile from "./dashboard/views/config/profile";
import Settings from "./dashboard/views/config/settings";
import About from "./dashboard/views/about/about";

// Pages default
import Error404 from "./dashboard/views/errors/404";
import Suspended from "./dashboard/views/errors/suspended";

import JsonData from './landingpage/data/data.json';
import { env } from './env';

const Loading = () => {
  return(
    <div style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
      <CircularProgress/>
    </div>
  );
}

const { API } = env;

export class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      landingPageData: {},
    }
  }

  async getAuthLogin(){
    try {
      const sessionAuthSession = await AsyncStorage.getItem('sessionAuthSession');
      let authSession = sessionAuthSession ? JSON.parse(sessionAuthSession) : {};
      this.props.setAuthSession(authSession);
    } catch (e) {
    }
  }

  componentDidMount() {
    this.getSubscriptionInfo();
    this.getlandingPageData();
    this.loadGlobalConfig();
  }

  async getSubscriptionInfo() {
    var sessionAuthSession = await AsyncStorage.getItem('sessionAuthSession');
    // Si no existe ninguna sesión
    if(!sessionAuthSession.user){
      return 0;
    }
    var authSession = sessionAuthSession ? JSON.parse(sessionAuthSession) : {};
    let headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      'Authorization': `Bearer ${authSession.token}`
    }
    let options = {
      method: 'GET', headers
    }
    fetch(`${API}/api/user/${authSession.user.id}/subscription`, options)
      .then(async (response) => {
          if(response.ok) {
            return response.json();
          }
          throw new Error('Ocurrió un error');
      })
      .then(async (res) => {
        if(res.subscription){
          authSession = {
            ...authSession,
            subscription: res.subscription
          };
          this.props.setAuthSession(authSession);
          await AsyncStorage.setItem('sessionAuthSession', JSON.stringify(authSession));
        }
      })  
      .catch(function(error) {
          console.log('Request failed', error);
      });
  }

  async loadGlobalConfig(){
    const sessionGlobalConfig = await AsyncStorage.getItem('sessionGlobalConfig');
    let globalConfig = sessionGlobalConfig ? JSON.parse(sessionGlobalConfig) : this.props.globalConfig;
    this.props.setGlobalConfig(globalConfig);
  }

  requireLogin = async (to, from, next) => {
    await this.getAuthLogin();
    if (to.meta.auth) {
      if (this.props.authSession.user) {
        if (to.meta.routeLogin) {
          next.redirect('/dashboard');
        }
        next();
      }
      next.redirect('/login');
    } else {
      next();
    }
  };

  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  render() {
    return (
      <Router>
        <GuardProvider guards={[this.requireLogin]} loading={Loading} error={Error404}>
          <Switch>
            {/* LandingPage */}
            <GuardedRoute exact path="/" render={(props) => <Index data={ this.state.landingPageData } {...props}/>} />

            {/* Orders */}
            <GuardedRoute exact path="/order/:companySlug/:branchId/:tableNumber" render={(props) => <OrderIndex {...props}/>} />

            {/* Dashboard */}

            {/* Auth */}
            <GuardedRoute exact path="/login" meta={{ auth: true, routeLogin: true }} render={(props) => <Login {...props}/>} />
            <GuardedRoute exact path="/register" meta={{ routeLogin: true }} render={(props) => <Register {...props}/>} />

            {/* Dashboard */}
            <GuardedRoute exact path="/dashboard" meta={{ auth: true }} render={(props) => <Home {...props}/>} />

            {/* Dashboard */}
            <GuardedRoute exact path="/dashboard/mycompany" meta={{ auth: true }} render={(props) => <MyCompany {...props}/>} />

            <GuardedRoute exact path="/dashboard/branches" meta={{ auth: true }} render={(props) => <BranchesList {...props}/>} />
            <GuardedRoute exact path="/dashboard/branches/create" meta={{ auth: true }} render={(props) => <BranchesCreateEdit {...props}/>} />
            <GuardedRoute exact path="/dashboard/branches/:id/edit" meta={{ auth: true }} render={(props) => <BranchesCreateEdit {...props}/>} />

            <GuardedRoute exact path="/dashboard/products" meta={{ auth: true }} render={(props) => <ProductsList {...props}/>} />
            <GuardedRoute exact path="/dashboard/products/create" meta={{ auth: true }} render={(props) => <ProductsCreateEdit {...props}/>} />
            <GuardedRoute exact path="/dashboard/products/:id/edit" meta={{ auth: true }} render={(props) => <ProductsCreateEdit {...props}/>} />

            <GuardedRoute exact path="/dashboard/productscategories" meta={{ auth: true }} render={(props) => <ProductsCategoriesList {...props}/>} />
            <GuardedRoute exact path="/dashboard/productscategories/create" meta={{ auth: true }} render={(props) => <ProductsCategoriesCreateEdit {...props}/>} />
            <GuardedRoute exact path="/dashboard/productscategories/:id/edit" meta={{ auth: true }} render={(props) => <ProductsCategoriesCreateEdit {...props}/>} />

            <GuardedRoute exact path="/dashboard/offerts" meta={{ auth: true }} render={(props) => <OffertsList {...props}/>} />
            <GuardedRoute exact path="/dashboard/offerts/create" meta={{ auth: true }} render={(props) => <OffertsCreateEdit {...props}/>} />
            <GuardedRoute exact path="/dashboard/offerts/:id/edit" meta={{ auth: true }} render={(props) => <OffertsCreateEdit {...props}/>} />

            <GuardedRoute exact path="/dashboard/cashiers" meta={{ auth: true }} render={(props) => <CashiersList {...props}/>} />
            <GuardedRoute exact path="/dashboard/cashiers/:id/close" meta={{ auth: true }} render={(props) => <CashiersClose {...props}/>} />

            <GuardedRoute exact path="/dashboard/sales" meta={{ auth: true }} render={(props) => <SalesList {...props}/>} />
            <GuardedRoute exact path="/dashboard/kitchen" meta={{ auth: true }} render={(props) => <SalesKitchenList {...props}/>} />
            <GuardedRoute exact path="/dashboard/sales/create" meta={{ auth: true }} render={(props) => <SalesCreate {...props}/>} />
            <GuardedRoute exact path="/dashboard/sales/print/:id" meta={{ auth: true }} render={(props) => <Receipt {...props}/>} />
            <GuardedRoute exact path="/dashboard/tickets" meta={{ auth: true }} render={(props) => <Tickets {...props}/>} />
            <GuardedRoute exact path="/dashboard/publications" meta={{ auth: true }} render={(props) => <PublicationsList {...props} />} />

            <GuardedRoute exact path="/dashboard/purchases" meta={{ auth: true }} render={(props) => <PurchasesList {...props}/>} />
            <GuardedRoute exact path="/dashboard/purchases/create" meta={{ auth: true }} render={(props) => <PurchasesCreateEdit {...props}/>} />

            <GuardedRoute exact path="/dashboard/employes" meta={{ auth: true }} render={(props) => <EmployesList {...props}/>} />
            <GuardedRoute exact path="/dashboard/employes/create" meta={{ auth: true }} render={(props) => <EmployesCreateEdit {...props}/>} />
            <GuardedRoute exact path="/dashboard/employes/:id/edit" meta={{ auth: true }} render={(props) => <EmployesCreateEdit {...props}/>} />

            <GuardedRoute exact path="/dashboard/reports/sales" meta={{ auth: true }} render={(props) => <SalesReports {...props}/>} />
            <GuardedRoute exact path="/dashboard/reports/cashiers" meta={{ auth: true }} render={(props) => <CashiersReports {...props}/>} />
            <GuardedRoute exact path="/dashboard/reports/purchases" meta={{ auth: true }} render={(props) => <PurchasesReports {...props}/>} />
            {/* <GuardedRoute exact path="/dashboard/reports/products" meta={{ auth: true }} render={(props) => <ProductsReports {...props}/>} /> */}

            <GuardedRoute exact path="/dashboard/profile" meta={{ auth: true }} render={(props) => <Profile {...props}/>} />
            <GuardedRoute exact path="/dashboard/settings" meta={{ auth: true }} render={(props) => <Settings {...props}/>} />
            <GuardedRoute exact path="/dashboard/about" meta={{ auth: true }} render={(props) => <About {...props}/>} />

            <GuardedRoute exact path="/dashboard/qr" meta={{ auth: true }} render={(props) => <QrGenerator {...props}/>} />

            <GuardedRoute exact path="/suspended" meta={{ auth: true }} render={(props) => <Suspended {...props}/>} />

            {/* Not found */}
            <Route path="*" component={Error404} />
          </Switch>
        </GuardProvider>
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authSession: state.authSession,
    globalConfig: state.globalConfig,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthSession : (authSession) => dispatch({
      type: 'SET_AUTH_SESSION',
      payload: authSession
    }),
    setGlobalConfig : (globalConfig) => dispatch({
      type: 'SET_GLOBAL_CONFIG',
      payload: globalConfig
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);