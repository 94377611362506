import React, { Component } from 'react';
import {
  Grid,
  Button,
  Tooltip,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Chip,
  Avatar
} from '@material-ui/core';

import { IoIosMenu, IoIosAddCircle, IoIosCreate, IoIosTrash } from "react-icons/io";
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import axios from "axios";
import { withSnackbar } from 'notistack';

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { EmptyList, LoadingList } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API, color } = env;

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableColumns = [
  { id: 'id', label: 'ID' },
  { id: 'name', label: 'Información' },
  { id: 'branch', label: 'Sucursal' },
  { id: 'actions', label: 'Opciones', align: 'right' },
];

class EmployesList extends Component {
    constructor(props){
        super(props)
        this.state = {
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
            'Authorization': `Bearer ${this.props.authSession.token}`
          },
          permission: null,
          loadingList: false,
          showDialog: false,
          tableRows: [],
          sidebarToggled: false,
          page: 0,
          rowsPerPage: 10,
          deleteId: 0,
        }
    }

    createData(id, name, user, branch) {
      let tableOptions = (
        <>
          <Link to={`/dashboard/employes/${id}/edit`} style={{marginRight: 10}}>
            <Tooltip title="Editar empleado" placement="top">
              <Fab aria-label="Editar empleado" size='small'>
                <IoIosCreate size={25} style={{color: color.skyBlue}} />
              </Fab>
            </Tooltip>
          </Link>
          <Tooltip title="Eliminar empleado" placement="top">
            <Fab aria-label="Eliminar empleado" size='small' onClick={ () => this.setState({ showDialog: true, deleteId: id }) }>
              <IoIosTrash size={25} style={{color: color.red}} />
            </Fab>
          </Tooltip>
        </>
      )

      let rol = user.roles.length ? user.roles[0].name : 'no definido';
      let title = <>
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar alt={user.name} src={user.avatar ? `${API}/storage/${user.avatar.replace('.', '-cropped.')}` : user.name} />
                      </Grid>
                      <Grid item xs zeroMinWidth>
                        <b>{name}</b><br/>
                        <Chip label={rol} size="small" />
                      </Grid>
                    </Grid>
                  </>
      return { id, name: title, branch, actions: tableOptions };
    }

    componentDidMount(){
      // Obtener el rol del usuario
      let { roles } = this.props.authSession.user;
      let role = roles.length > 0 ? roles[0] : {};
      this.setState({permission: [2, 3].find(element => element === role.id)});

      document.title = "Empleados | " + env.appName;
        this.getData()
    }

    getData(){
      this.setState({loadingList: true});
      let { company } = this.props.authSession;
        fetch(`${API}/api/company/${company.id}/employes/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
          let rows = [];
          if(res.employes){
            res.employes.map(employe => {
              rows.push(this.createData(employe.id, `${employe.person.first_name} ${employe.person.last_name}`, employe.user, employe.branch.name));
            });
          }
          this.setState({tableRows: rows});
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => this.setState({loadingList: false}));
    }

    handleDelete = () => {
      let options = {
        headers: this.state.headers
      }
      axios.get(`${API}/api/employe/${this.state.deleteId}/delete`, options)
      .then(res => {
        if(res.data.employe){
          this.getData();
          this.props.enqueueSnackbar('Empleado eliminada correctamente!', { variant: 'success' });
        }else{
          this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
          console.log(res.data.message);
        }
      })
      .catch(error => {
        console.log(error);
      })
      .finally( () => {
        this.setState({showDialog: false});
      });

      this.setState({showDialog: false});
    }

    render() {
      // Si no tiene permiso redirecciona al dashboard
      if(this.state.permission === undefined){
        return <Redirect to="/dashboard" />
      }

      return (
        <div className='app'>
            <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
            <main style={{padding: 10}}>
                <Navbar
                    title={
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                            </Grid>
                            <Grid item>
                                <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                    Empleados
                                </h1>
                            </Grid>
                        </Grid>
                    }
                />

                <AlertSubscription />

                <Grid style={{marginTop: 20}}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Link to='/dashboard/employes/create'>
                            <Button variant="contained" style={{backgroundColor: color.primary, color: 'white'}} endIcon={<IoIosAddCircle/>} > Crear nuevo</Button>
                        </Link>
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 50 }}>
                        <Paper >
                          { this.state.loadingList && <LoadingList /> }
                          { this.state.tableRows.length === 0 && !this.state.loadingList && <EmptyList /> }
                          { this.state.tableRows.length > 0 &&
                            <>
                              <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      {tableColumns.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{ minWidth: column.minWidth, backgroundColor: color.primary, color: 'white', fontWeight: 500 }}
                                        >
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.tableRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                      return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                          {tableColumns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                              <TableCell key={column.id} align={column.align}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                              </TableCell>
                                            );
                                          })}
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={this.state.tableRows.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                labelRowsPerPage='Items por página'
                                onChangePage={(event, newPage) => this.setState({page: newPage})}
                                onChangeRowsPerPage={(event) => this.setState({rowsPerPage: +event.target.value, page: 0})}
                              />
                            </>
                          }
                        </Paper>
                    </div>
                </Grid>
            </main>

            {/* Delete dialog */}
            <Dialog
              open={ this.state.showDialog }
              TransitionComponent={ transition }
              onClose={ () => this.setState({ showDialog: false }) }
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Ésta acción eliminará de forma permanente el registro y no podrás usarlo en el futuro.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={ () => this.setState({ showDialog: false }) }>
                  Cancelar
                </Button>
                <Button onClick={ this.handleDelete } style={{color: color.red}}>
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>
        </div>
      );
    }
}


// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };



const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

export default connect(mapStateToProps)(withSnackbar(EmployesList));