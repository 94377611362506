import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Tooltip,
    IconButton,
    Button,
    Backdrop,
    LinearProgress,
    CircularProgress,
    Switch,
    // Form input
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    // Table
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    // Dialog
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
} from '@material-ui/core';

import {
    Alert,
    AlertTitle,
    // Toggle buttons
    ToggleButtonGroup,
    ToggleButton
} from '@material-ui/lab';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { IoIosMenu, IoIosList, IoIosKeypad, IoIosCart, IoIosTrash, IoIosBasket } from "react-icons/io";
import { Link, Redirect } from "react-router-dom";
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import axios from "axios";
import { io } from "socket.io-client";
import CounterInput from "react-counter-input";

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { LoadingList } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API, SOCKET_IO, color } = env;
const defaultImg = `${API}/images/default-image.png`;
const socket = io(SOCKET_IO);

// Sale confirmation
const transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Tab panels config
function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// Filtro del select de clientes para buscar por nombre y CI
const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option) => `${option.person.first_name} ${option.person.ci_nit}`,
});

class SalesCreate extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            sidebarToggled: false,
            tabActive: 0,
            company: this.props.authSession.company,
            productsCategories: [],
            customers: [],
            productCategoryIdActive: null,
            productsCategoriesShowType: this.props.globalConfig.TPVSales.productsShowType,
            loading: false,
            loadingAlt: false,
            loadingProducts: false,
            // Products details
            saleDetails: [],
            // Form sales
            radioSaleType: 'mesa',
            inputSaleAmount: 0,
            inputDiscountAmount: '',
            amountReceived: '',
            amountReceivedError: false,
            tableNumber: '',
            showDialogSaleConfirm: false,
            formSaleSending: false,
            checkType: false,
            paymentType: false,
            inputObservations: '',
            // formCustomer
            cashier: null,
            loadCashier: true,
            branch: this.props.authSession.branch,
            inputIdCustomer: '',
            inputFirstNameCustomer: '',
            inputCICustomer: '',
            inputPhones: '',
            inputAddress: '',
            // formCashier
            showDialogCashier: false,
            inputNameCashier: 'principal',
            inputNameCashierError: false,
            inputAmountCashier: 0,
            inputAmountCashierError: false,
            // Print
            enablePrintRecipe: this.props.globalConfig.sales ? this.props.globalConfig.sales.print : true,
            printKitchenTickets: this.props.globalConfig.sales ? this.props.globalConfig.sales.printKitchenTickets : false,
            printerType: this.props.globalConfig.sales ? this.props.globalConfig.sales.printerType : 'normal',
            printerServerUrl: this.props.globalConfig.sales ? this.props.globalConfig.sales.printerServerUrl : '',
            printerName: this.props.globalConfig.sales ? this.props.globalConfig.sales.printerName : '',
        }
    }

    async componentDidMount(){
        // Obtener el rol del usuario
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3, 4].find(element => element === role.id)});

        document.title = "Vender | " + env.appName;
        document.addEventListener("keyup", this.capturekeyPress, false);
        this.getProducts();
        this.getCustomers();
        this.getCashiers();
    }

    componentWillUnmount(){
        document.removeEventListener("keyup", this.capturekeyPress, false);
    }

    // Capture keyPress
    capturekeyPress = (e) => {
        switch (e.keyCode) {
            case 13:
                if(!this.state.showDialogSaleConfirm && this.state.amountReceived != ''){
                    this.handleConfirm();
                    return;
                }
                if(this.state.showDialogSaleConfirm){
                    this.handleSubmitSale();
                    return;
                }
                break;
            case 27:
                break;
            default: 
                break;
        }
    }

    getProducts(){
        this.setState({loadingProducts: true});
        let { authSession, productsTPV } = this.props;
        if(productsTPV.length){
            this.setState({productsCategories: productsTPV});
            this.setState({loadingProducts: false});
        }else{
            fetch(`${API}/api/company/${authSession.company.id}/products/category/list`, {headers: this.state.headers})
            .then(res => res.json())
            .then(res => {
                if(res.products_category){
                    let { products_category } = res;
                    products_category = products_category.sort((a, b) => b.sales_count - a.sales_count);
                    this.setState({productsCategories: products_category});
                    this.props.setProductsTPV(products_category);
                }
            })
            .catch(error => console.log(error))
            .finally(() => this.setState({loadingProducts: false}));;
        }
    }

    getCashiers(){
        let { branch } = this.state;
        let { user } = this.props.authSession;

        if(branch && user.id){
            fetch(`${API}/api/branch/${branch.id}/cashier/user/${user.id}`, {headers: this.state.headers})
            .then(res => res.json())
            .then(res => {
                if(res.cashier){
                    this.setState({cashier: res.cashier});
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => this.setState({loadCashier: false}));
        }
    }

    handleChangeTab = (event, newValue) => {
        this.setState({tabActive: newValue})
    }

    // sale Details
    handlePressProduct(product){
        let saleDetails = this.state.saleDetails;
        let indexFound = false;

        var price = product.price;
        if(product.offerts.length) {
            let offert = product.offerts[0];
            price = offert.discount_type == 'porcentaje' ? product.price - (product.price * (offert.discount_amount /100)) : product.price - offert.discount_amount
        }
        saleDetails.map((detail, index) => {
            if(detail.id == product.id){
                saleDetails[index].quantity = (parseFloat(saleDetails[index].quantity) +1).toFixed(2);
                saleDetails[index].price = parseFloat(price).toFixed(2);
                saleDetails[index].subtotal = (parseFloat(saleDetails[index].subtotal) + parseFloat(price)).toFixed(2);
                indexFound = true;
            }
        });
        if(!indexFound){
            let newProduct = {
                ...product,
                quantity: 1,
                price,
                subtotal: price
            }
            saleDetails.push(newProduct);
        }
        this.setState({saleDetails, inputSaleAmount: (this.state.inputSaleAmount + parseFloat(price) ) });
    }

    handleChangeQuantity(id, quantity){
        let total = 0;
        let saleDetails = this.state.saleDetails.map(item => {
            let newItem = item;
            if(item.id == id){
                newItem = {
                    ...item,
                    quantity,
                    subtotal: (item.price * quantity).toFixed(2)
                }
            }
            total += parseFloat(newItem.subtotal);
            return newItem;
        });
        this.setState({saleDetails, inputSaleAmount: total });
    }

    handleDeleteSaleDetail(id){
        let saleDetails = this.state.saleDetails;
        let indexDelete = null;
        let total = 0;
        saleDetails.map((item, index) => {
            if(item.id == id){
                indexDelete = index;
            }else{
                total += parseFloat(item.subtotal);
            }
        });
        saleDetails.splice( indexDelete, 1 );
        this.setState({saleDetails, inputSaleAmount: total });
    }

    handleChangeProductsCategoriesShowType = (event, newAlignment) => {
        if(newAlignment){
            let { TPVSales } = this.props.globalConfig;
            let newTPVSales = {
                ...TPVSales,
                productsShowType: newAlignment
            }
            let newGlobalConfig = {
                ...this.props.globalConfig,
                TPVSales: newTPVSales
            }
            this.setState({productsCategoriesShowType: newAlignment}, () => this.props.setGlobalConfig(newGlobalConfig));
        }
    }

    handleConfirm(){
        if(this.state.saleDetails.length > 0){
            this.setState({showDialogSaleConfirm: true});
        }else{
            this.props.enqueueSnackbar('Debes seleccionar algún producto!', { variant: 'warning' });
        }
    }

    // Customers
    getCustomers(){
        let { company } = this.props.authSession;
        fetch(`${API}/api/company/${company.id}/customer/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            if(res.customers){
                let { customers } = res;
                let customerSort = customers.sort(function (a, b) {
                    if (a.person.first_name > b.person.first_name) {
                        return 1;
                    }
                    if (a.person.first_name < b.person.first_name) {
                        return -1;
                    }
                    return 0;
                });

                this.setState({customers: customerSort});
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    handleSubmitCashier = e => {
        if(!this.state.inputNameCashier){
            this.props.enqueueSnackbar('Debes ingresar el título de la caja', { variant: 'error' });
            this.setState({inputNameCashierError: true});
            return;
        }
        if(this.state.inputAmountCashier === ''){
            this.props.enqueueSnackbar('Debes ingresar el monto de apertura', { variant: 'error' });
            this.setState({inputAmountCashierError: true});
            return;
        }

        this.setState({showDialogCashier: false, loading: true});

        let params = {
            user_id: this.props.authSession.user.id,
            name: this.state.inputNameCashier,
            opening_amount: this.state.inputAmountCashier
        }

        axios({
            method: 'post',
            url: `${API}/api/branch/${this.state.branch.id}/cashier/create`,
            data: JSON.stringify(params),
            headers: this.state.headers
        })
        .then(res => {
            if(res.data.cashier){
                this.setState({cashier: res.data.cashier});
                this.props.enqueueSnackbar('Caja aperturada correctamente', { variant: 'success' });
            }else{
                this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
                console.log(res.data.message);
            }
        })
        .catch(error => this.props.enqueueSnackbar('Error al realizar la petición', { variant: 'warning' }))
        .finally(() => {
            this.setState({loading: false});
        })
    }

    handleSubmitSale(){
        if(this.state.cashier === null){
            this.props.enqueueSnackbar('Debes abrir caja primero', { variant: 'error' });
            return
        }

        if(!this.state.formSaleSending && this.state.saleDetails.length){
            this.setState({formSaleSending: true, loadingAlt: true}, async () => {
                let { company } = this.props.authSession;
                let params = {
                    company_id: company.id,
                    branch_id: this.state.branch.id,
                    user_id: this.props.authSession.user.id,
                    customer_id: this.state.inputIdCustomer,
                    first_name: this.state.inputFirstNameCustomer,
                    ci_nit: this.state.inputCICustomer,
                    cashier_id: this.state.cashier.id,
                    payment_type: this.state.paymentType ? 2 : 1,
                    sale_type: this.state.radioSaleType,
                    sales_status_id: 2,
                    total: this.state.inputSaleAmount,
                    discount: this.state.inputDiscountAmount,
                    amount_received: this.state.amountReceived,
                    table_number: this.state.tableNumber,
                    observations: this.state.inputObservations,
                    bill: this.state.checkType ? true : false,
                    sale_details: this.state.saleDetails
                }

                let options = {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: this.state.headers
                }
                let res = await fetch(`${API}/api/sales/create`, options)
                .then(res => res.json())
                .then(res => res)
                .catch(error => {
                    console.log(error);
                })
                .finally(() => this.setState({formSaleSending: false, showDialogSaleConfirm: false, loadingAlt: false}))

                if(res.sale){
                    this.setState({
                        inputIdCustomer: '',
                        inputFirstNameCustomer: '',
                        inputCICustomer: '',
                        saleDetails: [],
                        paymentType: false,
                        radioSaleType: 'mesa',
                        inputSaleAmount: 0,
                        inputDiscountAmount: '',
                        amountReceived: '',
                        tableNumber: '',
                        checkType: false,
                        inputObservations: ''
                    });
                    this.props.enqueueSnackbar('Venta realizada correctamente!', { variant: 'success' });

                    // Emitir evento para cocina
                    socket.emit(`change status`, {status: 2, branchId: this.state.branch.id});

                    if(this.state.enablePrintRecipe){
                        if(this.state.printerType == 'normal'){
                          window.open(`/dashboard/sales/print/${res.sale.id}`, 'Factura', 'toolbar=0,location=0,menubar=0,width=370,height=420,top=100,left:300');
                        }else{
                            if(this.state.printerServerUrl && this.state.printerName){
                                let sale = res.sale;
                                var saleDetails = []; 
                                
                                sale.details.map(detail => {
                                saleDetails.push({
                                        "quantity": detail.quantity,
                                        "product": detail.product.name,
                                        "total": detail.quantity * detail.price
                                    });
                                });
                    
                                let body = {
                                    "platform": this.props.globalConfig.platform,
                                    "printer_name": this.state.printerName,
                                    "company_name": this.state.company.name,
                                    "sale_number": sale.sale_number,
                                    "sale_type": sale.sale_type,
                                    "table_number": sale.table_number,
                                    "discount": sale.discount,
                                    "observations": sale.observations,
                                    "print_kitchen_tickets": this.state.printKitchenTickets,
                                    "details": saleDetails
                                };
                                
                                let options = {
                                method: 'POST',
                                body: JSON.stringify(body),
                                headers: this.state.headers
                                }
                                let slash = this.state.printerServerUrl.substr(this.state.printerServerUrl.length -1) != '/' ? '/' : '';
                                fetch(`${this.state.printerServerUrl}${slash}api/print`, options)
                                    .then(function(response) {
                                        if(response.ok) {
                                            return response.text();         
                                        }
                                        throw new Error('Something went wrong.');
                                    })
                                    .then(function(text) {
                                        console.log('Request successful', text);  
                                    })  
                                    .catch(function(error) {
                                        console.log('Request failed', error);
                                    });
                            }else{
                                this.props.enqueueSnackbar('Debe configurar los parámetros de la impresora térmica', { variant: 'warning' });
                            }
                        }
                    }
                }else{
                    this.props.enqueueSnackbar(res.error, { variant: 'error' });
                    console.log(res.message);
                }
            });
        }
    }

    handleSelectCustomerId = (event, value, reason) => {
        if(value){
            this.setState({inputCICustomer: value.person.ci_nit ? value.person.ci_nit : '', inputIdCustomer: value.id});
        }else{
            this.setState({inputCICustomer: ''});
        }
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }
        
        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }

                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    {/* <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            Caja { this.state.cashier ? this.state.cashier.name : 'cerrada' }
                                        </h1>
                                    </Grid> */}
                                </Grid>
                            }
                        />

                        <AlertSubscription />

                        <Grid container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: 5, marginBottom: 5, paddingRight: 5 }}>
                            {/* Products show type */}
                            <ToggleButtonGroup
                                value={ this.state.productsCategoriesShowType }
                                exclusive
                                onChange={ this.handleChangeProductsCategoriesShowType }
                                aria-label="Tipo de visualización"
                                size="small"
                            >
                                <ToggleButton value="cuadricula" aria-label="Vista en cuadrícula">
                                    <IoIosKeypad size={20} />
                                </ToggleButton>
                                <ToggleButton value="agrupado" aria-label="Vista agrupada">
                                    <IoIosList size={20} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        { this.state.cashier === null && this.state.loadCashier === false &&
                            <Alert severity="error" style={{ marginBottom: 10 }}>
                                <AlertTitle>Advertencia</AlertTitle>
                                Debes abrir caja para registrar tus ventas realizadas.
                                <Button variant="outlined" color="secondary" onClick={ e => this.setState({showDialogCashier: true}) } style={{marginLeft: 10}}>
                                    Abrir caja
                                </Button>
                            </Alert>
                        }

                        {/* Products list and sale details */}

                        <Paper elevation={2}>
                            <Grid container spacing={2}>
                                {/* Tabs products */}

                                { this.state.loadingProducts && <Grid item xs={12} sm={8} style={{paddingTop: 50}}><LoadingList /> </Grid> }

                                { !this.state.loadingProducts &&
                                    <Grid item xs={12} sm={8} md={8} >
                                        <AppBar position="static" style={{backgroundColor: color.secondary, color: 'white', margin: 0}}>
                                            <Tabs
                                                value={ this.state.tabActive }
                                                onChange={ this.handleChangeTab }
                                                aria-label="Panel de productos"
                                                variant="scrollable"
                                                scrollButtons="auto"
                                            >
                                                {
                                                    this.state.productsCategories.map((category, index) => {
                                                        if(category.products.length){
                                                            return <Tab key={ category.id } label={ category.name } {...tabProps(index)} style={{ fontSize: 11 }} />                                                        
                                                        }
                                                    })
                                                }
                                            </Tabs>
                                        </AppBar>
                                        <div style={{maxHeight: 300, overflowY: 'auto' }}>
                                            
                                            {/* Recorrer lista de productos */}
                                            {
                                                this.state.productsCategories.map((category, index) => {
                                                    
                                                    if(category.products.length){
                                                        // Show group by
                                                        if(this.state.productsCategoriesShowType === 'agrupado'){
                                                            return (
                                                                <TabPanel key={category.id} value={ this.state.tabActive } index={index}>
                                                                    {
                                                                        Array.from(
                                                                        category.products.reduce((a,{type, ...rest})=>{
                                                                            return a.set(type, [rest].concat(a.get(type)||[]));
                                                                        }, new Map())
                                                                        ).map(([type, children])=>({type,children}))
                                                                        .map(type => {
                                                                            return(
                                                                                <div key={type.type} style={{marginBottom: 20}}>
                                                                                    <Typography variant='body2' style={{marginBottom: 5}}>{type.type}</Typography>
                                                                                    <Grid container spacing={1} style={{margin: 0}} >
                                                                                        {
                                                                                            type.children.map(product => <CardProduct key={product.id} product={product} onClick={ (e) => this.handlePressProduct(product) } />)
                                                                                        }
                                                                                    </Grid>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </TabPanel>
                                                            )
                                                        }
                                                        
                                                        // Show whitout group by
                                                        return(
                                                            <TabPanel key={category.id} value={ this.state.tabActive } index={index}>
                                                                <Grid container spacing={1} style={{margin: 0}} >
                                                                    {
                                                                        category.products.map(product => <CardProduct key={product.id} product={product} onClick={ (e) => this.handlePressProduct(product) } />)
                                                                    }
                                                                </Grid>
                                                            </TabPanel>
                                                        )
                                                    }
                                                })
                                            }
                                        </div>
                                    </Grid>
                                }

                                {/* Sale info */}
                                <Grid item xs={12} sm={4} md={4} style={{ padding: 20, paddingTop: 15}}>
                                    <Grid container>
                                        {/* Select customer */}
                                        <Grid item xs={12} sm={12}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} sm={12}>
                                                    <Autocomplete
                                                        freeSolo
                                                        id="free-solo-2-demo"
                                                        disableClearable
                                                        fullWidth
                                                        options={ this.state.customers }
                                                        getOptionLabel={(option) => option.person.first_name }
                                                        renderOption={(option) => (
                                                            <React.Fragment>
                                                                <div>
                                                                    {option.person.first_name} <small style={{fontSize: 12}}>({option.sales.length})</small> <br /> <small>{option.person.ci_nit}</small>
                                                                </div>
                                                            </React.Fragment>
                                                          )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Cliente"
                                                                margin="normal"
                                                                size="small"
                                                                fullWidth
                                                                variant="outlined"
                                                                value={ this.state.inputFirstNameCustomer }
                                                            />
                                                        )}
                                                        
                                                        onChange={ this.handleSelectCustomerId }
                                                        onInputChange={(event, value, reason) => this.setState({inputFirstNameCustomer: value})}
                                                        filterOptions={filterOptions}
                                                        inputValue={ this.state.inputFirstNameCustomer }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="input-ci_nit"
                                                        label="NIT/CI"
                                                        size="small"
                                                        value={ this.state.inputCICustomer }
                                                        onChange={ event => this.setState({inputCICustomer: event.target.value})}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Type sale */}
                                        <Grid item xs={12} style={{marginTop: 3}}>
                                            <RadioGroup row aria-label="position" name="position" defaultValue={ this.state.radioSaleType }>
                                                <Grid container direction="row" justify="space-around" alignItems="flex-start">
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="mesa"
                                                            control={<Radio color="primary" />}
                                                            label="Mesa"
                                                            labelPlacement="end"
                                                            onChange={ (event) => this.setState({radioSaleType: event.target.value}) }
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            value="para llevar"
                                                            control={<Radio color="primary" />}
                                                            label="Para llevar"
                                                            labelPlacement="end"
                                                            onChange={ (event) => this.setState({radioSaleType: event.target.value, tableNumber: ''}) }
                                                        />
                                                    </Grid>
                                                    {/* <Grid item>
                                                        <FormControlLabel
                                                            value="domicilio"
                                                            control={<Radio color="primary" />}
                                                            label="Domicilio"
                                                            labelPlacement="end"
                                                            onChange={ (event) => this.setState({radioSaleType: event.target.value}) }
                                                        />
                                                    </Grid> */}
                                                </Grid>
                                            </RadioGroup>
                                        </Grid>

                                        {/* Amounts */}
                                        <Grid item xs={12}>
                                            <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                                                <Grid item xs={12} sm={12} md={4} style={{textAlign: 'right', padding: 5, paddingTop: 0}}>
                                                    <TextField
                                                        id="input-amount" 
                                                        label="$"
                                                        fullWidth
                                                        inputProps={{ type: 'number', min: '0', step: '0.5' }}
                                                        error={ this.state.amountReceivedError }
                                                        value={ this.state.amountReceived }
                                                        onChange={ e => this.setState({amountReceived: e.target.value, amountReceivedError: false}) }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={4} style={{textAlign: 'right', padding: 5, paddingTop: 0}}>
                                                    <TextField
                                                        id="input-discount" 
                                                        label="Desc."
                                                        fullWidth
                                                        inputProps={{ type: 'number', min: '0', step: '0.5' }}
                                                        value={ this.state.inputDiscountAmount }
                                                        onChange={ e => this.setState({inputDiscountAmount: e.target.value}) }
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={4} style={{textAlign: 'right', padding: 5, paddingTop: 0}}>
                                                    <TextField
                                                        id="input-tableNumber" 
                                                        label="# mesa"
                                                        inputProps={{ type: 'number', min: '1', step: '1', }}
                                                        value={ this.state.tableNumber }
                                                        onChange={ e => this.setState({tableNumber: e.target.value}) }
                                                        disabled={this.state.radioSaleType != 'mesa' ? true : false}
                                                    />
                                                </Grid>
                                                <Grid className='amount-total-normal' item xs={12} md={6} style={{textAlign: 'right', padding: 5, paddingTop: 10}}>
                                                    <Typography variant='h5'>{ this.state.inputSaleAmount == parseInt(this.state.inputSaleAmount) ? parseInt(this.state.inputSaleAmount) : this.state.inputSaleAmount.toFixed(2) } <small style={{ fontSize: 12, padding: 0 }}>Bs.</small> </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* Alert refund amount */}
                                        <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 5}}>
                                            {
                                                this.state.amountReceived == '' &&
                                                <Alert severity="info">Ingresa el monto recibido por el cliente</Alert>
                                            }
                                            {
                                                this.state.amountReceived != '' && this.state.amountReceived == (this.state.inputSaleAmount - this.state.inputDiscountAmount) &&
                                                <Alert severity="success">Monto recibido exacto!</Alert>
                                            }
                                            {
                                                this.state.amountReceived != '' && this.state.amountReceived != (this.state.inputSaleAmount - this.state.inputDiscountAmount) &&
                                                <Alert severity={this.state.amountReceived > (this.state.inputSaleAmount - this.state.inputDiscountAmount) ? 'info' : 'error'}>
                                                    {
                                                        (this.state.amountReceived > (this.state.inputSaleAmount - this.state.inputDiscountAmount)) ?
                                                        <>Debes dar un cambio de <b>{this.state.amountReceived - (this.state.inputSaleAmount - this.state.inputDiscountAmount)}</b> Bs.</> :
                                                        'Monto inferior al importe de la venta'
                                                    }
                                                </Alert>
                                            }
                                        </Grid>

                                        {/* Switches */}
                                        {/* <Grid item md={12}>
                                            <FormControlLabel
                                                control={<Switch checked={ this.state.checkType } onChange={ e => this.setState({checkType: e.target.checked})} name="switch-checkType" color="primary" />}
                                                label="Factura"
                                            />
                                        </Grid> */}
                                        <Grid item md={12}>
                                            <FormControlLabel
                                                control={<Switch checked={ this.state.paymentType } onChange={ e => this.setState({ paymentType: e.target.checked, amountReceived: e.target.checked ? this.state.inputSaleAmount : '' })} name="switch-paymentType" color="primary" />}
                                                label="Pagado con QR/Tarjeta"
                                            />
                                        </Grid>

                                        <div className='amount-total-normal' style={{width: '100%'}}>
                                            <BtnSale
                                                onClick={ (e) => {
                                                    if(this.state.cashier === null){
                                                        this.setState({showDialogCashier: true});
                                                        this.props.enqueueSnackbar('Debes abrir caja primero', { variant: 'error' });
                                                        return
                                                    }
                                                    if(this.state.amountReceived != '' && (this.state.amountReceived < (this.state.inputSaleAmount - this.state.inputDiscountAmount))){
                                                        this.setState({amountReceivedError: true})
                                                        this.props.enqueueSnackbar('Monto recibido inferior al importe de la venta', { variant: 'warning' });
                                                        return
                                                    }
                                                    this.handleConfirm()
                                                }}
                                            />
                                            <Grid item xs={12} md={12} style={{ textAlign: 'center', paddingBottom: 10 }}>
                                                <Link to='/dashboard/sales' style={{ textDecoration: 'underline' }}>Ver lista de ventas</Link>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>

                        {/* Table details products */}
                        { this.state.saleDetails.length > 0 &&
                            <Paper style={{marginTop: 20, marginBottom: 30}} elevation={2}>
                                
                                {/* Table details */}
                                <TableContainer>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><Typography variant="subtitle2" color="textSecondary">Producto</Typography></TableCell>
                                                {/* <TableCell align="right"><Typography variant="subtitle2" color="textSecondary">Precio</Typography></TableCell> */}
                                                <TableCell align="right"><Typography variant="subtitle2" color="textSecondary">Cantidad</Typography></TableCell>
                                                <TableCell align="right"><Typography variant="subtitle2" color="textSecondary">Subtotal</Typography></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.saleDetails.map((row) => {
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row" style={{ padding: '0px 5px' }}>{
                                                        <Card elevation={0} style={{display: 'flex'}}>
                                                            <CardMedia
                                                                component="img"
                                                                alt={ row.name }
                                                                image={row.image ? `${API}/storage/${row.image.replace('.', '-cropped.')}` : defaultImg}
                                                                title={ row.name }
                                                                style={{ height: 60, width: 60 }}
                                                            />
                                                            <div>
                                                                <CardContent style={{ paddingTop: 0, paddingBottom: 0, }}>
                                                                    <Typography noWrap={true} variant="subtitle2"><b>{ row.name }</b></Typography>
                                                                    <Typography noWrap={true} variant="caption" color="textSecondary">{ row.type ? row.type : row.short_description }</Typography> <br />
                                                                    <Typography noWrap={true} variant="caption" color="textSecondary">{ parseFloat(row.price).toFixed(2) } <small>Bs.</small></Typography>
                                                                </CardContent>
                                                            </div>
                                                        </Card>
                                                        }
                                                    </TableCell>
                                                    {/* <TableCell align="right" style={{ padding: '0px 20px' }}>{ parseFloat(row.price).toFixed(2) }</TableCell> */}
                                                    <TableCell align="right" style={{ width: 95, padding: '0px 5px' }}>
                                                        <CounterInput
                                                            count={ parseInt(row.quantity) }
                                                            min={1}
                                                            onCountChange={count => this.handleChangeQuantity(row.id, count)}
                                                            btnStyle={{ backgroundColor: color.primary, color: 'white', borderRadius: 3, padding: '3px 8px' }}
                                                            inputStyle={{ height: 28 }}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="right" style={{ fontSize: 18, padding: '0px 20px' }}>{ parseFloat(row.subtotal).toFixed(2) }</TableCell>
                                                    <TableCell align="right" style={{ padding: '0px 5px' }}>
                                                        <Tooltip title="Borrar" placement="top">
                                                            <IconButton aria-label="Borrar" component="span" onClick={ e => this.handleDeleteSaleDetail(row.id) }>
                                                                <IoIosTrash color='#CD272A' size={20} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                {/* Observations */}
                                <Grid style={{margin: 10, marginTop: 20}}>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="input-observations"
                                        label="Observaciones"
                                        placeholder='Describa las solicitudes extras del cliente'
                                        name="observations"
                                        helperText="Ej: Sin ensalada, sin aceitunas, etc."
                                        value={ this.state.inputObservations }
                                        onChange={ event => this.setState({inputObservations: event.target.value}) }
                                        style={{ marginBottom: 15 }}
                                        multiline
                                        rows={2}
                                    />
                                </Grid>

                            </Paper>
                        }

                        {/* Empty sale detail */}
                        {
                            !this.state.saleDetails.length &&
                            <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                style={{marginTop: 50, marginBottom: 50}}
                            >
                                {/* <div style={{marginBottom: 10}}>
                                    <img src="/img/dashboard/cart-empty.png" style={{width: 200}}/>
                                </div> */}
                                <Typography color="textSecondary" variant='h5'>Cesta de productos vacía <IoIosBasket size={30} /> </Typography>
                            </Grid>
                        }

                        {/* Sale confirmation */}
                        <Dialog
                            open={ this.state.showDialogSaleConfirm }
                            // open={ true }
                            TransitionComponent={ transition }
                            keepMounted
                            onClose={() => this.setState({showDialogSaleConfirm: false}) }
                            fullWidth
                            maxWidth='md'
                        >
                            <DialogContent>
                                <Paper style={{marginTop: 20 , marginBottom: 30}} elevation={2}>
                                    <Grid container>

                                        {/* List sale details */}
                                        <Grid item md={6} xs={12} style={{ maxHeight: 330, overflowY: 'auto' }}>
                                            <TableContainer>
                                                <Table size="small" aria-label="Detalles de venta">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Producto</TableCell>
                                                            <TableCell align="right">Monto</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {this.state.saleDetails.map((row) => (
                                                        <TableRow key={`preview-${row.id}`}>
                                                            <TableCell component="th" scope="row" style={{paddingTop: 3, paddingBottom: 3}}>
                                                                <Card elevation={0} style={{display: 'flex'}}>
                                                                    <CardMedia
                                                                        component="img"
                                                                        alt={ row.name }
                                                                        height={40}
                                                                        image={row.image ? `${API}/storage/${row.image.replace('.', '-small.')}` : defaultImg}
                                                                        title={ row.name }
                                                                        style={{ width: 60 }}
                                                                    />
                                                                    <div>
                                                                        <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                                            <Typography noWrap={true} variant="body1">{ row.quantity } { row.name }</Typography>
                                                                            <Typography noWrap={true} variant="subtitle2" color="textSecondary">{ row.type ? row.type : row.short_description }</Typography>
                                                                        </CardContent>
                                                                    </div>
                                                                </Card>
                                                            </TableCell>
                                                            <TableCell align="right"><Typography noWrap={true} variant="body1">{ row.subtotal } <small>Bs.</small></Typography></TableCell>
                                                        </TableRow>
                                                    ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>

                                        {/* Sale info */}
                                        <Grid item md={6} xs={12} style={{ backgroundColor: color.secondary }}>
                                            <Grid item md={12} style={{ textAlign: 'center', marginTop: 20}}>
                                                <Typography variant='h5' style={{ color: 'white' }} >{ this.state.checkType ? 'Factura' : 'Recibo de venta' }</Typography>
                                            </Grid>
                                            <Grid container style={{ padding: 20 }}>
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' style={{ color: 'white' }} >Cliente</Typography>
                                                    <Typography variant='h6' noWrap={false} style={{ color: 'white' }} >{ this.state.inputFirstNameCustomer }</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{marginTop: 10}}>
                                                    <Typography variant='body2' style={{ color: 'white' }} >NIT</Typography>
                                                    <Typography variant='h6' noWrap={false} style={{ color: 'white' }} >{ this.state.inputCICustomer }</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="column" justify="flex-end" alignItems="flex-end" style={{paddingRight: 10 }}>
                                                <Grid item xs={12} style={{textAlign: 'right', paddingBottom: 10}}>
                                                    <Typography variant='body2' style={{ color: 'white' }}>Monto total</Typography>
                                                    <Typography variant='h3' style={{ color: 'white' }}>{ (this.state.inputSaleAmount-this.state.inputDiscountAmount).toFixed(2) } <small style={{ fontSize: 15, padding: 0, color: 'white' }}>Bs.</small> </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {
                                        this.state.loadingAlt && <LinearProgress />
                                    }
                                </Paper>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => this.setState({showDialogSaleConfirm: false}) } >
                                    Cancelar
                                </Button>
                                <Button disabled={this.state.formSaleSending || this.state.cashier === null ? true : false } onClick={() => this.handleSubmitSale() } style={{ color: color.primary }} >
                                    Aceptar
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {/* Form cashier */}
                        <div>
                            <Dialog
                                open={ this.state.showDialogCashier }
                                TransitionComponent={ transition }
                                keepMounted
                                onClose={() => this.setState({showDialogCashier: false}) }
                                aria-labelledby="alert-dialog-customer-title"
                                fullWidth
                                maxWidth='sm'
                            >
                                <DialogTitle id="alert-dialog-customer-title">Aperturar caja</DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={12}>
                                            <TextField
                                                name="name"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="input-name"
                                                label="Título de la caja"
                                                helperText="título de la caja o nombre del cajero para diferenciarla del resto. Ej: Turno tarde"
                                                error={ this.state.inputNameCashierError }
                                                value={ this.state.inputNameCashier }
                                                onChange={ event => this.setState({inputNameCashier: event.target.value, inputNameCashierError: false}) }
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <TextField
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="input-amount"
                                                label="Monto de apertura"
                                                name="amount"
                                                helperText="Monto de apertura de la caja."
                                                inputProps={{ type: 'number', min: '0', step: '0.5' }}
                                                // onClick={ e => e.target.select() }
                                                error={ this.state.inputAmountCashierError }
                                                value={ this.state.inputAmountCashier }
                                                onChange={ event => this.setState({inputAmountCashier: event.target.value, inputAmountCashierError: false}) }
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <Alert severity="info">
                                                <AlertTitle>Información</AlertTitle>
                                                Todas la ventas que realices, serán registradas en ésta caja.
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ showDialogCashier: false }) } >
                                        Cancelar
                                    </Button>
                                    <Button onClick={ this.handleSubmitCashier } style={{color: color.primary}} >
                                        Aperturar caja
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                        <div className='amount-total-bottom'>
                            <div style={{padding: 10}}>
                                <Grid item xs={12} md={6} style={{textAlign: 'right', padding: 5, paddingTop: 10}}>
                                    <Typography variant='h5'>{ this.state.inputSaleAmount.toFixed(2) } <small style={{ fontSize: 12, padding: 0 }}>Bs.</small> </Typography>
                                </Grid>
                                <BtnSale
                                    onClick={ (e) => {
                                        if(this.state.cashier === null){
                                            this.setState({showDialogCashier: true});
                                            this.props.enqueueSnackbar('Debes abrir caja primero', { variant: 'error' });
                                            return
                                        }
                                        if(this.state.amountReceived != '' && (this.state.amountReceived < (this.state.inputSaleAmount - this.state.inputDiscountAmount))){
                                            this.setState({amountReceivedError: true})
                                            this.props.enqueueSnackbar('Monto recibido inferior al importe de la venta', { variant: 'warning' });
                                            return
                                        }
                                        this.handleConfirm()
                                    }}
                                />
                            </div>
                        </div>
                    </main>
                </div>
            </>
        );
    }
}

const mystyle = {
    color: "white",
    backgroundColor: "DodgerBlue",
    padding: "10px",
    fontFamily: "Arial"
  };

const CardProduct = props => {
    let { product } = props;
    let offert = null;
    let price = product.price;
    let oldPrice = 0;
    if(product.offerts.length) {
        offert = product.offerts[0];
        oldPrice = price;
        price = offert.discount_type == 'porcentaje' ? product.price - (product.price * (offert.discount_amount /100)) : product.price - offert.discount_amount
    }
    return(
        <Grid item xs={6} sm={4} md={3} onClick={ props.onClick }>
            <Tooltip title={ product.short_description ? product.short_description : 'Sin descripción' }  >
                <Card >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt={ product.name }
                            height="80"
                            image={ product.image ? `${API}/storage/${product.image.replace('.', '-small.')}` : defaultImg }
                        />
                        <CardContent>
                            <Grid container spacing={2} style={{marginTop: -20}}>
                                <Grid item sm={12}>
                                    <Typography noWrap={true} variant='subtitle2'><b>{ product.name }</b></Typography>
                                    <Typography noWrap={true} variant='caption'>{ product.type ? product.type : (product.short_description ? product.short_description : 'Sin descripción') }</Typography>
                                </Grid>
                                <Grid container style={{marginTop: -10}} justify="flex-end">
                                    <small style={{ color: color.primary }}>
                                        {
                                            offert &&
                                            <>
                                                <small style={{ color: '#707172', textDecoration: 'line-through', fontSize: 10 }}>{ parseFloat(oldPrice).toFixed(2) }</small>&nbsp;
                                            </>
                                        }
                                        { parseFloat(price).toFixed(2) }
                                        <small style={{marginLeft: 2}}>Bs.</small>
                                    </small>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Tooltip>
        </Grid>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const BtnSale = props => {
    return <>
        <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
            <Button
                variant="contained"
                style={{backgroundColor: color.primary, color: 'white'}}
                fullWidth
                size="large"
                endIcon={<IoIosCart>Vender</IoIosCart>}
                onClick={props.onClick}
            >
                Vender
            </Button>

        </Grid>
    </>
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
        globalConfig: state.globalConfig,
        productsTPV: state.productsTPV
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAuthSession : (authSession) => dispatch({
            type: 'SET_AUTH_SESSION',
            payload: authSession
        }),
        setProductsTPV : (productsTPV) => dispatch({
            type: 'SET_PRODUCTS_TPV',
            payload: productsTPV
        }),
        setGlobalConfig : (globalConfig) => dispatch({
            type: 'SET_GLOBAL_CONFIG',
            payload: globalConfig
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(SalesCreate));