import React, { Component } from 'react';
import {
    Grid,
    Paper,
    TextField,
    Button,
    Select,
    InputLabel,
    FormControl,
    Backdrop,
    CircularProgress,
    Typography
}from '@material-ui/core';
import {
    Alert,
    AlertTitle,
    ToggleButtonGroup,
    ToggleButton
} from '@material-ui/lab';

import { IoIosMenu, IoMdCalendar, IoIosCalendar, IoMdCrop, IoIosCog, IoMdPrint } from "react-icons/io";
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import axios from "axios";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import moment from 'moment';
import 'moment/locale/es';

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { WaterMark, FooterPrint } from "../../components/forms";
import { env } from '../../../env';

const { API, color } = env;

const containerStyle = {
    height: 350, width: '100%', marginTop: 50
};

class CashiersReports extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            sidebarToggled: false,
            loading: false,
            reportType: 'day',
            // Form
            inputDate: '',
            inputStart: '',
            inputEnd: '',
            selectMonth: 'none',
            inputYear: moment().format('Y'),
            report: [],
            reportDate: {}
        }
    }

    componentDidMount(){
        // Obtener el rol del usuario
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3].find(element => element === role.id)});

        document.title = "Reporte de cajas | " + env.appName;
        let date = new Date();
        this.setState({ selectMonth: date.getMonth() +1 })
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        let { reportType, inputDate, selectMonth, inputYear, inputStart, inputEnd } = this.state;
        let params = {};

        // Validación dependiendo del tipo de rango de fecha
        if(reportType == 'day'){
            if(inputDate == ''){
                this.props.enqueueSnackbar('Debes ingresar la fecha del reporte!', { variant: 'warning' });
                return false;
            }
            params = {type: reportType, date: inputDate }
        }
        if(reportType == 'month'){
            if(selectMonth == 'none' || inputYear == ''){
                this.props.enqueueSnackbar('Debes ingresar el mes y el año del reporte!', { variant: 'warning' });
                return false;
            }
            params = { type: reportType, month: selectMonth, year: inputYear }
        }
        if(reportType == 'range'){
            if(inputStart == '' || inputEnd == ''){
                this.props.enqueueSnackbar('Debes ingresar la fecha de inicio y de fin del reporte!', { variant: 'warning' });
                return false;
            }
            params = { type: reportType, start: inputStart, finish: inputEnd }
        }
        // ============================================

        let { company } = this.props.authSession;

        this.setState({loading: true});
        axios({
            method: 'post',
            url: `${API}/api/company/${company.id}/report/cashiers`,
            data: params,
            headers: this.state.headers
        })
        .then(res => {
            if (res.data.report) {
                this.setState({
                    report: res.data.report,
                    reportDate: res.data.date
                });
            } else {
                console.log(res.data.message);
            }
        })
        .catch((err) => this.props.enqueueSnackbar('Ocurrió un error en nuestro servidor!', { variant: 'error' }))
        .then(() => this.setState({loading: false}));
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }

        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            Reporte de cajas
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />
                        
                        {/* Form */}
                        <Paper style={{ backgroundColor: 'white', padding: 30, marginTop: 50}}>
                            <form onSubmit={ this.handleSubmit }>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" justify="flex-end" alignItems="flex-start">
                                            <ToggleButtonGroup
                                                value={ this.state.reportType }
                                                exclusive
                                                onChange={ (event, newReportType) => { if(newReportType) this.setState({reportType: newReportType, report: [] }) } }
                                                aria-label="Tipo de aporte"
                                            >
                                                <ToggleButton value="day" aria-label="Diario">
                                                    <IoMdCalendar size={20} />
                                                </ToggleButton>
                                                <ToggleButton value="month" aria-label="Mensual">
                                                    <IoIosCalendar size={20} />
                                                </ToggleButton>
                                                <ToggleButton value="range" aria-label="Rango de fecha">
                                                    <IoMdCrop size={20} />
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                    </Grid>
                                    {/* Form day */}
                                    {
                                        this.state.reportType == 'day' &&
                                        <>
                                            <Grid item lg={3} sm={6} xs={6}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    id="inputDate"
                                                    label="Fecha"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={event => this.setState({inputDate: event.target.value})}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    {/* Form month */}
                                    {
                                        this.state.reportType == 'month' &&
                                        <>
                                            <Grid item lg={3} sm={4} xs={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel htmlFor="outlined-age-native-simple">Mes</InputLabel>
                                                    <Select
                                                        native
                                                        value={ this.state.selectMonth }
                                                        onChange={ event => this.setState({selectMonth: event.target.value}) }
                                                        label="Mes"
                                                        inputProps={{
                                                            name: 'month',
                                                            id: 'outlined-age-native-simple',
                                                        }}
                                                    >
                                                        <option aria-label="none" value=""></option>
                                                        <option value={1}>Enero</option>
                                                        <option value={2}>Febrero</option>
                                                        <option value={3}>Marzo</option>
                                                        <option value={4}>Abril</option>
                                                        <option value={5}>Mayo</option>
                                                        <option value={6}>Junio</option>
                                                        <option value={7}>Julio</option>
                                                        <option value={8}>Agosto</option>
                                                        <option value={9}>Septiembre</option>
                                                        <option value={10}>Octubre</option>
                                                        <option value={11}>Noviembre</option>
                                                        <option value={12}>Diciembre</option>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={2} sm={4} xs={4}>
                                                <TextField
                                                    fullWidth
                                                    id="input-year"
                                                    label="Año"
                                                    variant="outlined"
                                                    value={ this.state.inputYear }
                                                    onChange={ event => this.setState({inputYear: event.target.value}) }
                                                    inputProps={{
                                                        type: 'number',
                                                        step: 1
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    }

                                    {/* Form range */}
                                    {
                                        this.state.reportType == 'range' &&
                                        <>
                                            <Grid item lg={3} sm={4} xs={4}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    id="inputStart"
                                                    label="Fecha de inicio"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={event => this.setState({inputStart: event.target.value})}
                                                />
                                            </Grid>
                                            <Grid item lg={3} sm={4} xs={4}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    id="inputEnd"
                                                    label="Fin"
                                                    type="date"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={event => this.setState({inputEnd: event.target.value})}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    <Grid item lg={6} sm={6} xs={6}>
                                        <Button
                                            variant="contained"
                                            style={{backgroundColor: color.primary, color: 'white', height: 55}}
                                            endIcon={<IoIosCog />}
                                            type="submit"
                                        >
                                            Generar
                                        </Button>
                                        { this.state.report.length > 0 &&
                                            <ReactToPrint content={() => this.componentRef} documentTitle="Reporte de cajas" pageStyle="margin: 10px;">
                                                <PrintContextConsumer>
                                                    {({ handlePrint }) => (
                                                        <Button
                                                            variant="contained"
                                                            style={{backgroundColor: color.red, color: 'white', height: 55, marginLeft: 5}}
                                                            endIcon={<IoMdPrint />}
                                                            onClick={ handlePrint }
                                                        >
                                                            Imprimir
                                                        </Button>
                                                    )}
                                                </PrintContextConsumer>
                                            </ReactToPrint>
                                        }
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>

                        {/* Table */}
                        <Paper style={{ backgroundColor: 'white', padding: 30, marginTop: 20}}>
                            { this.state.report.length == 0 &&
                                <div>
                                    <Alert severity="info">
                                        <AlertTitle>Información</AlertTitle>
                                        Puedes elegir el tipo de reporte que deseas según el tiempo o la forma en que lo desees visualizar y presionar el botón <strong>Generar!</strong>
                                    </Alert>    
                                </div>
                            }                            
                            
                            {/* Reporte agrupado por ventas */}
                            { 
                                this.state.report.length > 0 && 
                                <>
                                    <ReportCashiers data={ this.state.report } ref={el => (this.componentRef = el)} />
                                    <div style={{ display: "none" }}>
                                        <PrintReport
                                            company={this.props.authSession.company}
                                            data={ this.state.report }
                                            ref={el => (this.componentRef = el)}
                                            reportType={ this.state.reportType }
                                            reportDate={ this.state.reportDate }
                                        />
                                    </div>
                                </>
                            }
                        </Paper>
                    </main>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

const ReportCashiers = props => {
    const { data } = props;
    if(data.length == 0){
        return(
            <div>
                <Typography variant="h6" color="textSecondary">No hay datos para mostrar!</Typography>
            </div>
        );
    }

    return(
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead style={{backgroundColor: color.primary, color: 'white', fontWeight: 500}}>
                    <tr>
                        <th>N&deg;</th>
                        <th>Sucursal</th>
                        <th>Creada por</th>
                        <th>Nombre</th>
                        <th style={{textAlign: 'right'}}>Apertura</th>
                        <th style={{textAlign: 'right'}}>Cierre</th>
                        <th style={{textAlign: 'right'}}>Sobrante</th>
                        <th style={{textAlign: 'right'}}>Faltante</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => {
                            return(
                                <tr key={item.id}>
                                    <td>{ index +1 }</td>
                                    <td>{ item.branch.name }</td>
                                    <td>{ item.user.name }</td>
                                    <td>{ item.name }</td>
                                    <td style={{textAlign: 'right'}}>
                                        { item.opening_amount ? parseFloat(item.opening_amount).toFixed(2) : 'No definido' } Bs. <br />
                                        <small>{ moment(item.opening).format('DD/MMM/YYYY, h:mm:ss a') }</small>
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        { item.closing_amount ? parseFloat(item.closing_amount).toFixed(2) : 'No definido' } Bs. <br />
                                        <small>{ item.closing ? moment(item.closing).format('DD/MMM/YYYY, h:mm:ss a') : 'No definida' }</small>
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        { item.real_amount - item.closing_amount > 0 ? parseFloat(item.real_amount - item.closing_amount).toFixed(2) : '0.00' } Bs.
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        { parseFloat(item.missing_amount).toFixed(2) } Bs.
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

const PrintReport = React.forwardRef((props, ref) => {
    const { company, reportType, reportDate } = props;
    return(
        <div ref={ref}>
            <WaterMark
                src={ company.logos ? `${API}/storage/${company.logos.replace('.', '-cropped.')}` : null}
            />
            <Grid container direction="row" justify="space-between" alignItems="flex-start" style={{marginTop: 10, marginBottom: 40}}>
                {/* Información del restaurante */}
                {
                    company != undefined &&
                    <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                            <Grid item>
                                <img src={ company.logos ? `${API}/storage/${company.logos.replace('.', '-cropped.')}` : `${API}/images/logo.png`} alt='logo' style={{width: 80}} />
                            </Grid>
                            <Grid item style={{marginLeft: 10}}>
                                <b style={{fontSize: 20}}>{ company.name }</b><br/>
                                {
                                    company.phones && <><small>{ company.phones }</small><br/></>
                                }
                                {
                                    company.address && <><small>{ company.address }</small><br/></>
                                }
                                { company.city && <small>{ company.city.name } - { company.city.state } - { company.city.country }</small> }
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {/* Descripción del reporte */}
                <Grid item style={{textAlign: 'right'}}>
                    <b style={{fontSize: 30}}>Reporte de cajas</b><br/>
                    {/* Diaria */}
                    { reportType == 'day' && <span>{ moment(reportDate.date).format('dddd, DD [de] MMMM [de] YYYY') }</span> }
                    
                    {/* Mensual */}
                    { reportType == 'month' && <span>{ moment(reportDate.date).format('MMMM [de] YYYY') }</span> }
                    
                    {/* Por rango de fecha */}
                    {
                        reportType == 'range' &&
                        <>
                            <span>Del { moment(reportDate.start).format('DD [de] MMMM [de] YYYY') }</span><br/>
                            <span>Al { moment(reportDate.finish).format('DD [de] MMMM [de] YYYY') }</span>
                        </>
                    }
                </Grid>
            </Grid> 

            <ReportCashiers {...props} />
            <FooterPrint/>
        </div>
    );
})

export default connect(mapStateToProps)(withSnackbar(CashiersReports));