import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Tabs,
    Tab,
    Typography,
    Box,
    Button,
    Card,
    CardContent,
    CardActionArea,
    CardMedia,
    Tooltip,
    IconButton,
    CircularProgress,
    AppBar,
    Toolbar,
    // Form input
    TextField,
    // Table
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    // Dialog
    Dialog,
    DialogContent,
    Slide,
    Fab,
    Badge
} from '@material-ui/core';

import {
    ToggleButtonGroup,
    ToggleButton
} from '@material-ui/lab';

import { IoIosList, IoIosKeypad, IoIosCart, IoIosTrash, IoIosCheckmarkCircleOutline, IoIosClose } from "react-icons/io";
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import { io } from "socket.io-client";
import CounterInput from "react-counter-input";

// Components
import { env } from '../../../env';

const { API, SOCKET_IO, color } = env;
const defaultImg = `${API}/images/default-image.png`;
const socket = io(SOCKET_IO);

// Sale confirmation
const transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// Tab panels config
function tabProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class OrderIndex extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            },
            tabActive: 0,
            productsCategories: [],
            productCategoryIdActive: null,
            productsCategoriesShowType: this.props.globalConfig.TPVSales.productsShowType,
            loading: true,
            // Products details
            saleDetails: [],
            // Form sales
            inputSaleAmount: 0,
            showDialogSaleConfirm: false,
            showDialogSaleFinish: false,
            formSaleSending: false,
            inputObservations: '',
            // Company
            company: null,
            branchId: this.props.match.params.branchId,
            cashier: null,
            tableNumber: this.props.match.params.tableNumber,
            orderNumber: 0,
            orderTotal: 0,
            orderDetails: []
        }
    }

    async componentDidMount(){
        document.title = "Menú | " + env.appName;
        this.getCompanyInfo();
        this.getCashiers();
    }

    getCompanyInfo(){
        fetch(`${API}/api/companies/${this.props.match.params.companySlug}`)
        .then(res => res.json())
        .then(res => {
            if(res.company){
                let { company } = res;

                // Si el restaurante tiene la opción de pedido desde la mesa habilitada
                if(company.orders_table == 1){
                    this.setState({company}, () => {
                        this.getProducts();
                    });
                }
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    getProducts(){
        let { productsTPV } = this.props;
        if(productsTPV.length){
            this.setState({productsCategories: productsTPV});
        }else{
            fetch(`${API}/api/order/company/${this.state.company.id}/products`)
            .then(res => res.json())
            .then(res => {
                if(res.products_category){
                    let { products_category } = res;
                    products_category = products_category.sort((a, b) => b.count_sales - a.count_sales);
                    this.setState({productsCategories: products_category, loading: false});
                    this.props.setProductsTPV(products_category);
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    getCashiers(){
        fetch(`${API}/api/order/branch/${this.state.branchId}/cashier`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            if(res.cashier){
                this.setState({cashier: res.cashier});
            }
        })
        .catch(error => {
            console.log(error);
        });
    }

    handleChangeTab = (event, newValue) => {
        this.setState({tabActive: newValue})
    }

    // sale Details
    handlePressProduct(product){
        let saleDetails = this.state.saleDetails;
        let indexFound = false;

        saleDetails.map((detail, index) => {
            if(detail.id == product.id){
                saleDetails[index].quantity = (parseFloat(saleDetails[index].quantity) +1).toFixed(2);
                saleDetails[index].subtotal = (parseFloat(saleDetails[index].subtotal) + parseFloat(product.price)).toFixed(2);
                indexFound = true;
            }
        });

        if(!indexFound){
            let newProduct = {
                ...product,
                quantity: 1,
                subtotal: product.price
            }
            saleDetails.push(newProduct);
        }

        this.setState({saleDetails, inputSaleAmount: (this.state.inputSaleAmount + parseFloat(product.price) ) });
        this.props.enqueueSnackbar('Item agregado al carrito', { variant: 'info' });
    }

    handleChangeQuantity(id, quantity){
        let total = 0;
        let saleDetails = this.state.saleDetails.map(item => {
            let newItem = item;
            if(item.id == id){
                newItem = {
                    ...item,
                    quantity,
                    subtotal: (item.price * quantity).toFixed(2)
                }
            }
            total += parseFloat(newItem.subtotal);
            return newItem;
        });
        this.setState({saleDetails, inputSaleAmount: total });
    }

    handleDeleteSaleDetail(id){
        let saleDetails = this.state.saleDetails;
        let indexDelete = null;
        let total = 0;
        saleDetails.map((item, index) => {
            if(item.id == id){
                indexDelete = index;
            }else{
                total += parseFloat(item.subtotal);
            }
        });
        saleDetails.splice( indexDelete, 1 );
        this.setState({saleDetails, inputSaleAmount: total });
    }

    handleChangeProductsCategoriesShowType = (event, newAlignment) => {
        if(newAlignment){
            let { TPVSales } = this.props.globalConfig;
            let newTPVSales = {
                ...TPVSales,
                productsShowType: newAlignment
            }
            let newGlobalConfig = {
                ...this.props.globalConfig,
                TPVSales: newTPVSales
            }
            this.setState({productsCategoriesShowType: newAlignment}, () => this.props.setGlobalConfig(newGlobalConfig));
        }
    }

    handleConfirm(){
        if(this.state.saleDetails.length > 0){
            this.setState({showDialogSaleConfirm: true});
        }else{
            this.props.enqueueSnackbar('Debes seleccionar algún producto!', { variant: 'warning' });
        }
    }

    handleSubmitSale(){

        if(!this.state.formSaleSending && this.state.saleDetails.length){
            this.setState({formSaleSending: true, orderTotal: this.state.inputSaleAmount}, async () => {
                let params = {
                    company_id: this.state.company.id,
                    branch_id: this.state.branchId,
                    user_id: null,
                    cashier_id: this.state.cashier ? this.state.cashier.id : null,
                    payment_type: 1,
                    sale_type: 'mesa',
                    sales_status_id: 1,
                    total: this.state.inputSaleAmount,
                    discount: 0,
                    amount_received: 0,
                    table_number: this.state.tableNumber,
                    observations: this.state.inputObservations,
                    bill: false,
                    sale_details: this.state.saleDetails
                }

                let options = {
                    method: 'POST',
                    body: JSON.stringify(params),
                    headers: this.state.headers
                }
                let res = await fetch(`${API}/api/order/sales/create`, options)
                .then(res => res.json())
                .then(res => res)
                .catch(error => {
                    console.log(error);
                })
                .finally(() => this.setState({formSaleSending: false, showDialogSaleConfirm: false}))
                if(res.sale){
                    this.setState({
                        saleDetails: [],
                        inputSaleAmount: 0,
                        inputObservations: '',
                        orderNumber: res.sale.sale_number,
                        orderDetails: res.sale.details,
                        showDialogSaleFinish: true
                    });

                    // Emitir evento para cocina
                    socket.emit(`change status`, {status: 1, branchId: this.state.branchId});
                }else if(res.info){
                    this.props.enqueueSnackbar(res.info, { variant: 'info' });
                }else if(res.error){
                    this.props.enqueueSnackbar(res.error, { variant: 'error' });
                    console.log(res.message);
                }
            });
        }
    }

    render() {
        return (
            <>
                <div className='app'>
                    <main style={{padding: 10}}>

                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" wrap="nowrap">
                        <Grid item style={{ paddingRight: 10 }}>
                            <img
                                src={ this.state.company ? this.state.company.logos ? `${API}/storage/${this.state.company.logos.replace('.', '-cropped.')}` : '/favicon.ico' : '/favicon.ico' }
                                style={{ width: 50, height: 50 }}
                                alt={ this.state.company ? this.state.company.name : 'Restaurante' }
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant='subtitle1'>{ this.state.company ? this.state.company.name : 'Restaurante' }</Typography>
                            <Typography variant='caption' style={{color: '#85929E'}}>{ this.state.company ? this.state.company.slogan : '' }</Typography>
                        </Grid>
                    </Grid>

                        <Grid container direction="row" justify="flex-end" alignItems="center" style={{ marginTop: 5, marginBottom: 5, paddingRight: 5 }}>
                            {/* Products show type */}
                            <ToggleButtonGroup
                                value={ this.state.productsCategoriesShowType }
                                exclusive
                                onChange={ this.handleChangeProductsCategoriesShowType }
                                aria-label="Tipo de visualización"
                                size="small"
                            >
                                <ToggleButton value="cuadricula" aria-label="Vista en cuadrícula">
                                    <IoIosKeypad size={20} />
                                </ToggleButton>
                                <ToggleButton value="agrupado" aria-label="Vista agrupada">
                                    <IoIosList size={20} />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        {/* Products list and sale details */}
                        <Paper elevation={2} style={{marginBottom: 50}}>
                            <Grid container spacing={2}>
                                {/* Tabs products */}
                                <Grid item xs={12} sm={12} >
                                    <AppBar position="static" style={{backgroundColor: color.secondary, color: 'white', margin: 0}}>
                                        <Tabs
                                            value={ this.state.tabActive }
                                            onChange={ this.handleChangeTab }
                                            aria-label="Panel de productos"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                        >
                                            {
                                                this.state.productsCategories.map((category, index) => {
                                                    if(category.products.length){
                                                        return <Tab key={ category.id } label={ category.name } {...tabProps(index)} style={{ fontSize: 11 }} />                                                        
                                                    }
                                                })
                                            }
                                        </Tabs>
                                    </AppBar>
                                    <div>
                                        
                                        {/* Recorrer lista de productos */}
                                        {
                                            this.state.productsCategories.map((category, index) => {
                                                
                                                if(category.products.length){
                                                    // Show group by
                                                    if(this.state.productsCategoriesShowType === 'agrupado'){
                                                        return (
                                                            <TabPanel key={category.id} value={ this.state.tabActive } index={index}>
                                                                {
                                                                    Array.from(
                                                                    category.products.reduce((a,{type, ...rest})=>{
                                                                        return a.set(type, [rest].concat(a.get(type)||[]));
                                                                    }, new Map())
                                                                    ).map(([type, children])=>({type,children}))
                                                                    .map(type => {
                                                                        return(
                                                                            <div key={type.type} style={{marginBottom: 20}}>
                                                                                <Typography variant='body2' style={{marginBottom: 5}}>{type.type}</Typography>
                                                                                <Grid container spacing={1} style={{margin: 0}} >
                                                                                    {
                                                                                        type.children.map(product => <CardProduct key={product.id} product={product} onClick={ (e) => this.handlePressProduct(product) } />)
                                                                                    }
                                                                                </Grid>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </TabPanel>
                                                        )
                                                    }
                                                    
                                                    // Show whitout group by
                                                    return(
                                                        <TabPanel key={category.id} value={ this.state.tabActive } index={index}>
                                                            <Grid container spacing={1} style={{margin: 0}} >
                                                                {
                                                                    category.products.map(product => <CardProduct key={product.id} product={product} onClick={ (e) => this.handlePressProduct(product) } />)
                                                                }
                                                            </Grid>
                                                        </TabPanel>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>

                        <div style={{ position: 'fixed', bottom: 70, right: 35 }}>
                            <Fab color="primary" aria-label="add" onClick={() => this.setState({showDialogSaleConfirm: !this.state.showDialogSaleConfirm}) }>
                                <Badge badgeContent={this.state.saleDetails.length} color="secondary" anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}>
                                    <IoIosCart size={25} />
                                </Badge>
                            </Fab>
                        </div>

                        {/* Order confirmation */}
                        <Dialog
                            open={ this.state.showDialogSaleConfirm }
                            // open={ true }
                            TransitionComponent={ transition }
                            keepMounted
                            onClose={() => this.setState({showDialogSaleConfirm: false}) }
                            fullScreen
                        >
                            <AppBar position="static" style={{paddingTop: 20, paddingBottom: 20}}>
                                <Toolbar variant="fullWidth">
                                    <Typography edge="start" variant="h6">Orden de compra</Typography>
                                    <div style={{ position: 'absolute', right: 20 }}>
                                        <IconButton edge="end" color="inherit" onClick={() => this.setState({showDialogSaleConfirm: false})} aria-label="close">
                                            <IoIosClose size={50} />
                                        </IconButton>
                                    </div>
                                </Toolbar>
                            </AppBar>
                            <DialogContent style={{padding: 10}}>
                                {/* Table details products */}
                                { this.state.saleDetails.length > 0 &&
                                    <Paper style={{marginTop: 20, marginBottom: 80}} elevation={1}>
                                        
                                        {/* Table details */}
                                        <TableContainer>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center"><Typography variant="caption" color="textSecondary">Producto</Typography></TableCell>
                                                        <TableCell align="right"><Typography variant="caption" color="textSecondary">Subtotal</Typography></TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {this.state.saleDetails.map((row) => (
                                                    <TableRow key={row.id}>
                                                        <TableCell component="th" scope="row" style={{ padding: '5px 0px' }}>{
                                                            <Card elevation={0} style={{display: 'flex'}}>
                                                                <CardMedia
                                                                    component="img"
                                                                    alt={ row.name }
                                                                    image={row.image ? `${API}/storage/${row.image.replace('.', '-cropped.')}` : defaultImg}
                                                                    title={ row.name }
                                                                    style={{ height: 70, width: 70 }}
                                                                />
                                                                <div>
                                                                    <CardContent style={{ paddingTop: 0, paddingBottom: 0, }}>
                                                                        <Typography noWrap={true} variant="subtitle2"><b>{ row.name }</b></Typography>
                                                                        <Typography noWrap={true} variant="caption" color="textSecondary">{ row.type ? row.type : row.short_description } | <b>{ row.price }</b></Typography>
                                                                        <CounterInput
                                                                            count={ parseInt(row.quantity) }
                                                                            min={1}
                                                                            onCountChange={count => this.handleChangeQuantity(row.id, count)}
                                                                            btnStyle={{ backgroundColor: color.primary, color: 'white', borderRadius: 3, padding: '3px 8px' }}
                                                                            inputStyle={{ height: 28 }}
                                                                        />
                                                                    </CardContent>
                                                                </div>
                                                            </Card>
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right" style={{ fontSize: 15, padding: '0px 5px' }}>{ row.subtotal }</TableCell>
                                                        <TableCell align="right" style={{padding: 0}}>
                                                            <IconButton aria-label="Borrar" component="span" style={{paddingRight: 15}} onClick={ e => this.handleDeleteSaleDetail(row.id) }>
                                                                <IoIosTrash color='#CD272A' size={20} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <Grid item xs={12} md={12} style={{ marginTop: 10, textAlign: 'right', marginRight: 10 }}>
                                            <Typography variant='h6'><Typography variant='caption'>TOTAL Bs.</Typography> { this.state.inputSaleAmount.toFixed(2) }</Typography>
                                        </Grid>

                                        {/* Observations */}
                                        <Grid style={{margin: 10, marginTop: 20}}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="input-observations"
                                                label="Extras"
                                                placeholder='Describa las solicitudes extras del cliente'
                                                name="observations"
                                                helperText="Ej: Sin ensalada, sin aceitunas, etc."
                                                value={ this.state.inputObservations }
                                                onChange={ event => this.setState({inputObservations: event.target.value}) }
                                                // style={{ marginBottom: 10 }}
                                                multiline
                                                rows={2}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} style={{marginTop: 30, paddingBottom: 10}}>
                                            <Button
                                                variant="contained"
                                                style={{backgroundColor: color.primary, color: 'white'}}
                                                fullWidth
                                                size="large"
                                                onClick={() =>  this.state.cashier ? this.handleSubmitSale() : this.props.enqueueSnackbar('Servicio no disponible', { variant: 'error' })}
                                                endIcon={<IoIosCheckmarkCircleOutline />}
                                                // disabled={ this.state.cashier ? false : true }
                                            >
                                                Ordenar
                                            </Button>

                                        </Grid>

                                    </Paper>
                                }
                            </DialogContent>
                        </Dialog>

                        {/* Order finish    */}
                        <Dialog
                            open={ this.state.showDialogSaleFinish }
                            // open={ true }
                            TransitionComponent={ transition }
                            keepMounted
                            onClose={() => this.setState({showDialogSaleFinish: false}) }
                            fullScreen
                        >
                            <DialogContent style={{padding: 10}}>
                                <div style={{ textAlign: 'center', marginTop: 50, marginBottom: 30 }}>
                                    <IoIosCheckmarkCircleOutline size={100} color={color.primary} />
                                    <Typography variant='h6'>Gracias por tu orden</Typography>
                                    <br />
                                    <Typography variant='h5'>Ticket N&deg; { this.state.orderNumber }</Typography>
                                </div>

                                {/* Table details */}
                                <TableContainer>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"><Typography variant="caption" color="textSecondary">Producto</Typography></TableCell>
                                                <TableCell align="right"><Typography variant="caption" color="textSecondary">Subtotal</Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {this.state.orderDetails.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell component="th" scope="row" style={{ padding: '5px 0px' }}>{
                                                    <Card elevation={0} style={{display: 'flex'}}>
                                                        <CardMedia
                                                            component="img"
                                                            alt={ row.product.name }
                                                            image={row.product.image ? `${API}/storage/${row.product.image.replace('.', '-cropped.')}` : defaultImg}
                                                            title={ row.product.name }
                                                            style={{ height: 70, width: 70 }}
                                                        />
                                                        <div>
                                                            <CardContent style={{ paddingTop: 0, paddingBottom: 0, }}>
                                                                <Typography noWrap={true} variant="subtitle2"><b>{ row.product.name }</b></Typography>
                                                                <Typography noWrap={true} variant="caption" color="textSecondary">{ row.product.type ? row.product.type : row.product.short_description } | <b>{ row.price }</b></Typography>
                                                            </CardContent>
                                                        </div>
                                                    </Card>
                                                    }
                                                </TableCell>
                                                <TableCell align="right" style={{ fontSize: 15, padding: '0px 5px' }}>{ (row.price * row.quantity).toFixed(2) }</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid item xs={12} md={12} style={{ marginTop: 10, marginBottom: 50, textAlign: 'right', marginRight: 10 }}>
                                    <Typography variant='h5'><Typography variant='caption'>TOTAL Bs.</Typography> { this.state.orderTotal.toFixed(2) }</Typography>
                                    <div style={{textAlign: 'center', marginTop: 50}}>
                                        <Typography variant='caption'>El cobro se realizará al momento de recoger tu orden <br /> Gracias por tu preferencia </Typography>
                                    </div>
                                </Grid>

                            </DialogContent>
                        </Dialog>

                        {/* SplashScreen */}
                        { this.state.loading &&
                            <div style={{ position: 'fixed', top: 0, left: 0, backgroundColor: 'white',  width: '100%', height: '100%', zIndex: 1 }}>
                                <div style={{position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)', textAlign: 'center'}}>
                                    { this.state.company &&
                                        <>
                                            <img
                                                src={ this.state.company.logos ? `${API}/storage/${this.state.company.logos.replace('.', '-cropped.')}` : '/favicon.ico' }
                                                style={{ width: 100, height: 100 }}
                                                alt={ this.state.company ? this.state.company.name : 'Restaurante' }
                                            />
                                            <div style={{ marginTop: 10 }}>
                                            <Typography variant='h5'>{ this.state.company ? this.state.company.name : '' }</Typography>
                                            </div>
                                        </>
                                    }
                                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                                        <CircularProgress color="inherit" />
                                    </div>
                                </div>
                            </div>
                        }
                    </main>
                </div>

                {/* Footer */}
                <div style={{ position: 'fixed', bottom: 0, height: 50, width: '100%', backgroundColor: color.secondary }}>
                    <div style={{padding: 15, color: 'white', textAlign: 'right'}}>Powered by <a href="http://ideacreativa.dev" target="_blank" rel="noopener noreferrer"><b>IdeaCreativa</b></a></div>
                </div>
            </>
        );
    }
}

const CardProduct = props => {
    let { product } = props;
    return(
        <Grid item xs={6} sm={2} onClick={ props.onClick }>
            <Tooltip title={ product.short_description ? product.short_description : 'Sin descripción' }  >
                <Card >
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            alt={ product.name }
                            height="80"
                            image={ product.image ? `${API}/storage/${product.image.replace('.', '-small.')}` : defaultImg }
                        />
                        <CardContent>
                            <Grid container spacing={1} style={{marginTop: -20}}>
                                <Grid item sm={12}>
                                    <Typography noWrap={true} variant='subtitle2'><b>{ product.name }</b></Typography>
                                    <Typography noWrap={true} variant='subtitle2'>{ product.type ? product.type : (product.short_description ? product.short_description : 'Sin descripción') }</Typography>
                                </Grid>
                                <Grid container style={{marginTop: -10}} justify="flex-end">
                                    <small style={{ color: '#1D6CC6' }}>{ product.price }<small style={{marginLeft: 2}}>Bs.</small></small>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Tooltip>
        </Grid>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <>{children}</>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
    return {
        globalConfig: state.globalConfig,
        productsTPV: state.productsTPV
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProductsTPV : (productsTPV) => dispatch({
            type: 'SET_PRODUCTS_TPV',
            payload: productsTPV
        }),
        setGlobalConfig : (globalConfig) => dispatch({
            type: 'SET_GLOBAL_CONFIG',
            payload: globalConfig
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(OrderIndex));