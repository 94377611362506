import React, { Component } from 'react';
import {
    Grid,
    Button,
    Tooltip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Avatar,
    Chip,
    Backdrop,
    CircularProgress
} from '@material-ui/core';

import { IoIosMenu, IoMdThumbsUp } from "react-icons/io";

import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import axios from "axios";
import { withSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';
import { io } from "socket.io-client";

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { EmptyList, LoadingList } from "../../components/forms";
import { env } from '../../../env';

const { API, SOCKET_IO, color } = env;
const defaultImg = `${API}/images/default-image.png`;
const socket = io(SOCKET_IO);

const tableColumns = [
    { id: 'number', label: 'Ticket' },
    { id: 'details', label: 'Detalle' },
    { id: 'type', label: 'Tipo' },
    { id: 'hour', label: 'Hora de pedido' },
    { id: 'observations', label: 'Observaciones' },
    { id: 'actions', label: 'Opciones', align: 'right' },
];

class SalesKitchenList extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            loadingList: false,
            branchId: this.props.authSession.branch.id,
            tableRows: [],
            sidebarToggled: false,
            page: 0,
            salesLis: [],
            rowsPerPage: 10,
            loading: false,
            play: false,
        }
    }

    audio = new Audio(`${API}/sounds/alert1.mp3`);

    componentDidMount(){
        // Obtener el rol del usuario
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3, 4].find(element => element === role.id)});

        document.title = "Pedidos pendientes | " + env.appName;
        if(this.state.branchId){
            this.getSales();
        }

        socket.on(`kitchen branch ${this.state.branchId}`, data => {
            this.getSales();
            this.togglePlay();
        });

        this.audio.addEventListener('ended', () => this.setState({ play: false }));
    }

    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({ play: false }));
    }

    togglePlay = () => {
        this.setState({ play: !this.state.play }, () => {
          this.state.play ? this.audio.play() : this.audio.pause();
        });
    }

    createData(id, number, details, type, tableNumber, observations, hour) {
        let tableDetails = (
            <>
                {
                    details.map(item => {
                        let image= item.product.image ? `${API}/storage/${item.product.image.replace('.', '-small.')}` : defaultImg;
                        return(
                            <div key={ item.id }>
                                <Chip avatar={<Avatar src={image} />} label={ `${item.quantity} ${item.product.name} - ${item.product.type}` } style={{ margin: 5 }} />   
                            </div>
                        )
                    })
                }
            </>
        );

        let typeLabel = (
            <Tooltip title={ type } placement="top">
              <Chip
                size="small"
                style={{ backgroundColor: type == 'mesa' ? color.primary : color.blue , color: 'white' }}
                label={ `${type} ${tableNumber ? tableNumber : ''}` }
              />
            </Tooltip>
        );

        let tableOptions = (
            <>
                <Tooltip title="Pedido listo" placement="bottom">
                    <Button
                        variant="contained"
                        style={{ backgroundColor: color.primary, color: 'white' }}
                        endIcon={<IoMdThumbsUp />}
                        onClick={ event => this.handleStatus(id) }
                    >
                        Listo
                    </Button>
                </Tooltip>
            </>
        );
        return { number: `Nro ${number}`, details: tableDetails, type: typeLabel, hour: moment(hour).fromNow(), observations: observations ? observations : 'Ninguna', actions: tableOptions };
    }

    getSales(){
        this.setState({loadingList: true});
        fetch(`${API}/api/branch/${this.state.branchId}/sales/kitchen/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            this.renderRowTable(res.sales);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => this.setState({loadingList: false}));
    }

    renderRowTable(sales){
        let rows = [];
        if(sales){
        this.setState({salesLis: sales});
        sales.map(sale => {
            rows.push(this.createData(sale.id, sale.sale_number, sale.details, sale.sale_type, sale.table_number, sale.observations, sale.created_at));
        });
        }
        this.setState({tableRows: rows});
    }

    handleStatus(id){
        this.setState({loading: true});

        let params = {
            sales_status_id: 3
        };

        axios({
            method: 'post',
            url: `${API}/api/sales/${id}/update/status`,
            data: JSON.stringify(params),
            headers: this.state.headers
        })
        .then(res => {
            if(res.data.sale){
                let { sale } = res.data;
                let salesLis = [];
                this.state.salesLis.map(item => {
                    if(item.id != sale.id){
                        salesLis.push(item);
                    }
                });

                this.renderRowTable(salesLis);
                this.props.enqueueSnackbar('Pedido listo!', { variant: 'success' });

                // Emitir para actualizar vista del cajero
                socket.emit(`change status`, {status: 3, branchId: this.state.branchId, number: sale.sale_number});
            }else{
                this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
                console.log(res.data.message);
            }
        })
        .catch(error => console.log(error))
        .finally(() => {
            this.setState({loading: false});
        });
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }

        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            Pedidos
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <Grid style={{marginTop: 20}}>
                            <div style={{ marginTop: 20, marginBottom: 50 }}>
                                <Paper >
                                    { this.state.loadingList && <LoadingList /> }
                                    { this.state.tableRows.length === 0 && !this.state.loadingList &&
                                        <EmptyList
                                            icon={<img src="/img/break.png" style={{width: 100}} />}
                                            title="No tienes pedidos pendientes"
                                            subtitle="Puedes tomarte un descanso."
                                        />
                                    }
                                    { this.state.tableRows.length > 0 &&
                                        <>
                                            <TableContainer>
                                                <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                    {tableColumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth, backgroundColor: color.primary, color: 'white', fontWeight: 500 }}
                                                        >
                                                        {column.label}
                                                        </TableCell>
                                                    ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.tableRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                {tableColumns.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                component="div"
                                                count={this.state.tableRows.length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                labelRowsPerPage='Items por página'
                                                onChangePage={(event, newPage) => this.setState({page: newPage})}
                                                onChangeRowsPerPage={(event) => this.setState({rowsPerPage: +event.target.value, page: 0})}
                                            />
                                        </>
                                    }
                                </Paper>
                            </div>
                        </Grid>
                    </main>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

export default connect(mapStateToProps)(withSnackbar(SalesKitchenList));