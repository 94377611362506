import React, { Component } from 'react';
import {
    Grid,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button
} from '@material-ui/core';

import {
    Alert,
    AlertTitle,
} from '@material-ui/lab';

import { IoIosMenu } from "react-icons/io";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { withSnackbar } from 'notistack';
import QRCode from 'qrcode.react';

import { AiOutlineQrcode } from "react-icons/ai";

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { env } from '../../../env';

const { DOMAIN, API, color } = env;

class QrGenerator extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            company: this.props.authSession.company,
            sidebarToggled: false,
            branches: [],
            branchId: 'none',
            tableNumber: ''
        }
    }

    componentDidMount(){
        // Obtener el rol del usuario
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3, 4].find(element => element === role.id)});

        document.title = "Generar QR | " + env.appName;

        // Get branches company
        let { company } = this.props.authSession;
        fetch(`${API}/api/company/${company.id}/branches/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            this.setState({branches: res.branches});
        })
        .catch(error => {
            console.log(error);
        });
    }

    downloadQRCode = () => {

        if(this.state.branchId == 'none' || this.state.tableNumber == ''){
            this.props.enqueueSnackbar('Debes seleccionar una sucursal y un número de mesa!', { variant: 'warning' });
            return null;
        }

        const qrCodeURL = document.getElementById('qrCodeEl')
                                        .toDataURL("image/png")
                                        .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = `Mesa ${this.state.tableNumber}.png`;
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }
        
        return (
            <>
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            Generador de QR
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <Paper style={{ marginTop: 20, backgroundColor: 'white', padding: 30 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <Grid item xs={12} style={{ margin: '20 0' }}>
                                        <Alert severity="info">
                                            <AlertTitle>Información</AlertTitle>
                                            Selecciona la sucursal, el número de mesa y presiona el botón <br /> <b>"GENERAR QR"</b>.
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel htmlFor="outlined-adornment-password">Sucursal</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-filled-label"
                                                id="demo-simple-select-filled"
                                                variant="outlined"
                                                label="Sucursal"
                                                inputProps={{ 'aria-label': 'Sucursal' }}
                                                required
                                                fullWidth
                                                value={ this.state.branchId }
                                                onChange={ event => this.setState({branchId: event.target.value}) }
                                                style={{ marginBottom: 20 }}
                                                >
                                                    <MenuItem disabled key={0} value="none">
                                                        <em>Selecciona la sucursal</em>
                                                    </MenuItem>
                                                    {
                                                    this.state.branches.map(branch => 
                                                        <MenuItem key={branch.id} value={branch.id}>{branch.name}</MenuItem>
                                                    )
                                                    }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="number"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="input-number"
                                            label="Número de mesa"
                                            placeholder='10'
                                            // helperText="Nombre descriptivo del producto"
                                            inputProps={{ type: 'number', min: '1', step: '1' }}
                                            value={ this.state.tableNumber }
                                            onChange={ event => this.setState({tableNumber: event.target.value}) }
                                            style={{ marginBottom: 20 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            style={{backgroundColor: color.primary, color: 'white'}}
                                            fullWidth
                                            size="large"
                                            onClick={ this.downloadQRCode }
                                            endIcon={<AiOutlineQrcode />}
                                        >
                                            Generar QR
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={6} style={{ overflow: 'auto'}}>
                                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 400, width: "100%" }}>
                                        <QRCode
                                            id="qrCodeEl"
                                            size={400}
                                            value={`${DOMAIN}/order/${this.state.company.slug}/${this.state.branchId}/${this.state.tableNumber}`}
                                            level="H"
                                            includeMargin={true}
                                            imageSettings={{ src: '/favicon.ico', width: 60, height: 60 }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>

                    </main>

                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

export default connect(mapStateToProps)(withSnackbar(QrGenerator));