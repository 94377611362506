import React, { Component } from 'react';
import {
  Grid,
  Button,
  IconButton,
  Tooltip,
  TextField,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Slide,
  Chip,
  Typography,
  Collapse,
  Box,
  // List
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemAvatar,
  Avatar,
  // Cards
  Card,
  CardMedia,
  CardContent
} from '@material-ui/core';

import {
  Autocomplete,
  Alert,
} from '@material-ui/lab';

import {
  IoIosMenu,
  IoIosAddCircle,
  IoIosTrash,
  IoIosPrint,
  IoIosCash,
  IoMdThumbsUp,
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
  IoIosBasket,
  IoMdTime,
  IoIosCheckmarkCircleOutline,
  IoMdCash
} from "react-icons/io";
import { FaQrcode } from "react-icons/fa";
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import axios from "axios";
import CounterInput from "react-counter-input";
import { withSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';
import { io } from "socket.io-client";

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { EmptyList, LoadingList } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API, SOCKET_IO, color } = env;
const defaultImg = `${API}/images/default-image.png`;
const socket = io(SOCKET_IO);

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableColumns = [
    { id: 'number', label: 'Nro' },
    { id: 'customer', label: 'Cliente' },
    { id: 'subtotal', label: 'Sub Total' },
    { id: 'discount', label: 'Descuento' },
    { id: 'total', label: 'Total', align: 'right' },
    { id: 'payment_type', label: 'Modo de pago', align: 'right' },
    { id: 'status', label: 'Estado', align: 'right' },
    { id: 'actions', label: 'Opciones', align: 'right' },
];

class salesList extends Component {
  constructor(props){
    super(props)
    this.state = {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization': `Bearer ${this.props.authSession.token}`
      },
      permission: null,
      loadingList: false,
      company: this.props.authSession.company,
      branchId: this.props.authSession.branch.id,
      defaultImg: `${API}/images/default-image.png`,
      tableRows: [],
      page: 0,
      rowsPerPage: 10,
      sidebarToggled: false,
      inputFilter: '',
      saleId: null,
      salesList: [],
      productsList: [],
      productsAddList: [],
      customersList: [],
      deleteId: 0,
      // Dialogs
      showDialogDelete: false,
      showDialogAddItem: false,
      showDialogCloseSale: false,
      // Form close sale
      inputFirstName: '',
      inputCI: '',
      inputReceivedAmount: 0,
      inputDiscountAmount: 0,
      inputSaleAmount: 0,
      saleDetails: [],
      // Print
      enablePrintRecipe: this.props.globalConfig.sales ? this.props.globalConfig.sales.print : true,
      printKitchenTickets: this.props.globalConfig.sales ? this.props.globalConfig.sales.printKitchenTickets : false,
      printerType: this.props.globalConfig.sales ? this.props.globalConfig.sales.printerType : 'normal',
      printerServerUrl: this.props.globalConfig.sales ? this.props.globalConfig.sales.printerServerUrl : '',
      printerName: this.props.globalConfig.sales ? this.props.globalConfig.sales.printerName : '',
      play: false,
    }
  }

  audio = new Audio(`${API}/sounds/alert2.mp3`);

  componentDidMount(){
    // Obtener el rol del usuario
    let { roles } = this.props.authSession.user;
    let role = roles.length > 0 ? roles[0] : {};
    this.setState({permission: [2, 3, 4].find(element => element === role.id)});

    document.title = "Ventas del día | " + env.appName;
    if(this.state.branchId){
      this.getSales();
      this.getProducts();
      this.getCustomers();
    }

    socket.on(`sales branch ${this.state.branchId}`, data => {
      this.getSales();
      if(data.status == 1){
        this.togglePlay();
      }
    });

    this.audio.addEventListener('ended', () => this.setState({ play: false }));
  }

  componentWillUnmount() {
    this.audio.removeEventListener('ended', () => this.setState({ play: false }));
  }

  togglePlay = () => {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause();
    });
  }

  createData(id, number, customer, type, payment_type, table_number, status, total, discount, hour, details, paidOut, observations) {
    let detail = (
      <>
        <Typography>{customer}</Typography>
        <b style={{ color: type === 'mesa' ? color.primary : color.blue }}>{ type } { table_number ? table_number : ''}</b> <br/>
        <small>{moment(hour).fromNow()}</small>
      </>
    );
    let tableOptions = (
      <>
        { this.state.enablePrintRecipe &&
          <Tooltip title="Reimprimir" placement="top">
            <Fab aria-label="Reimprimir" size='small' onClick={ () => this.printSale(id) } style={{margin: 5}}>
              <IoIosPrint size={25} style={{color: color.blue}} />
            </Fab>
          </Tooltip>
        }
        { status.id == 1 &&
          <Tooltip title="Aceptar pedido" placement="top">
            {/* Si se acpta un pedido se debe pasar el ID del usuario que lo está aceptando */}
            <Fab aria-label="Aceptar pedido" size='small' onClick={ () => this.handleStatus(id, 2, this.props.authSession.user.id) } style={{margin: 5}}>
              <IoIosCheckmarkCircleOutline size={25} style={{color: color.orange}} />
            </Fab>
          </Tooltip>
        }
        { status.id == 2 &&
          <Tooltip title="Pedido listo" placement="top">
            <Fab aria-label="Pedido listo" size='small' onClick={ () => this.handleStatus(id, 3) } style={{margin: 5}}>
              <IoMdTime size={25} style={{color: color.skyBlue}} />
            </Fab>
          </Tooltip>
        }
        { status.id == 3 &&
          <Tooltip title="Pedido entregada" placement="top">
            <Fab aria-label="Pedido entregada" size='small' onClick={ () => this.handleStatus(id, 5) } style={{margin: 5}}>
              <IoMdThumbsUp size={25} style={{color: color.blue}} />
            </Fab>
          </Tooltip>
        }
        { !paidOut &&
          <>
            <Tooltip title="Cerrar venta" placement="top">
              <Fab aria-label="Cerrar venta" size='small' onClick={ () => this.handleShowSaleDetails(id) } style={{margin: 5}}>
                <IoIosCash size={25} style={{color: color.green}} />
              </Fab>
            </Tooltip>
            <Tooltip title="Agregar producto" placement="top">
              <Fab aria-label="Agregar producto" size='small' onClick={ () => this.setState({ showDialogAddItem: true, saleId: id }) } style={{margin: 5}}>
                <IoIosAddCircle size={25} style={{color: color.violeta}} />
              </Fab>
            </Tooltip>
          </>
        }
        <Tooltip title="Eliminar venta" placement="top">
            <Fab aria-label="Eliminar venta" size='small' onClick={ () => this.setState({ showDialogDelete: true, deleteId: id }) } style={{margin: 5}}>
                <IoIosTrash size={25} style={{color: color.red}} />
            </Fab>
        </Tooltip>
      </>
    );



    let labelType = ''
    let statusLabel = (
      <Tooltip title={ labelType } placement="top">
        <Chip
          size="small"
          style={{ backgroundColor: status.color , color: 'white' }}
          label={ status.name }
        />
      </Tooltip>
    )


    return { number, customer: detail, subtotal: total, discount, total: (total-discount).toFixed(2), payment_type: payment_type == 1 ? <IoMdCash size={20} color={color.green} title='Pago en efectivo' /> : <FaQrcode size={20} color={color.blue} title='Pago con Qr/Transacción' />, status: statusLabel, details, observations, actions: tableOptions };
  }

  getSales(){
    this.setState({loadingList: true});
    let { branch, user } = this.props.authSession;
    fetch(`${API}/api/branch/${branch.id}/sales/${user.id}`, {headers: this.state.headers})
    .then(res => res.json())
    .then(res => {
      this.renderRowTable(res.sales);
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => this.setState({loadingList: false}));
  }

  getProducts(){
    let { company } = this.props.authSession;
    fetch(`${API}/api/company/${company.id}/products/list`, {headers: this.state.headers})
    .then(res => res.json())
    .then(res => {
      this.setState({productsList: res.products});
    })
    .catch(error => {
      console.log(error);
    });
  }

  getCustomers(){
    let { company } = this.props.authSession;
    fetch(`${API}/api/company/${company.id}/customer/list`, {headers: this.state.headers})
    .then(res => res.json())
    .then(res => {
        if(res.customers){
            let { customers } = res;
            this.setState({customersList: customers});
        }
    })
    .catch(error => {
      console.log(error);
    });
  }

  renderRowTable(sales){
    let rows = [];
    if(sales){
      this.setState({salesList: sales});
      sales.map(sale => {
          let customer = `${sale.customer.person.first_name} ${sale.customer.person.last_name ? sale.customer.person.last_name : ''}`
          rows.push(this.createData(sale.id, sale.sale_number, customer, sale.sale_type, sale.payment_type, sale.table_number, sale.status, sale.total, sale.discount, sale.created_at, sale.details, sale.paid_out, sale.observations));
      });
    }
    this.setState({tableRows: rows});
  }

  handleStatus(id, sales_status_id, userId = null){
    this.setState({loadingList: true});

    axios({
        method: 'post',
        url: `${API}/api/sales/${id}/update/status${userId ? '/'+userId : ''}`,
        data: JSON.stringify({sales_status_id}),
        headers: this.state.headers
    })
    .then(res => {
      if(res.data.sale){
        this.getSales();
        switch (sales_status_id) {
          case 2:
            this.props.enqueueSnackbar(`Pedido aceptado!`, { variant: 'success' });
            break;
          case 3:
            this.props.enqueueSnackbar(`Pedido listo!`, { variant: 'success' });
            break;
          case 4:
            this.props.enqueueSnackbar(`Pedido asignado a delivery!`, { variant: 'success' });
            break;
          case 5:
            this.props.enqueueSnackbar(`Pedido entregado!`, { variant: 'success' });
            break;
        
          default:
            break;
        }

        // Emitir para actualizar vista del cajero
        socket.emit(`change status`, {status: sales_status_id, branchId: this.state.branchId});
      }else{
        this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
        console.log(res.data.message);
      }
    })
    .catch(error => console.log(error));
  }

  handleSelectProductId = (event, value, reason) => {
    if(value){
      let { productsAddList } = this.state;
      let item = {
        id: value.id,
        name: value.name,
        type: value.type,
        price: value.price,
        image: value.image,
        quantity: 1
      }
      let findProduct = productsAddList.find(element => element.id == value.id);
      if(!findProduct){
        productsAddList.push(item);
        this.setState({productsAddList});
      }

    }
  }

  handleDeleteProductId(index){
    let { productsAddList } = this.state;
    productsAddList.splice(index, 1);
    this.setState({productsAddList});
  }

  handleChangeQuantityProduct(id, count){
    let { productsAddList } = this.state;
    productsAddList.map((item, index) => {
      if(item.id == id){
        let product = productsAddList[index];
        let updateItem = {
          id: product.id,
          name: product.name,
          type: product.type,
          price: product.price,
          image: product.image,
          quantity: count
        }
        productsAddList[index] = updateItem;
      }
    });
    this.setState({productsAddList});
  }

  handleSubmitAddProducts = async () => {
    this.setState({showDialogAddItem: false, loadingList: true})
    if(this.state.productsAddList.length > 0){
      let params = {
        branch_id: this.state.branchId,
        sale_id: this.state.saleId,
        sale_details: this.state.productsAddList
      }
      let options = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: this.state.headers
      }
      let res = await fetch(`${API}/api/sales/add/items`, options)
      .then(res => res.json())
      .then(res => res)
      .catch(error => {
        console.log(error);
      })
      .finally(() => this.setState({productsAddList: []}));

      if(res.success){
        this.props.enqueueSnackbar(res.success, { variant: 'success' });
        this.getSales();
      }else{
        this.props.enqueueSnackbar(res.error, { variant: 'success' });
      }
    }
  }

  handleShowSaleDetails(id){
    let sale = this.state.salesList.find(element => element.id == id);
    this.setState({
      showDialogCloseSale: true,
      saleId: id,
      inputFirstName: sale.customer.person.first_name,
      inputCI: sale.customer.person.ci_nit ? sale.customer.person.ci_nit : '',
      saleDetails: sale.details,
      inputSaleAmount: parseFloat(sale.total).toFixed(2)
    });
  }

  handleSaleClose = async () => {
    let amountRecived = this.state.inputReceivedAmount ? parseFloat(this.state.inputReceivedAmount) : 0;
    let amountDiscount = this.state.inputDiscountAmount ? parseFloat(this.state.inputDiscountAmount) : 0;
    let amountSale = this.state.inputSaleAmount ? parseFloat(this.state.inputSaleAmount) : 0;
    if(amountRecived >= (amountSale - amountDiscount)){
      let { company } = this.props.authSession;
      this.setState({ showDialogCloseSale: false, inputReceivedAmount: 0, inputDiscountAmount: 0, loadingList: true })
      let params = {
        company_id: company.id,
        sale_id: this.state.saleId,
        first_name: this.state.inputFirstName,
        ci_nit: this.state.inputCI,
        amount_received: this.state.inputReceivedAmount,
        discount: this.state.inputDiscountAmount,
      }
      let options = {
        method: 'POST',
        body: JSON.stringify(params),
        headers: this.state.headers
      }
      let res = await fetch(`${API}/api/sales/update`, options)
      .then(res => res.json())
      .then(res => res)
      .catch(error => {
        console.log(error);
      })

      if(res.success){
        this.props.enqueueSnackbar(res.success, { variant: 'success' });

        this.printSale(res.sale.id);
        this.getSales();
      }else{
        this.props.enqueueSnackbar(res.error, { variant: 'error' });
        console.log(res.message);
      }
    }else{
      this.props.enqueueSnackbar('El monto recibido es menor al total de la venta.', { variant: 'error' });
    }
  }

  printSale(id){
    if(this.state.enablePrintRecipe){
      if(this.state.printerType == 'normal'){
        window.open(`/dashboard/sales/print/${id}`, 'Factura', 'toolbar=0,location=0,menubar=0,width=370,height=420,top=100,left:300');
      }else{
        if(this.state.printerServerUrl && this.state.printerName){
          let sale = this.state.salesList.filter(sale => sale.id == id)[0];
          var saleDetails = []; 
          
          sale.details.map(detail => {
            saleDetails.push({
              "quantity": detail.quantity,
              "product": detail.product.name,
              "total": detail.quantity * detail.price
            });
          });

          let body = {
            "platform": this.props.globalConfig.platform,
            "printer_name": this.state.printerName,
            "company_name": this.state.company.name,
            "sale_number": sale.sale_number,
            "sale_type": sale.sale_type,
            "table_number": sale.table_number,
            "discount": sale.discount,
            "observations": sale.observations,
            "print_kitchen_tickets": this.state.printKitchenTickets,
            "details": saleDetails
          };

          let options = {
            method: 'POST',
            body: JSON.stringify(body),
            headers: this.state.headers
          }
          let slash = this.state.printerServerUrl.substr(this.state.printerServerUrl.length -1) != '/' ? '/' : '';
          fetch(`${this.state.printerServerUrl}${slash}api/print`, options)
            .then(function(response) {
              if(response.ok)
              {
                return response.text();         
              }
              throw new Error('Something went wrong.');
            })
            .then(function(text) {
              console.log('Request successful', text);  
            })  
            .catch(function(error) {
              console.log('Request failed', error);
            });
        }else{
          this.props.enqueueSnackbar('Debe configurar los parámetros de la impresora térmica', { variant: 'warning' });
        }
      }
    }
  }

  handleDelete = () => {
    this.setState({loadingList: true, showDialogDelete: false});
    let options = {
        headers: this.state.headers
    }
    axios.get(`${API}/api/sales/${this.state.deleteId}/delete`, options)
    .then(res => {
        if(res.data.sale_id){
          this.getSales();
          this.props.enqueueSnackbar('Venta anulada correctamente!', { variant: 'success' });
        }else{
          this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
          console.log(res.data.error);
        }
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
      // Si no tiene permiso redirecciona al dashboard
      if(this.state.permission === undefined){
        return <Redirect to="/dashboard" />
      }

      return (
          <div className='app'>
              <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
              <main style={{padding: 10}}>
                  <Navbar
                    title={
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                            </Grid>
                            <Grid item>
                                <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                    Ventas del día
                                </h1>
                            </Grid>
                        </Grid>
                    }
                  />

                  <AlertSubscription />

                  <Grid style={{marginTop: 20}}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Link to='/dashboard/sales/create'>
                              <Button variant="contained" style={{backgroundColor: color.primary, color: 'white'}} endIcon={<IoIosAddCircle/>} > Crear nueva</Button>
                          </Link>
                      </div>

                      <div style={{ marginTop: 20, marginBottom: 50 }}>
                        {/* Filtro */}
                        {/* <Grid container style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                          <Grid item>
                            <Paper component="form" style={{ display: 'flex', flexDirection: 'row-reverse', marginBottom: 30, maxWidth: 400 }}>
                              <Tooltip title="Buscar" placement="bottom" style={{ marginRight: 10 }}>
                                <IconButton color="primary" aria-label="Buscar">
                                  <IoIosSearch size={25} />
                                </IconButton>
                              </Tooltip>
                              <InputBase
                                placeholder="Nro o cliente"
                                inputProps={{ 'aria-label': 'Nro o cliente' }}
                                // onChange={ this.filter }
                                value={ this.state.inputFilter }
                              />
                              <Divider orientation="vertical" style={{ height: 30, margin: 10, marginRight: 20 }} />
                              <Tooltip title="Cambiar sucursal actual" placement="top" style={{ marginLeft: 10 }}>
                                <IconButton color="primary" aria-label="Cambiar sucursal actual" onClick={ (e) =>  this.props.enqueueSnackbar('Ésta opción no está disponible para tu tipo de suscripción.', { variant: 'warning' }) }>
                                  <IoIosHome size={25} />
                                </IconButton>
                              </Tooltip>
                            </Paper>
                          </Grid>
                        </Grid> */}

                        <Paper >
                          { this.state.loadingList && <LoadingList /> }
                          { this.state.tableRows.length === 0 && !this.state.loadingList && <EmptyList /> }
                          { this.state.tableRows.length > 0 &&
                            <>
                              <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={{backgroundColor: color.primary}}/>
                                      {tableColumns.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{ minWidth: column.minWidth, backgroundColor: color.primary, color: 'white', fontWeight: 500 }}
                                        >
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.tableRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                      return (
                                        <Row key={row.id} row={row} />
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={this.state.tableRows.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                labelRowsPerPage='Items por página'
                                onChangePage={(event, newPage) => this.setState({page: newPage})}
                                onChangeRowsPerPage={(event) => this.setState({rowsPerPage: +event.target.value, page: 0})}
                              />
                            </>
                          }
                        </Paper>
                      </div>
                  </Grid>
              </main>

              {/* Add item dialog */}
              <Dialog
                open={ this.state.showDialogAddItem }
                TransitionComponent={ transition }
                onClose={ () => this.setState({ showDialogAddItem: false }) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
              >
                <DialogTitle id="alert-dialog-title">Agregar producto</DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12} sm={12} style={{margin: 0}}>
                      <Autocomplete
                          id="combo-products"
                          fullWidth
                          options={ this.state.productsList }
                          getOptionLabel={(option) => `${option.name}`}
                          renderInput={(params) => <TextField {...params} label="Nombre del producto" variant="outlined" />}
                          renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <Grid container>
                                  <Grid item style={{marginRight: 10}}>
                                    <Avatar src={option.image ? `${API}/storage/${option.image.replace('.', '-cropped.')}` : defaultImg} />
                                  </Grid>
                                  <Grid item>
                                    <div>
                                        <b>{ option.name }</b>
                                        <br/>
                                        <small>{ option.type } - { option.price } Bs.</small>
                                    </div>
                                  </Grid>
                                </Grid>
                              </React.Fragment>
                          )}
                          // inputValue={false}
                          noOptionsText='No hay resultados'
                          onChange={ this.handleSelectProductId }
                      />
                    </Grid>

                    {/* Products details */}
                    <Grid item xs={12} sm={12} style={{margin: 0}}>
                      <List>
                        {
                          this.state.productsAddList.map((item, index) =>
                            <ListItem button id={`item-${item.id}`}>
                              <ListItemAvatar>
                                <Avatar src={item.image ? `${API}/storage/${item.image.replace('.', '-cropped.')}` : defaultImg} />
                              </ListItemAvatar>
                              <ListItemText primary={ item.name } secondary={ `${item.type} - ${item.price} Bs.` } />
                              <ListItemSecondaryAction>
                                <Grid container direction="row" alignItems="center">
                                  <Grid item style={{marginRight: 20}}>
                                    <CounterInput
                                      count={item.quantity}
                                      min={1}
                                      onCountChange={count => this.handleChangeQuantityProduct(item.id, count)}
                                      btnStyle={{ backgroundColor: color.primary, color: 'white', borderRadius: 3, padding: '5px 10px' }}
                                      inputStyle={{ height: 28 }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Tooltip title="Eliminar venta" placement="top">
                                      <Fab aria-label="Eliminar venta" size='small' onClick={ () => this.handleDeleteProductId(index) } style={{margin: 5}}>
                                          <IoIosTrash size={25} style={{color: color.red}} />
                                      </Fab>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        }
                      </List>
                    </Grid>

                    {/* Empty sale detail */}
                    {
                      !this.state.productsAddList.length &&
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        style={{marginBottom: 20}}
                      >
                        {/* <div style={{marginBottom: 10}}>
                            <img src="/img/dashboard/cart-empty.png" style={{width: 200}}/>
                        </div> */}
                        <Typography color="textSecondary" variant='h5'>Lista de productos vacía <IoIosBasket size={30} /> </Typography>
                      </Grid>
                    }
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={ () => this.setState({ showDialogAddItem: false, productsAddList: [] }) }>
                    Cerrar
                  </Button>
                  <Button onClick={ this.handleSubmitAddProducts } style={{color: color.red}}>
                    Agregar
                  </Button>
                </DialogActions>
              </Dialog>
              
              {/* Close sale dialog */}
              <Dialog
                open={ this.state.showDialogCloseSale }
                TransitionComponent={ transition }
                onClose={ () => this.setState({ showDialogCloseSale: false }) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth='md'
              >
                  <DialogTitle id="alert-dialog-title">Detalles de venta</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TableContainer>
                          <Table size="small" aria-label="Detalles de venta">
                              <TableHead>
                                  <TableRow>
                                      <TableCell>Producto</TableCell>
                                      <TableCell align="right">Monto</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                              {this.state.saleDetails.map(row => {
                                return (
                                  <TableRow key={`preview-${row.id}`}>
                                      <TableCell component="th" scope="row" style={{paddingTop: 3, paddingBottom: 3}}>
                                          <Card elevation={0} style={{display: 'flex'}}>
                                              <CardMedia
                                                  component="img"
                                                  alt={ row.name }
                                                  height={40}
                                                  image={row.product.image ? `${API}/storage/${row.product.image.replace('.', '-small.')}` : defaultImg}
                                                  title={ row.name }
                                                  style={{ width: 60 }}
                                              />
                                              <div>
                                                  <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                      <Typography noWrap={true} variant="body1">{ row.quantity } { row.product.name }</Typography>
                                                      <Typography noWrap={true} variant="subtitle2" color="textSecondary">{ row.product.type ? row.type : row.product.short_description }</Typography>
                                                  </CardContent>
                                              </div>
                                          </Card>
                                      </TableCell>
                                      <TableCell align="right"><Typography noWrap={true} variant="body1">{ (row.quantity * row.product.price).toFixed(2) } <small>Bs.</small></Typography></TableCell>
                                  </TableRow>
                              )})}
                              </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="input-ci_nit"
                              label="NIT"
                              size="small"
                              name="ci_nit"
                              value={ this.state.inputCI }
                              // onChange={ event => this.setState({inputCI: event.target.value}) }
                              onChange={ event => {
                                this.setState({inputCI: event.target.value});
                                let customer = this.state.customersList.find(element => element.person.ci_nit == event.target.value);
                                this.setState({inputFirstName: customer ? customer.person.first_name : ''});
                            }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={12}>
                            <TextField
                              name="firstName"
                              variant="outlined"
                              required
                              fullWidth
                              id="input-firstName"
                              label="Nombre completo o razón social"
                              size="small"
                              value={ this.state.inputFirstName }
                              onChange={ event => this.setState({inputFirstName: event.target.value}) }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} style={{textAlign: 'right', padding: 10}}>
                            <TextField
                              id="input-amount" 
                              label="Monto recibido"
                              fullWidth
                              // onClick={ e => e.target.select() }
                              inputProps={{ type: 'number', min: '0', step: '0.5' }}
                              value={ this.state.inputReceivedAmount }
                              onChange={ e => this.setState({inputReceivedAmount: e.target.value}) }
                            />
                          </Grid>
                          <Grid item xs={12} md={6} style={{textAlign: 'right', padding: 10}}>
                            <TextField
                              id="input-discount" 
                              label="Descuento"
                              fullWidth
                              // onClick={ e => e.target.select() }
                              inputProps={{ type: 'number', min: '0', step: '0.5' }}
                              value={ this.state.inputDiscountAmount }
                              onChange={ e => this.setState({inputDiscountAmount: e.target.value}) }
                            />
                          </Grid>
                          {/* Alert refund amount */}
                          <Grid item xs={12} style={{paddingTop: 0, paddingBottom: 10}}>
                            {
                              this.state.inputReceivedAmount == '' &&
                              <Alert severity="info">Ingresa el monto recibido por el cliente</Alert>
                            }
                            {
                              this.state.inputReceivedAmount != '' && this.state.inputReceivedAmount == (this.state.inputSaleAmount - this.state.inputDiscountAmount) &&
                              <Alert severity="success">Monto recibido exacto!</Alert>
                            }
                            {
                              this.state.inputReceivedAmount != '' && this.state.inputReceivedAmount != (this.state.inputSaleAmount - this.state.inputDiscountAmount) &&
                              <Alert severity={this.state.inputReceivedAmount > (this.state.inputSaleAmount - this.state.inputDiscountAmount) ? 'info' : 'error'}>
                                {
                                  (this.state.inputReceivedAmount > (this.state.inputSaleAmount - this.state.inputDiscountAmount)) ?
                                  <>Debes dar un cambio de <b>{this.state.inputReceivedAmount - (this.state.inputSaleAmount - this.state.inputDiscountAmount)}</b> Bs.</> :
                                  'Monto inferior al importe de la venta'
                                }
                              </Alert>
                            }
                          </Grid>
                          <Grid item xs={12} md={12} style={{textAlign: 'right', padding: 10}}>
                              <Typography variant='h4'>{ (this.state.inputSaleAmount - this.state.inputDiscountAmount).toFixed(2) } <small style={{ fontSize: 15, padding: 0 }}>Bs.</small> </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={ () => this.setState({ showDialogCloseSale: false, inputReceivedAmount: 0, inputDiscountAmount: 0 }) }>
                      Cancelar
                    </Button>
                    <Button onClick={ this.handleSaleClose } style={{color: color.primary}}>
                      Cerrar venta
                    </Button>
                  </DialogActions>
              </Dialog>
              
              {/* Delete dialog */}
              <Dialog
                open={ this.state.showDialogDelete }
                TransitionComponent={ transition }
                onClose={ () => this.setState({ showDialogDelete: false }) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                  <DialogTitle id="alert-dialog-title">Anular venta</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ésta acción eliminará de forma permanente el registro y no podrás usarlo en el futuro.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={ () => this.setState({ showDialogDelete: false }) }>
                      Cancelar
                    </Button>
                    <Button onClick={ this.handleDelete } style={{color: color.red}}>
                      Eliminar
                    </Button>
                  </DialogActions>
              </Dialog>
          </div>
      );
  }
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <IoIosArrowDropupCircle /> : <IoIosArrowDropdownCircle />}
          </IconButton>
        </TableCell>
        {tableColumns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow style={{ backgroundColor: color.secondary }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} style={{marginBottom: 30}}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Detalle de la venta
              </Typography> */}
              <Table size="small" aria-label="purchases" style={{marginBottom: 10}}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{color: 'white'}}><b><small>Producto</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Cantidad</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Precio</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Subtotal</small></b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    row.details.map(item => {
                      let { image, name, type } = item.product;
                      return(
                        <TableRow>
                          <TableCell scope="row" style={{color: 'white'}}>
                            <Grid container spacing={2}>
                              <Grid item>
                                <Avatar src={ image ? `${API}/storage/${image.replace('.', '-cropped.')}` : defaultImg} style={{width: 40, height: 40}} />
                              </Grid>
                              <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={1}>
                                  <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1" style={{margin: 0}}>
                                      { name }
                                    </Typography>
                                    <Typography variant="body2">
                                      { type }
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell scope="row" style={{color: 'white'}}>{ item.quantity }</TableCell>
                          <TableCell scope="row" style={{color: 'white'}}>{ item.price }</TableCell>
                          <TableCell scope="row" style={{color: 'white'}}><b>{ (item.quantity * item.price).toFixed(2) }</b></TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
              <div style={{color: 'white'}}>
                <b>Observaciones</b> : <span>{ row.observations ? row.observations : 'Ninguna' }</span>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
        globalConfig: state.globalConfig
    }
}

export default connect(mapStateToProps)(withSnackbar(salesList));