import React, { Component } from 'react';
import {
  Grid,
  Button,
  IconButton,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Slide,
  Typography,
  Collapse,
  Box,
} from '@material-ui/core';

import {
  IoIosMenu,
  IoIosAddCircle,
  IoIosTrash,
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import axios from "axios";
import { withSnackbar } from 'notistack';
import moment from 'moment';
import 'moment/locale/es';

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { EmptyList, LoadingList } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API, color } = env;
const defaultImg = `${API}/images/default-image.png`;

const transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const tableColumns = [
    { id: 'id', label: 'ID' },
    { id: 'user', label: 'Registrado por' },
    { id: 'created_at', label: 'Fecha' },
    { id: 'total', label: 'Total' },
    // { id: 'discount', label: 'Descuento' },
    // { id: 'status', label: 'Estado' },
    { id: 'actions', label: 'Opciones', align: 'right', align: 'right' },
];

class PurchasesList extends Component {
  constructor(props){
    super(props)
    this.state = {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Authorization': `Bearer ${this.props.authSession.token}`
      },
      permission: null,
      loadingList: false,
      branchId: this.props.authSession.branch.id,
      defaultImg: `${API}/images/default-image.png`,
      tableRows: [],
      sidebarToggled: false,
      page: 0,
      purchaseId: null,
      purchaseList: [],
      rowsPerPage: 10,
      deleteId: 0,
      // Dialogs
      showDialogDelete: false,
    }
  }

  componentDidMount(){
    // Obtener el rol del usuario
    let { roles } = this.props.authSession.user;
    let role = roles.length > 0 ? roles[0] : {};
    this.setState({permission: [2, 3].find(element => element === role.id)});

    document.title = "Mis compras/gastos | " + env.appName;
    this.getPurchases();
  }

  createData(data) {
    let tableOptions = (
      <>
        <Tooltip title="Eliminar venta" placement="top">
            <Fab
              aria-label="Eliminar venta"
              size='small'
              // onClick={ () => this.setState({ showDialogDelete: true, deleteId: data.id }) }
              onClick={ () => this.props.enqueueSnackbar('Opción en etapa de desarrollo!', { variant: 'info' }) }
              style={{margin: 5}}
            >
              <IoIosTrash size={25} style={{color: color.red}} />
            </Fab>
        </Tooltip>
      </>
    );
    let created_at = <><span>{ moment(data.created_at).format('DD [de] MMMM YYYY, h:mm:ss a') }</span><br/><Typography variant="body2" color="textSecondary">{ moment(data.created_at).fromNow() }</Typography></>;
    return { id: data.id, user: data.user.name, total: data.total, created_at, details: data.details, actions: tableOptions };
  }

  getPurchases(){
    this.setState({loadingList: true});
    let { company } = this.props.authSession;
    fetch(`${API}/api/company/${company.id}/purchases/list`, {headers: this.state.headers})
    .then(res => res.json())
    .then(res => {
      this.renderRowTable(res.purchases);
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => this.setState({loadingList: false}));
  }

  renderRowTable(purchases){
    let rows = [];
    if(purchases){
      this.setState({purchaseList: purchases});
      purchases.map(purchase => {
          rows.push(this.createData(purchase));
      });
    }
    this.setState({tableRows: rows});
  }

  handleDelete = () => {
    this.setState({loadingList: true, showDialogDelete: false});
    let options = {
        headers: this.state.headers
    }
    axios.get(`${API}/api/sales/${this.state.deleteId}/delete`, options)
    .then(res => {
        if(res.data.sale_id){
          this.getPurchases();
          this.props.enqueueSnackbar('Venta anulada correctamente!', { variant: 'success' });
        }else{
          this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
          console.log(res.data.message);
        }
    })
    .catch(error => {
      console.log(error);
    });
  }

  render() {
    // Si no tiene permiso redirecciona al dashboard
    if(this.state.permission === undefined){
      return <Redirect to="/dashboard" />
    }

      return (
          <div className='app'>
              <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
              <main style={{padding: 10}}>
                  <Navbar
                    title={
                        <Grid
                            container
                            direction="row"
                            justify="flex-start"
                            alignItems="flex-start"
                        >
                            <Grid item>
                                <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                            </Grid>
                            <Grid item>
                                <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                  Mis compras/gastos
                                </h1>
                            </Grid>
                        </Grid>
                    }
                  />

                  <AlertSubscription />

                  <Grid style={{marginTop: 20}}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Link to='/dashboard/purchases/create'>
                              <Button variant="contained" style={{backgroundColor: color.primary, color: 'white'}} endIcon={<IoIosAddCircle/>} > Crear nueva</Button>
                          </Link>
                      </div>

                      <div style={{ marginTop: 20, marginBottom: 50 }}>
                        <Paper >
                          { this.state.loadingList && <LoadingList /> }
                          { this.state.tableRows.length === 0 && !this.state.loadingList && <EmptyList /> }
                          { this.state.tableRows.length > 0 &&
                            <>
                              <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={{backgroundColor: color.primary}}/>
                                      {tableColumns.map((column) => (
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          style={{ minWidth: column.minWidth, backgroundColor: color.primary, color: 'white', fontWeight: 500 }}
                                        >
                                          {column.label}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.tableRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                      return (
                                        <Row key={row.id} row={row} />
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <TablePagination
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                component="div"
                                count={this.state.tableRows.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                labelRowsPerPage='Items por página'
                                onChangePage={(event, newPage) => this.setState({page: newPage})}
                                onChangeRowsPerPage={(event) => this.setState({rowsPerPage: +event.target.value, page: 0})}
                              />
                            </>
                          }
                        </Paper>
                      </div>
                  </Grid>
              </main>
              
              
              {/* Delete dialog */}
              <Dialog
                open={ this.state.showDialogDelete }
                TransitionComponent={ transition }
                onClose={ () => this.setState({ showDialogDelete: false }) }
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                  <DialogTitle id="alert-dialog-title">Anular venta</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Ésta acción eliminará de forma permanente el registro y no podrás usarlo en el futuro.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={ () => this.setState({ showDialogDelete: false }) }>
                      Cancelar
                    </Button>
                    <Button onClick={ this.handleDelete } style={{color: color.red}}>
                      Eliminar
                    </Button>
                  </DialogActions>
              </Dialog>
          </div>
      );
  }
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <IoIosArrowDropupCircle /> : <IoIosArrowDropdownCircle />}
          </IconButton>
        </TableCell>
        {tableColumns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format && typeof value === 'number' ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow style={{ backgroundColor: color.secondary }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} style={{marginBottom: 30}}>
              <Table size="small" aria-label="purchases" style={{marginBottom: 10}}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{color: 'white'}}><b><small>Detalle</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Cantidad</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Precio</small></b></TableCell>
                    <TableCell style={{color: 'white'}}><b><small>Subtotal</small></b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    row.details.map(item => {
                      return(
                        <TableRow>
                          <TableCell scope="row" style={{color: 'white'}}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={1}>
                                  <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1" style={{margin: 0}}>
                                    { item.product ? item.product.name : item.details }
                                    </Typography>
                                    <Typography variant="body2" >
                                    { item.product ? item.product.type : '' }
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                          <TableCell scope="row" style={{color: 'white'}}>{ item.quantity }</TableCell>
                          <TableCell scope="row" style={{color: 'white'}}>{ item.price }</TableCell>
                          <TableCell scope="row" style={{color: 'white'}}><b>{ (item.quantity * item.price).toFixed(2) }</b></TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
              <div style={{color: 'white'}}>
                <b>Observaciones</b> : <span>{ row.observations ? row.observations : 'Ninguna' }</span>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
        globalConfig: state.globalConfig
    }
}

export default connect(mapStateToProps)(withSnackbar(PurchasesList));