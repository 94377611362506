import React, { Component } from 'react';
import {
    Grid,
    Paper,
    Collapse,
    Backdrop,
    CircularProgress,
    Tooltip,
    Button,
    IconButton,
    Fab,
    Chip,
    Typography,
    Switch,
    Avatar,
    Box,
    // Tablas
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    // Modal
    Slide,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';

import { IoIosMenu, IoIosAddCircle, IoIosTrash, IoIosCreate, IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosLock, IoIosJournal, IoMdPrint } from "react-icons/io";

import { connect } from 'react-redux';
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import { withSnackbar } from 'notistack';

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { EmptyList, LoadingList } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

import moment from 'moment';
import 'moment/locale/es';

const { API, URL, color } = env;
const defaultImg = `${API}/images/default-image.png`;

const transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const tableColumns = [
    { id: 'id', label: 'ID' },
    { id: 'banner', label: 'Banner'},
    { id: 'title', label: 'Nombre' },
    { id: 'type', label: 'Detalles' },
    { id: 'status', label: 'Estado' },
    { id: 'actions', label: 'Opciones', align: 'right' },
];

const DAYS = ['', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']

class offertsList extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            loadingList: false,
            sidebarToggled: false,
            loading: false,
            showDialogDelete: false,
            deleteId: 0,
            offerts: [],
            // Table
            tableRows: [],
            page: 0,
            rowsPerPage: 10,
        }
    }

    componentDidMount(){
        // Obtener el rol del usuario
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3, 4].find(element => element === role.id)});

        this.getData();

        document.title = "Mis ofertas | " + env.appName;
    }

    getData(){
        this.setState({loadingList: true});
        let { company, user } = this.props.authSession;      
        fetch(`${API}/api/company/${company.id}/offerts/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            this.renderRowsTable(res.offerts);
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => this.setState({loadingList: false}));
    }

    renderRowsTable(offerts){
        if(offerts){
            let rows = [];
            offerts.map(offert => {
                let { id, title, banner, type, start, finish, number_day, observations, status, details, created_at } = offert;
                rows.push(this.createData(id, title, banner, type, start, finish, number_day, observations, status, details, created_at));
            });
            this.setState({tableRows: rows, offerts});
        }
    }

    createData(id, title, banner, type, start, finish, number_day, observations, status, details, created_at) {
        let lableTitle = <>
                            { title } <br />
                            <Typography variant='caption'>{ observations }</Typography>
                        </>
        let bannerImnage = <Avatar alt="title" src={banner ? `${API}/storage/${banner.replace('.', '-cropped.')}` : defaultImg} />;
        let switchStatus = (
            <Switch
                checked={status == 1 ? true : false}
                onChange={ this.handleChangeStatus }
                color="primary"
                id={ id }
                inputProps={{ 'aria-label': 'Estado del producto' }}
            />
        );
        let period = null;
        switch (type) {
            case 'diario':
                period = start == finish ? 'El '+moment(start).format('DD [de] MMMM') : 'Desde '+moment(start).format('DD [de] MMMM')+' hasta '+moment(finish).format('DD [de] MMMM [de] YYYY');
                break;
            case 'semanal':
                period = 'Cada ' + DAYS[number_day];
                break;
            case 'mensual':
                period = 'El ' + number_day + ' de cada mes';
                break;
        
            default:
                period = "No definido";
                break;
        }
        let tableOptions = (
            <>
                <Link to={`/dashboard/offerts/${id}/edit`} style={{margin: 5}}>
                    <Tooltip title="Editar oferta" placement="top">
                        <Fab aria-label="Editar oferta" size='small'>
                            <IoIosCreate size={25} style={{color: color.skyBlue}} />
                        </Fab>
                    </Tooltip>
                </Link>
                <Tooltip title="Eliminar oferta" placement="top">
                    <Fab aria-label="Eliminar oferta" size='small' onClick={ () => this.setState({ showDialogDelete: true, deleteId: id }) } style={{margin: 5}}>
                        <IoIosTrash size={25} style={{color: color.red}} />
                    </Fab>
                </Tooltip>
            </>
        )
        return { id, title: lableTitle, banner: bannerImnage, type: period, start, finish, number_day, observations, status: switchStatus, details, created_at, actions: tableOptions };
    }

    handleChangeStatus = (event) => {
        this.setState({loading: true});
        let { checked, id } = event.target;
        fetch(`${API}/api/offerts/${id}/update/${checked ? 1 : 0}`, {headers: this.state.headers})
            .then(res => res.json())
            .then(res => {
                if(res.success){
                    let { offerts } = this.state;
                    offerts.map((item, index) => {
                    if(item.id == id){
                        offerts[index] = {
                        ...item,
                        status: checked ? 1 : 0
                        }
                    }
                    });
                    this.renderRowsTable(offerts)
                    this.props.enqueueSnackbar(res.success, { variant: 'success' });
                    this.props.setProductsTPV([]);
                }else{
                    this.props.enqueueSnackbar(res.error, { variant: 'error' });
                    console.log(res.message);
                }
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => this.setState({loading: false}));
    };

    handleDelete = () => {
        let options = {
          headers: this.state.headers
        }
        axios.get(`${API}/api/offerts/${this.state.deleteId}/delete`, options)
        .then(res => {
          if(res.data.offert_id){
            let { offert_id } = res.data;

            // Update products list
            let { offerts } = this.state;
            let rows = []
            offerts.map((item) => {
              if(item.id != offert_id){
                rows.push(item);
              }
            });
            this.renderRowsTable(rows)
            this.props.enqueueSnackbar('Oferta eliminado correctamente!', { variant: 'success' });
            this.props.setProductsTPV([]);
          }else{
            this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
            console.log(res.data.message);
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally( () => {
            this.setState({showDialogDelete: false});
        });
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }

        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            Mis ofertas
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <AlertSubscription />

                        <Grid style={{marginTop: 20}}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Link to='/dashboard/offerts/create'>
                                    <Tooltip title="Crear nueva oferta" placement="top">
                                        <Button variant="contained" style={{backgroundColor: color.primary, color: 'white'}} endIcon={<IoIosAddCircle/>} className="add-step" > Crear nueva</Button>
                                    </Tooltip>
                                </Link>
                            </div>

                            <div style={{ marginTop: 20, marginBottom: 50 }}>
                                <Paper >
                                    { this.state.loadingList && <LoadingList /> }
                                    { this.state.tableRows.length === 0 && !this.state.loadingList &&
                                        <EmptyList
                                            subtitle="No has registrado ninguna oferta hasta el momento."
                                        />
                                    }
                                    { this.state.tableRows.length > 0 &&
                                        <>
                                            <TableContainer>
                                                <Table stickyHeader aria-label="sticky table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{backgroundColor: color.primary}} />
                                                            {tableColumns.map((column) => (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    style={{ minWidth: column.minWidth, backgroundColor: color.primary, color: 'white', fontWeight: 500 }}
                                                                >
                                                                    {column.label}
                                                                </TableCell>
                                                            ))}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {this.state.tableRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => <Row key={row.id} row={row} /> )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                component="div"
                                                count={this.state.tableRows.length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                labelRowsPerPage='Items por página'
                                                onChangePage={(event, newPage) => this.setState({page: newPage})}
                                                onChangeRowsPerPage={(event) => this.setState({rowsPerPage: +event.target.value, page: 0})}
                                            />
                                        </>
                                    }
                                </Paper>
                            </div>
                        </Grid>
                    </main>

                    {/* Delete dialog */}
                    <Dialog
                        open={ this.state.showDialogDelete }
                        TransitionComponent={ transition }
                        onClose={ () => this.setState({ showDialogDelete: false }) }
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            Ésta acción eliminará de forma permanente el registro y no podrás usarlo en el futuro.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={ () => this.setState({ showDialogDelete: false }) } >
                            Cancelar
                            </Button>
                            <Button onClick={ this.handleDelete } style={{color: color.red}}>
                            Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </>
        );
    }
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow hover role="checkbox" tabIndex={-1}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <IoIosArrowDropupCircle /> : <IoIosArrowDropdownCircle />}
                    </IconButton>
                </TableCell>
                {tableColumns.map((column) => {
                    const value = row[column.id];
                    return (
                        <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                    );
                })}
            </TableRow>
            <TableRow style={{ backgroundColor: color.secondary }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1} style={{marginBottom: 30}}>
                            <Table size="small" aria-label="purchases" style={{marginBottom: 10}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{color: 'white'}}><b><small>Producto</small></b></TableCell>
                                        <TableCell style={{color: 'white'}}><b><small>Precio</small></b></TableCell>
                                        <TableCell style={{color: 'white'}}><b><small>Descuento</small></b></TableCell>
                                        <TableCell style={{color: 'white'}}><b><small>Precio final</small></b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    row.details.map(item => {
                                        let { image, name, price, type } = item.product;
                                        return(
                                            <TableRow>
                                                <TableCell scope="row" style={{color: 'white'}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item>
                                                        <Avatar src={ image ? `${API}/storage/${image.replace('.', '-cropped.')}` : defaultImg} style={{width: 40, height: 40}} />
                                                        </Grid>
                                                        <Grid item xs={12} sm container>
                                                        <Grid item xs container direction="column" spacing={1}>
                                                            <Grid item xs>
                                                            <Typography gutterBottom variant="subtitle1" style={{margin: 0}}>
                                                                { name }
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                { type }
                                                            </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell scope="row" style={{color: 'white'}}>{ price }</TableCell>
                                                <TableCell scope="row" style={{color: 'white'}}>
                                                    { item.discount_type == 'monto' ? parseFloat(item.discount_amount).toFixed(2) + ' Bs.' : item.discount_amount + '%' }
                                                </TableCell>
                                                <TableCell scope="row" style={{color: 'white'}}>{ Number.parseFloat(price - (item.discount_type == 'porcentaje' ? price * (item.discount_amount /100) : item.discount_amount)).toFixed(2) } Bs.</TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setProductsTPV : (productsTPV) => dispatch({
            type: 'SET_PRODUCTS_TPV',
            payload: productsTPV
        })
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(offertsList));