import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Paper,
    TextField,
    Backdrop,
    CircularProgress,
    Typography,
    Box,
    AppBar,
    Tabs,
    Tab,
    Avatar,
    Button,
    Fab,
    Tooltip,
    // Tables
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
}from '@material-ui/core';
import {
    Autocomplete,
} from '@material-ui/lab';
import { IoIosMenu, IoIosAddCircleOutline, IoIosBasket, IoIosTrash } from "react-icons/io";
import { Redirect } from "react-router-dom";
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import axios from "axios";

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { FormButtons } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API, color } = env;
const defaultImg = `${API}/images/default-image.png`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
  
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

class PurchasesCreateEdit extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            sidebarToggled: false,
            loading: false,
            redirect: false,
            id: this.props.match.params.id,
            inputAmount: '',
            inputDetails: '',
            inputQuantity: '',
            inputTotalPrice: '',
            tabActive: 0,
            productsList: [],
            productsDetailsList: [],
            productSelected: null
        }
    }

    componentDidMount(){
        // Obtener el rol del usuario
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3].find(element => element === role.id)});

        document.title = `${this.state.id ? 'Editar' : 'Crear '} compra/gasto | ${env.appName}`;
        this.getProducts();
    }

    getProducts(){
        let { company } = this.props.authSession;
        fetch(`${API}/api/company/${company.id}/products/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            this.setState({productsList: res.products});
        })
        .catch(error => {
            console.log(error);
        });
    }

    handleSelectProduct = (event, value, reason) => {
        if(value){
            this.setState({productSelected: value});
        }
    }

    handleAddItem = () => {
        let { productsDetailsList } = this.state;
        let item = {
            index: productsDetailsList.length,
            id: this.state.tabActive == 0 ? this.state.productSelected.id : null,
            details: this.state.tabActive == 0 ? `${this.state.productSelected.name} ${this.state.productSelected.type}` : this.state.inputDetails,
            quantity: this.state.tabActive == 0 ? parseFloat(this.state.inputQuantity) : 1,
            price: this.state.tabActive == 0 ? parseFloat(this.state.inputTotalPrice/this.state.inputQuantity).toFixed(2) : parseFloat(this.state.inputAmount)
        }

        let findProduct = this.state.tabActive == 0 ? productsDetailsList.find(element => element.id == item.id) : null;
        if(!findProduct){
            productsDetailsList.push(item);
            this.setState({
                productsDetailsList,
                inputAmount: '',
                inputDetails: '',
                inputQuantity: '',
                inputTotalPrice: ''
            });
        }
    }

    handleDeleteItem(index){
        let { productsDetailsList } = this.state;
        productsDetailsList.splice(index, 1);
        this.setState({productsDetailsList});
    }

    handleSubmit = (event) => {
        let { productsDetailsList } = this.state;
        if(productsDetailsList.length == 0){
            this.props.enqueueSnackbar('Debes agregar un item al detalle de compras', { variant: 'warning' });
            return;
        }

        this.setState({loading: true});
        let { company, user } = this.props.authSession;

        let total = productsDetailsList.reduce((total, item) => total + (item.price*item.quantity), 0);

        let data = {
            supplier_id: null,
            user_id: user.id,
            payment_type: 'efectivo',
            total,
            discount: 0,
            paid_out: 1,
            status: 1,
            details: productsDetailsList,
            observations: '',
        };

        // Change URL for update or create
        let url = `${API}/api/company/${company.id}/purchases/create`;

        axios({
            method: 'post',
            url,
            data: JSON.stringify(data),
            headers: this.state.headers
        })
        .then(res => {
            if(res.data.success){
                this.setState({productsDetailsList: []});
                this.props.enqueueSnackbar(res.data.success, { variant: 'success' });
            }else{
                this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
                console.log(res.data.message);
            }
        })
        .catch((err) => this.props.enqueueSnackbar('Ocurrió un error en nuestro servidor!', { variant: 'error' }))
        .then(() => this.setState({loading: false}));
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }

        if (this.state.redirect) {
           return <Redirect to='/dashboard/productscategories'/>;
        }

        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            { this.state.id ? 'Editar' : 'Crear' } compra/gasto
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <AlertSubscription />

                        <Paper style={{ marginTop: 50, backgroundColor: 'white' }}>
                            {/* <form onSubmit={ this.handleSubmit } > */}
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={4}>
                                        <AppBar position="static" style={{backgroundColor: color.secondary, color: 'white', margin: 0}}>
                                            <Tabs 
                                                value={this.state.tabActive}
                                                onChange={(event, newValue) => {
                                                    this.setState({tabActive: newValue});
                                                }}
                                                aria-label="tab-type"
                                            >
                                                <Tab label="Productos" {...a11yProps(0)} style={{ fontSize: 11 }}/>
                                                <Tab label="Otros" {...a11yProps(1)} style={{ fontSize: 11 }}/>
                                            </Tabs>
                                        </AppBar>
                                        <TabPanel value={this.state.tabActive} index={0}>
                                            <div>
                                                <Autocomplete
                                                    id="combo-products"
                                                    fullWidth
                                                    options={ this.state.productsList }
                                                    getOptionLabel={(option) => `${option.name}`}
                                                    renderInput={(params) => <TextField {...params} label="Nombre del producto" variant="outlined" />}
                                                    renderOption={(option, { selected }) => (
                                                        <React.Fragment>
                                                            <Grid container>
                                                                <Grid item style={{marginRight: 10}}>
                                                                    <Avatar src={option.image ? `${API}/storage/${option.image.replace('.', '-cropped.')}` : defaultImg} />
                                                                </Grid>
                                                                <Grid item>
                                                                    <div>
                                                                        <b>{ option.name }</b>
                                                                        <br/>
                                                                        <small>{ option.type }</small>
                                                                    </div>
                                                                </Grid>
                                                            </Grid>
                                                        </React.Fragment>
                                                    )}
                                                    // inputValue={false}
                                                    noOptionsText='No hay resultados'
                                                    style={{ marginBottom: 15 }}
                                                    size="small"
                                                    onChange={ this.handleSelectProduct }
                                                />
                                                <TextField
                                                    name="quantity"
                                                    variant="outlined"
                                                    fullWidth
                                                    id="input-quantity"
                                                    label="Cantidad"
                                                    placeholder='10'
                                                    // helperText="Cantidad del producto"
                                                    inputProps={{ type: 'number', min: '0', step: '1' }}
                                                    value={ this.state.inputQuantity }
                                                    onChange={ event => this.setState({inputQuantity: event.target.value}) }
                                                    style={{ marginBottom: 15 }}
                                                    size="small"
                                                />
                                                <TextField
                                                    name="totalPrice"
                                                    variant="outlined"
                                                    fullWidth
                                                    id="input-totalPrice"
                                                    label="Costo total"
                                                    placeholder='10'
                                                    // helperText="Precio del producto"
                                                    inputProps={{ type: 'number', min: '0', step: '0.1' }}
                                                    value={ this.state.inputTotalPrice }
                                                    onChange={ event => this.setState({inputTotalPrice: event.target.value}) }
                                                    style={{ marginBottom: 15 }}
                                                    size="small"
                                                />
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={this.state.tabActive} index={1}>
                                            <div>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="input-description"
                                                    label="Detalle"
                                                    placeholder='Describa el detalle de su gasto'  
                                                    name="details"
                                                    helperText='Ej: "Compras de agosto"'
                                                    value={ this.state.inputDetails }
                                                    onChange={ event => this.setState({inputDetails: event.target.value}) }
                                                    style={{ marginBottom: 15 }}
                                                    multiline
                                                    rows={3}
                                                    size="small"
                                                />
                                                <TextField
                                                    name="amount"
                                                    variant="outlined"
                                                    fullWidth
                                                    id="input-amount"
                                                    label="Monto"
                                                    placeholder='100'
                                                    // helperText="Monto de la compra/gasto"
                                                    inputProps={{ type: 'number', min: '0', step: '0.1' }}
                                                    value={ this.state.inputAmount }
                                                    onChange={ event => this.setState({inputAmount: event.target.value}) }
                                                    style={{ marginBottom: 15 }}
                                                    size="small"
                                                />
                                            </div>
                                        </TabPanel>
                                        <Grid style={{padding: '0px 20px'}}>
                                            <Button
                                                // type="submit"
                                                fullWidth
                                                size="small"
                                                variant="contained"
                                                style={{ backgroundColor: color.secondary, color: 'white'}}
                                                endIcon={ <IoIosAddCircleOutline/> }
                                                onClick={ this.handleAddItem }
                                            >
                                                Agregar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={8} style={{paddingLeft: 20, paddingRight: 20,}}>
                                    {
                                        !this.state.productsDetailsList.length &&
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            alignItems="center"
                                            style={{marginTop: 100}}
                                        >
                                            {/* <div style={{marginBottom: 10}}>
                                                <img src="/img/dashboard/cart-empty.png" style={{width: 200}}/>
                                            </div> */}
                                            <Typography color="textSecondary" variant='h5'>Lista de compras/gastos vacía <IoIosBasket size={30} /> </Typography>
                                        </Grid>
                                    }

                                    {
                                        this.state.productsDetailsList.length > 0 &&
                                        <TableContainer component={Paper} style={{maxHeight: 400}}>
                                            <Table aria-label="simple table">
                                                <TableHead>
                                                    <TableRow style={{backgroundColor: color.primary}}>
                                                        <TableCell style={{color: 'white'}}>N&deg;</TableCell>
                                                        <TableCell style={{color: 'white'}}>Detalle</TableCell>
                                                        <TableCell align="right" style={{color: 'white'}}>Cantidad&nbsp;(Unid.)</TableCell>
                                                        <TableCell align="right" style={{color: 'white'}}>Precio Unid.&nbsp;(Bs.)</TableCell>
                                                        <TableCell align="right" style={{color: 'white'}}>Subtotal&nbsp;(Bs)</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {this.state.productsDetailsList.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{ index +1 }</TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.details}
                                                        </TableCell>
                                                        <TableCell align="right">{row.quantity}</TableCell>
                                                        <TableCell align="right">{row.price}</TableCell>
                                                        <TableCell align="right">{(row.price * row.quantity).toFixed(2)}</TableCell>
                                                        <TableCell align="right">
                                                            <Tooltip title="Eliminar item" placement="top">
                                                                <Fab aria-label="Eliminar item" size='small' onClick={ () => this.handleDeleteItem(index) } style={{margin: 5}}>
                                                                    <IoIosTrash size={25} style={{color: color.red}} />
                                                                </Fab>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                    </Grid>
                                </Grid>
                                <FormButtons back='/dashboard/purchases' onClickSave={ this.handleSubmit } titleSuccess={ this.state.id ? 'Actualizar' : 'Guardar' } />
                            {/* </form> */}
                        </Paper>    
                    </main>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

export default connect(mapStateToProps)(withSnackbar(PurchasesCreateEdit));