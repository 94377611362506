import React, { Component } from 'react';
import {
    Grid,
    Paper,
    TextField,
    Backdrop,
    CircularProgress,
    CardMedia,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox
}from '@material-ui/core';
import { IoIosMenu, IoIosCamera } from "react-icons/io";
import { Redirect } from "react-router-dom";
import { withSnackbar } from 'notistack';
import { connect } from 'react-redux';
import axios from "axios";

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { FormButtons } from "../../components/forms";
import AlertSubscription from "../../components/alertSubscription";
import { env } from '../../../env';

const { API } = env;

class ProductsCategoriesCreateEdit extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            sidebarToggled: false,
            loading: false,
            redirect: false,
            id: this.props.match.params.id,
            inputName: '',
            inputDescription: '',
            image: `${API}/images/default-image.png`,
            fileImage: null,
            imageCategory: `${API}/images/default-image.png`,
            fileImageCategory: null,
            resetForm: this.props.match.params.id ? false : true
        }
    }

    componentDidMount(){
        // Obtener el rol del usuario
        let { company } = this.props.authSession;
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3].find(element => element === role.id)});

        document.title = `${this.state.id ? 'Editar' : 'Crear '} categoría de producto | ${env.appName}`;
        // If edit get data product
        if(this.state.id){
            this.setState({loading: true});
            fetch(`${API}/api/productscategories/${this.state.id}`, {headers: this.state.headers})
            .then(res => res.json())
            .then(res => {
                let { product_category } = res;
                console.log(product_category)
                if(product_category.company_id !== company.id) {
                    this.setState({permission: undefined});
                    return;
                }
                this.setState({
                    inputName: product_category.name,
                    inputDescription: product_category.description ? product_category.description : '',
                    image: product_category.image ? `${API}/storage/${product_category.image.replace('.', '-medium.')}`: `${API}/images/default-image.png`
                });
            })
            .catch(error => {
                console.log(error);
            })
            .then(() => this.setState({loading: false}));
        }
    }

    hanldeImage = e => {
        try {
            this.setState({image: URL.createObjectURL(e.target.files[0]), fileImage: e.target.files[0]})
        } catch (error) {console.log(error)}
    }

    hanldeImageCategory = e => {
        try {
            this.setState({imageCategory: URL.createObjectURL(e.target.files[0]), fileImageCategory: e.target.files[0]})
        } catch (error) {console.log(error)}
    }

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({loading: true});
        let { company } = this.props.authSession;
        let { id, inputName, inputDescription, fileImage } = this.state;

        let formData = new FormData();
        formData.append("name", inputName);
        formData.append("description", inputDescription);
        formData.append('image', fileImage);

        // Change URL for update or create
        let url = id ? `${API}/api/productscategories/${id}/update` : `${API}/api/company/${company.id}/productscategories/create`;

        axios({
            method: 'post',
            url,
            data: formData,
            headers: this.state.headers
        })
        .then(res => {
            if(res.data.products_categories){
                this.props.enqueueSnackbar(`Categoría de producto ${this.state.id ? 'editado' : 'registrado'} correctamente!`, { variant: 'success' });
                
                if(this.state.id){
                    this.setState({redirect: true});
                    return 0;
                }

                if(this.state.resetForm){
                    this.setState({
                        inputName: '',
                        inputDescription: '',
                        image: `${API}/images/default-image.png`,
                        fileImage: null,
                    });
                }
            }else{
                this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
                console.log(res.data.message);
            }
        })
        .catch((err) => this.props.enqueueSnackbar('Ocurrió un error en nuestro servidor!', { variant: 'error' }))
        .then(() => this.setState({loading: false}));
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }

        if (this.state.redirect) {
           return <Redirect to='/dashboard/productscategories'/>;
        }

        return (
            <>
                { this.state.loading &&
                    <Backdrop open={true} style={{ zIndex: 20 }}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            { this.state.id ? 'Editar' : 'Crear' } categoría de producto
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <AlertSubscription />

                        <Paper style={{ marginTop:20, backgroundColor: 'white', padding: 30 }}>
                            <form onSubmit={ this.handleSubmit } >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="name"
                                                variant="outlined"
                                                required
                                                fullWidth
                                                id="input-name"
                                                label="Nombre"
                                                placeholder='Hamburguesa'
                                                helperText="Nombre descriptivo del producto"
                                                value={ this.state.inputName }
                                                onChange={ event => this.setState({inputName: event.target.value}) }
                                                style={{ marginBottom: 15 }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="input-description"
                                                label="Descripción"
                                                placeholder='Descripción corta de su producto'
                                                name="description"
                                                helperText="Ej: Pan, carne, ensalada, huevo, salsas, etc."
                                                value={ this.state.inputDescription }
                                                onChange={ event => this.setState({inputDescription: event.target.value}) }
                                                style={{ marginBottom: 15 }}
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: 20 }}>
                                            {
                                                !this.state.id &&
                                                <FormControlLabel
                                                    control={<Checkbox onChange={(e) => this.setState({resetForm: e.target.checked})} checked={ this.state.resetForm } color="primary" />}
                                                    label="Limpiar campos"
                                                    style={{ marginBottom: 20 }}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} style={{paddingLeft: 20, paddingRight: 20,}}>
                                        <input type="file" style={{ display: 'none' }} id="input-image" name="image" accept="image/*" onChange={ this.hanldeImage }/>
                                        <CardMedia
                                            style={{ width: '100%', height: 270, position: 'relative', zIndex: 1, border: '3px solid #E2E3E3', marginBottom: 10 }}
                                            image={ this.state.image }
                                            title="Imagen del producto"
                                        />
                                        <div style={{ display: 'flex', justifyContent:'center' }}>
                                            <label htmlFor="input-image">
                                                <Tooltip title={`Click para ${ this.state.id ? 'editar' : 'agregar' } imagen del producto`} placement="top">
                                                    <IconButton aria-label={`Click para ${ this.state.id ? 'editar' : 'agregar' } imagen del producto`} component="span">
                                                        <IoIosCamera size={30} color="gray" />
                                                    </IconButton>
                                                </Tooltip>
                                            </label>
                                        </div>
                                    </Grid>
                                </Grid>
                                <FormButtons back='/dashboard/productscategories' titleSuccess={ this.state.id ? 'Actualizar' : 'Guardar' } />
                            </form>
                        </Paper>    
                    </main>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

export default connect(mapStateToProps)(withSnackbar(ProductsCategoriesCreateEdit));