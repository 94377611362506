import React, {useState, useEffect} from 'react'
import {
    Grid,
    Button
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { IoMdInformationCircle } from "react-icons/io";

import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import moment from 'moment';
import { env } from '../../env';
import 'moment/locale/es';

const { API, color } = env;

const AlertSubscription = props => {
    const [diff, setDiff] = useState(0);
    const [suspended, setSuspended] = useState(false);
    useEffect(() => {
        let subscription = props.authSession.subscription;
        let today  = moment().format("YYYYMMDD");
        let end = moment(subscription.end, "YYYYMMDD");
        setDiff(end.diff(today, "days"));
        setSuspended(end.diff(today, "days") <= subscription.type.expiration_days * -1)
    }, []);
    
    if(!suspended){
        if(diff <= 0) {
            return(
                <Grid container style={{marginTop: 20}}>
                    <Grid item xs={12}>
                        <Alert variant="filled" severity={ diff > 0 ? "warning" : "error" }>
                            {/* { diff > 0 && `Tu suscripción expira ${diff == 1 ? 'mañana' : 'en '+diff+' días'}` } */}
                            { diff == 0 && `Tu suscripción expira hoy` }
                            { diff < 0 && `Tu suscripción expiró ${diff == -1 ? 'ayer' : 'hace '+(diff *-1)+' días'}` }
                            { ', por favor contáctanos para evitar la suspensión del servicio.' }
                            <Link to="dashboard/about">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    endIcon={<IoMdInformationCircle />}
                                    style={{ marginLeft: 20, backgroundColor: color.primary }}
                                >
                                    Mas información
                                </Button>
                            </Link>
                        </Alert>
                    </Grid>
                </Grid>
            );
        }else {
            return <></>
        }
    }else{
        return <Redirect to='/suspended' />
    }
}

const mapStateToProps = (state) => {
  return {
    globalConfig: state.globalConfig,
    authSession: state.authSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalConfig : (globalConfig) => dispatch({
        type: 'SET_GLOBAL_CONFIG',
        payload: globalConfig
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertSubscription);