import React, { useEffect, useState } from 'react';
import { ProSidebar, SidebarHeader, SidebarContent, SidebarFooter, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { IoIosHome, IoIosBriefcase, IoIosBulb, IoIosArrowDropright, IoIosArrowDropleft, IoMdCart, IoIosCash, IoIosPie, IoMdSettings, IoIosBasket } from "react-icons/io";
import { IconButton } from '@material-ui/core';
import "../style.scss";

import { connect } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Link } from "react-router-dom";

const Sidebar = props => {
    const [role, setRole] = useState({});
    useEffect(() => {
        const { roles } = props.authSession.user;
        setRole(roles.length > 0 ? roles[0] : {})
    }, []);

    const handleSidebar = async () => {
        let config = {
            ...props.globalConfig,
            sidebar: {
                ...props.globalConfig.sidebar,
                collapsed: !props.globalConfig.sidebar.collapsed
            }
        }
        await AsyncStorage.setItem('sessionGlobalConfig', JSON.stringify(config));
        props.setGlobalConfig(config);
    }

    return (
        <>
            <ProSidebar
                collapsed={ props.globalConfig.sidebar.collapsed }
                breakPoint='md'
                toggled={ props.toggled }
                onToggle={ props.onToggle }
                image='/img/sidebar.jpg'
                className={ props.className }
            >
                <SidebarHeader>
                    <div style={{ padding: 15,}}>
                        <b style={{ fontSize: 25, color: 'white' }}><img src="/favicon.ico" alt="gerente.rest_logo" style={{ width: 50, float: 'left', marginRight: 10 }}/>
                            { props.globalConfig.sidebar.collapsed ?  '' : <>GerenteRest<small style={{fontSize: 12}}>v1</small></> }
                        </b>
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu iconShape="square">
                        <MenuItem icon={ <IoIosHome size={25} /> } ><Link to="/dashboard">Inicio</Link></MenuItem>
                        <SubMenu icon={ <IoIosBriefcase size={25} /> } title="Administración" style={{display: role.id == 2 || role.id == 3 ? 'flex' : 'none'}}>
                            <MenuItem><Link to="/dashboard/mycompany">Mi restaurante</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/branches">Sucursales</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/products">Productos</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/offerts">Ofertas</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/employes">Empleados</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/productscategories">Categoría de Productos</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/purchases">Compras/Gastos</Link></MenuItem>
                        </SubMenu>
                        <SubMenu icon={ <IoMdCart size={25} /> } title="Ventas" style={{display: role.id == 2 || role.id == 3 || role.id == 4 ? 'flex' : 'none'}}>
                        <MenuItem><Link to="/dashboard/cashiers">Cajas</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/sales/create">Vender</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/sales">Ventas del día</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/kitchen">Cocina</Link></MenuItem>
                        </SubMenu>
                        <SubMenu icon={ <IoIosPie size={25} /> } title="Reportes" style={{display: role.id == 2 || role.id == 3 ? 'flex' : 'none'}}>
                            <MenuItem><Link to="/dashboard/reports/sales">Ventas</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/reports/cashiers">Cajas</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/reports/purchases">Compras/Gastos</Link></MenuItem>
                            {/* <MenuItem><Link to="/dashboard/reports/products">Inventario</Link></MenuItem> */}
                        </SubMenu>
                        <SubMenu icon={ <IoIosBulb size={25} /> } title="Complementos" style={{display: role.id == 2 || role.id == 3 || role.id == 4 ? 'flex' : 'none'}}>
                            <MenuItem><Link to="/dashboard/tickets" target="_blank">Tickets</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/publications">Publicidad</Link></MenuItem>
                            <MenuItem><Link to="/dashboard/qr">Generar QR</Link></MenuItem>
                        </SubMenu>
                        <MenuItem icon={ <IoMdSettings size={25} /> } ><Link to="/dashboard/settings">Configuración</Link></MenuItem>
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <div style={{ textAlign: 'center', }}>
                        <IconButton aria-label="delete" onClick={ handleSidebar } className={ props.classNameToggle } >
                            { props.globalConfig.sidebar.collapsed ? <IoIosArrowDropright size={40} color='white' /> : <IoIosArrowDropleft size={40} color='white' /> }
                        </IconButton>
                    </div>
                </SidebarFooter>
            </ProSidebar>
        </>
    );
}

const mapStateToProps = (state) => {
  return {
    globalConfig: state.globalConfig,
    authSession: state.authSession
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGlobalConfig : (globalConfig) => dispatch({
        type: 'SET_GLOBAL_CONFIG',
        payload: globalConfig
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);