import React, { Component } from 'react';
import {
    Grid,
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Avatar,
    TextField,
    Tooltip,
    // Dialog
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    // Cards
    Card,
    CardHeader,
    CardMedia,
    Fab
} from '@material-ui/core';

import { IoIosMenu, IoIosAddCircle, IoIosCamera, IoIosCreate, IoIosTrash } from "react-icons/io";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import axios from "axios";
import { withSnackbar } from 'notistack';

// Components
import Sidebar from "../../components/sidebar";
import Navbar from "../../components/navbar";
import { EmptyList, LoadingList } from "../../components/forms";
import { env } from '../../../env';

const { API, color } = env;
const defaultImg = `${API}/images/default-image.png`;

// Publication confirmation
const transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const tableColumns = [
    { id: 'id', label: 'ID' },
    { id: 'banner', label: 'Banner' },
    { id: 'title', label: 'Título' },
    { id: 'subtitle', label: 'Descripción' },
    { id: 'actions', label: 'Opciones', align: 'right' },
];

class PublicationsList extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            permission: null,
            showDialogCreateEdit: false,
            showDialogDelete: false,
            loadingList: false,
            company: this.props.authSession.company,
            publicationId: null,
            tableRows: [],
            sidebarToggled: false,
            page: 0,
            publicationsList: [],
            rowsPerPage: 10,
            // Form
            inputTitle: '',
            inputSubtitle: '',
            image: null,
            fileImage: null,
        }
    }

    componentDidMount(){
        // Obtener el rol del usuario
        let { roles } = this.props.authSession.user;
        let role = roles.length > 0 ? roles[0] : {};
        this.setState({permission: [2, 3, 4].find(element => element === role.id)});

        document.title = "Publicidades | " + env.appName;
        if(this.state.company){
            this.getPublications();
        }
    }

    createData(id, banner, title, subtitle) {
        let avatar = <Avatar src={banner ? `${API}/storage/${banner.replace('.', '-cropped.')}` : defaultImg} style={{width: 80, height: 80}} />
        let tableActions = (
            <>
                <Tooltip title="Editar publicidad" placement="top">
                    <Fab
                        aria-label="Editar publicidad"
                        size='small'
                        onClick={ () => this.setState({ showDialogCreateEdit: true, publicationId: id, inputTitle: title, inputSubtitle: subtitle, image: banner ? `${API}/storage/${banner.replace('.', '-medium.')}` : "/img/publication-default.jpg" })}
                        style={{margin: 5}}
                    >
                        <IoIosCreate size={25} style={{color: color.skyBlue}} />
                    </Fab>
                </Tooltip>
                <Tooltip title="Eliminar publicidad" placement="top">
                    <Fab aria-label="Eliminar publicidad" size='small' onClick={ () => this.setState({ showDialogDelete: true, publicationId: id }) } style={{margin: 5}}>
                        <IoIosTrash size={25} style={{color: color.red}} />
                    </Fab>
                </Tooltip>
            </>
        );
        return { id, banner: avatar, title: title.length <= 30 ? title : title.substring(0, 30)+'...', subtitle: subtitle.length <= 50 ? subtitle : subtitle.substring(0, 50)+'...', actions: tableActions };
    }

    getPublications(){
        this.setState({loadingList: true});
        fetch(`${API}/api/company/${this.state.company.id}/publications`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
            this.renderRowTable(res.publications);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => this.setState({loadingList: false}));
    }

    renderRowTable(publications){
        let rows = [];
        if(publications){
        this.setState({publicationsList: publications});
        publications.map(publication => {
            rows.push(this.createData(publication.id, publication.banner, publication.title, publication.subtitle));
        });
        }
        this.setState({tableRows: rows});
    }

    hanldeImage = e => {
        try {
            this.setState({image: URL.createObjectURL(e.target.files[0]), fileImage: e.target.files[0]})
        } catch (error) {console.log(error)}
    }

    handleSubmitCreate = event => {
        if(!this.state.inputTitle){
            this.props.enqueueSnackbar('Debes agregar un título!', { variant: 'warning' });
            return false;
        }
        if(!this.state.fileImage){
            this.props.enqueueSnackbar('Debes seleccionar una imagen!', { variant: 'warning' });
            return false;
        }

        this.setState({loadingList: true, showDialogCreateEdit: false});
        let { inputTitle, inputSubtitle, fileImage } = this.state;

        let formData = new FormData();
        formData.append("title", inputTitle);
        formData.append("subtitle", inputSubtitle);
        formData.append('image', fileImage);

        axios({
            method: 'post',
            url: `${API}/api/company/${this.state.company.id}/publications/store`,
            data: formData,
            headers: this.state.headers
        })
        .then(res => {
            if(res.data.success){
                this.props.enqueueSnackbar(res.data.success, { variant: 'success' });
                this.setState({
                    inputTitle: '',
                    inputSubtitle: '',
                    image: null,
                    fileImage: null,
                });
                this.getPublications();
            }else{
                this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
                this.setState({showDialogCreateEdit: true});
                console.log(res.data.message);
            }
        })
        .catch((err) => {
            this.props.enqueueSnackbar('Ocurrió un error en nuestro servidor!', { variant: 'error' });
            console.log(err)
        })
        .finally(() => this.setState({loadingList: false}));
    }

    handleSubmitEdit = event => {
        if(!this.state.inputTitle){
            this.props.enqueueSnackbar('Debes agregar un título!', { variant: 'warning' });
            return false;
        }

        this.setState({loadingList: true, showDialogCreateEdit: false});
        let { publicationId, inputTitle, inputSubtitle, fileImage } = this.state;

        let formData = new FormData();
        formData.append("title", inputTitle);
        formData.append("subtitle", inputSubtitle);
        formData.append('image', fileImage);

        axios({
            method: 'post',
            url: `${API}/api/publications/${publicationId}/update`,
            data: formData,
            headers: this.state.headers
        })
        .then(res => {
            if(res.data.success){
                this.props.enqueueSnackbar(res.data.success, { variant: 'success' });
                this.setState({
                    publicationId: null,
                    inputTitle: '',
                    inputSubtitle: '',
                    image: null,
                    fileImage: null,
                });
                this.getPublications();
            }else{
                this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
                this.setState({showDialogCreateEdit: true});
            }
        })
        .catch((err) => {
            this.props.enqueueSnackbar('Ocurrió un error en nuestro servidor!', { variant: 'error' });
        })
        .finally(() => this.setState({loadingList: false}));
    }

    handleDelete = () => {
        this.setState({showDialogDelete: false, publicationId: null, loadingList: true})
        let options = {
          headers: this.state.headers
        }
        axios.get(`${API}/api/publications/${this.state.publicationId}/delete`, options)
        .then(res => {
          if(res.data.success){
            this.getPublications();
            this.props.enqueueSnackbar(res.data.success, { variant: 'success' });
          }else{
            this.props.enqueueSnackbar(res.data.error, { variant: 'error' });
            console.log(res.data.message);
          }
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => this.setState({loadingList: false}));
    }

    render() {
        // Si no tiene permiso redirecciona al dashboard
        if(this.state.permission === undefined){
            return <Redirect to="/dashboard" />
        }
        
        return (
            <>
                <div className='app'>
                    <Sidebar toggled={this.state.sidebarToggled} onToggle={ () => this.setState({ sidebarToggled: !this.state.sidebarToggled }) }/>
                    <main style={{padding: 10}}>
                        <Navbar
                            title={
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="flex-start"
                                >
                                    <Grid item>
                                        <IoIosMenu className="btn-toggle" size={40} onClick={() => this.setState({ sidebarToggled: !this.state.sidebarToggled })}/>
                                    </Grid>
                                    <Grid item>
                                        <h1 style={{marginLeft: 25, marginTop: 6, color: 'rgba(0,0,0,0.6)', fontSize: 25}}>
                                            Publicidades
                                        </h1>
                                    </Grid>
                                </Grid>
                            }
                        />

                        <Grid style={{marginTop: 20}}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button variant="contained" onClick={ () => this.setState({showDialogCreateEdit: true, publicationId: null}) } style={{backgroundColor: color.primary, color: 'white'}} endIcon={<IoIosAddCircle/>} > Crear nueva</Button>
                            </div>
                            
                            <div style={{ marginTop: 20, marginBottom: 50 }}>
                                <Paper >
                                    { this.state.loadingList && <LoadingList /> }
                                    { this.state.tableRows.length === 0 && !this.state.loadingList && <EmptyList /> }
                                    { this.state.tableRows.length > 0 &&
                                        <>
                                            <TableContainer>
                                                <Table stickyHeader aria-label="sticky table">
                                                <TableHead>
                                                    <TableRow>
                                                    {tableColumns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth, backgroundColor: color.primary, color: 'white', fontWeight: 500 }}
                                                        >
                                                        {column.label}
                                                        </TableCell>
                                                    ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.tableRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                {tableColumns.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                    <TableCell key={column.id} align={column.align}>
                                                                        {column.format && typeof value === 'number' ? column.format(value) : value}
                                                                    </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                                </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <TablePagination
                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                component="div"
                                                count={this.state.tableRows.length}
                                                rowsPerPage={this.state.rowsPerPage}
                                                page={this.state.page}
                                                labelRowsPerPage='Items por página'
                                                onChangePage={(event, newPage) => this.setState({page: newPage})}
                                                onChangeRowsPerPage={(event) => this.setState({rowsPerPage: +event.target.value, page: 0})}
                                            />
                                        </>
                                    }
                                </Paper>
                            </div>
                        </Grid>
                    </main>

                    <Dialog
                        open={ this.state.showDialogCreateEdit }
                        TransitionComponent={ transition }
                        keepMounted
                        onClose={() => this.setState({showDialogCreateEdit: false}) }
                        fullWidth
                        maxWidth='sm'
                    >
                        <DialogTitle id="alert-dialog-publication-title">{ this.state.publicationId ? 'Editar' : 'Agregar' } publicidad</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item sm={5}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                autoFocus={true}
                                                required={true}
                                                fullWidth
                                                label="Título de la publicidad"
                                                value={ this.state.inputTitle }
                                                onChange={ event => this.setState({inputTitle: event.target.value}) }
                                                inputProps={{ maxLength: 255 }}
                                                multiline
                                                rows={3}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                label="Subtítulo de la publicidad"
                                                value={ this.state.inputSubtitle }
                                                onChange={ event => this.setState({inputSubtitle: event.target.value}) }
                                                inputProps={{ maxLength: 255 }}
                                                multiline
                                                rows={7}
                                            />
                                        </Grid>
                                        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <input accept="image/*" style={{ display: 'none' }} id="input-banner" type="file" onChange={ this.hanldeImage } />
                                            <label htmlFor="input-banner">
                                                <Tooltip title="Click para cambiar banner" >
                                                    <IconButton aria-label="Click para cambiar banner" component="span">
                                                        <IoIosCamera color='#ccc' size={30} />
                                                    </IconButton>
                                                </Tooltip>
                                            </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={7}>
                                    <Card>
                                        <CardHeader
                                            avatar={
                                            <Avatar aria-label='image' src={ this.state.company.logos ? `${API}/storage/${this.state.company.logos.replace('.', '-cropped.')}` : `${API}/images/logo.png` } style={{width: 60, height: 60}} />
                                            }
                                            title={ this.state.inputTitle ? this.state.inputTitle : "Shrimp and Chorizo Paella" }
                                            subheader={
                                            <small style={{fontSize:10}}>{this.state.inputSubtitle ? this.state.inputSubtitle : "This impressive paella is a perfect party dish and a fun meal to cook together with yourguests. Add 1 cup of frozen peas along with the mussels, if you like"}</small>}
                                        />
                                        <CardMedia
                                            style={{paddingTop: '100%'}}
                                            image={this.state.image ? this.state.image : "/img/publication-default.jpg"}
                                            title="Banner de la publicidad"
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({showDialogCreateEdit: false}) } >
                                Cancelar
                            </Button>
                        <Button onClick={ this.state.publicationId ? this.handleSubmitEdit : this.handleSubmitCreate } style={{ color: color.primary }} >
                                Guardar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Delete dialog */}
                <Dialog
                  open={ this.state.showDialogDelete }
                  TransitionComponent={ transition }
                  onClose={ () => this.setState({ showDialogDelete: false }) }
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Confirmar eliminación"}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Ésta acción eliminará de forma permanente el registro y no podrás usarlo en el futuro.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={ () => this.setState({ showDialogDelete: false }) } >
                        Cancelar
                      </Button>
                      <Button onClick={ this.handleDelete } style={{color: color.red}}>
                        Eliminar
                      </Button>
                    </DialogActions>
                </Dialog>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
    }
}

export default connect(mapStateToProps)(withSnackbar(PublicationsList));