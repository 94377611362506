import React, { Component } from 'react';
import {
    Grid,
    Typography,
    // Cards
    Card,
    CardHeader,
    Avatar,
    CardMedia,
    Button
} from '@material-ui/core';

import { IoIosVolumeHigh } from "react-icons/io";
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { io } from "socket.io-client";
import { disableBodyScroll } from 'body-scroll-lock';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './tickets.css';

import { env } from '../../../env';

const { API, SOCKET_IO, color } = env;
const socket = io(SOCKET_IO);
const audio = new Audio(`${API}/sounds/alert1.mp3`)

class Tickets extends Component {
    constructor(props){
        super(props)
        this.state = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${this.props.authSession.token}`
            },
            company: props.authSession.company,
            branch: props.authSession.branch,
            configSales: props.globalConfig.sales,
            listTickets: [],
            listPublications: [],
            soundActivate: false
        }
    }

    componentDidMount(){
        document.title = "Tickets | " + env.appName;
        disableBodyScroll(document);

        this.getPublications();
        this.getSales();

        socket.on(`sales tickets ${this.state.branch.id}`, data => {
            if(data.number){
                // Tipo de alerta de ticket listo
                switch (this.state.configSales.alertTickets) {
                    case 'voice':
                        let textoAEscuchar = 'Ticket número '+data.number;
                        let mensaje = new SpeechSynthesisUtterance();
                        mensaje.volume = 1;
                        mensaje.rate = 0.8;
                        mensaje.text = textoAEscuchar;
                        mensaje.pitch = 1;
                        speechSynthesis.speak(mensaje);
                    break;
                    case 'sound':
                        audio.play();
                    break;
                
                    default:
                    break;
                }
            }
            this.getSales();
          });
    }

    getSales(){
        fetch(`${API}/api/branch/${this.state.branch.id}/sales/tickets/list`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
          this.setState({listTickets: res.sales});
        })
        .catch(error => {
            console.log(error);
        });
    }

    getPublications(){
        fetch(`${API}/api/company/${this.state.company.id}/publications`, {headers: this.state.headers})
        .then(res => res.json())
        .then(res => {
          this.setState({listPublications: res.publications});
        })
        .catch(error => console.log(error));
    }

    render(){
        return (
            <>
                <div
                    style={{
                        backgroundImage: `url(../../img/background-tickets.jpeg)`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        border: 0,
                        color: 'white',
                        height: '100%',
                    }}
                >
                    <Grid container style={{ backgroundColor: 'rgba(0,0,0,0.7)', height: '100%', }}>
                        <Grid item md={5}>
                            <div style={{padding: 20 }}>
                                <Carousel
                                    autoPlay
                                    infiniteLoop
                                    emulateTouch
                                    interval={this.state.configSales.timeSliderTickets ? this.state.configSales.timeSliderTickets *1000 : 10000}
                                    showArrows={false}
                                    showStatus={false}
                                    showIndicators={false}
                                    showThumbs={false}
                                >
                                    {
                                    this.state.listPublications.map((publication, index) =>
                                        <div
                                            className="slide"
                                            key={index}
                                        >
                                            <RecipeReviewCard company={this.state.company} publication={publication} />
                                        </div>
                                    )}
                                </Carousel>
                            </div>
                            <div style={{ position: 'fixed', left: 10, bottom: 10, background: 'rgba(0,0,0,0.3)', padding: '3px 25px', borderRadius: 3 }}>
                                <h3 style={{color: 'white', margin: 0}}><img src="/favicon.ico" style={{width: 50}} alt="Gerente.rest" /> gerente.rest</h3>
                            </div>
                        </Grid>
                        <Grid item md={7}>
                            <Grid container style={{marginTop: 20}}>
                                {
                                    this.state.listTickets.map(ticket => {
                                        return(
                                            <Grid item md={6} className={ticket.status.id == 3 ? 'parpadea': ''} style={{ border: '10px solid white' }} key={ ticket.id }>
                                                <Typography variant='h1' style={{ color: 'white', textAlign: 'center' }}>T-{ ticket.sale_number }</Typography>
                                                <Typography variant='subtitle1' style={{marginLeft:10}}>{ moment(ticket.created_at).fromNow() }</Typography>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>

                        { !this.state.soundActivate && (this.state.configSales.alertTickets == 'voice' || this.state.configSales.alertTickets == 'sound') &&
                            <Grid container direction="column" justify="center" alignItems="center" style={{position: 'fixed', top: 0, left: 0, zIndex: 10, width: '100h%', height: '100vh', backgroundColor: 'rgba(0,0,0,0.8)'}}>
                                <Typography variant="h6" color="textSecondary" style={{ margin: 10, color: 'white'}}>Para activar el sonido debes presionar el siguiente botón</Typography>
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: color.primary, color: 'white' }}
                                    endIcon={<IoIosVolumeHigh size={30} />}
                                    onClick={ event => this.setState({soundActivate: true}) }
                                >
                                    Activa sonido
                                </Button>
                            </Grid>
                        }
                  </Grid>
                </div>
            </>
        );
    }
}

const RecipeReviewCard = (props) => {
    let { company, publication } = props;
    var logo = company.logos ? `${API}/storage/${company.logos.replace('.', '-cropped.')}` : `${API}/images/logo.png`;
    return (
        <Card>
            <CardHeader
                avatar={
                <Avatar aria-label={publication.title} src={logo} style={{ backgroundColor: color.primary, width: 80, height: 80 }} />
                }
                title={<h3 style={{marginTop: 5}}>{ publication.title }</h3>}
                subheader={ publication.subtitle }
            />
            <CardMedia
                style={{ paddingTop: '100%' }}
                image={ publication.banner ? `${API}/storage/${publication.banner}` : "/img/publication-default.jpg" }
                title={publication.title}
            />
        </Card>
    );
}

const mapStateToProps = (state) => {
    return {
        authSession: state.authSession,
        globalConfig: state.globalConfig
    }
}

export default connect(mapStateToProps)(Tickets);
