import React, { Component } from 'react';
import {
    Grid,
    Container,
    Typography
}from '@material-ui/core';
import { Link } from "react-router-dom";

// Components
import Navbar from "../../components/navbar";
import { env } from '../../../env';

const { API } = env;

class Suspended extends Component {
    constructor(props){
        super(props)
        this.state = {
            
        }
    }

    componentDidMount(){
        document.title = "Cuenta suspendida | " + env.appName;
    }

    componentWillUnmount() {

    }

    render() {
        return (
            <>
                <div className='app'>
                    <main style={{paddingTop: 10}}>

                        <Navbar title={<h1 style={{marginLeft: 20, color: 'rgba(0,0,0,0.6)', fontSize: 30}}></h1>} />

                        <Grid>
                            <Container maxWidth="sm" style={{ marginTop: 150, textAlign: 'center', }}>
                                {/* <Typography style={{ fontSize: 100 }}>503</Typography> */}
                                <Typography variant='h3'>Cuenta suspendida</Typography>
                                <br />
                                <Typography variant='body'>Tu suscripción ha sido suspendida, por favor comunícate con el administrador del sistema para más información</Typography>
                                <div style={{ marginTop: 20 }}>
                                    <Link to="/" variant="body2">
                                        <u>Volver al inicio</u>
                                    </Link>
                                </div>
                            </Container>
                        </Grid>
                    </main>
                </div>
            </>
        );
    }
}

export default Suspended;