export const env = {
    appName: "Tu Restaurante Online",
    appDescription: "Sistema de Administración de Restaurantes",
    autor: "@AgustinMejiaM",
    DOMAIN: "https://turestaurante.online",
    API: "https://admin.turestaurante.online",
    SOCKET_IO: "https://admin.turestaurante.online:3001",
    location: {
        latitude: -14.834821,
        longitude: -64.904159,
    },
    color: {
        primary: '#28B463',
        primaryAlt: '40, 180, 99',
        secondary: '#212F3C',
        secondaryAlt: '33, 47, 60',
        red: '#E74C3C',
        violeta: '#8E44AD',
        blue: '#2980B9',
        skyBlue: '#09ABE1',
        green: '#28B463',
        darkGreen: '#138D75',
        brown: '#784212',
        yellow: '#F1C40F',
        orange: '#E67E22',
        white: '#FDFEFE',
        gray: '#95A5A6',
        black: '#17202A'
    },
    services: {
        googleMaps: 'AIzaSyC_G61K2deFMIdxuS8iN5ZuEUsyMYkXckU',
        googleOAuth: '759516342303-dv1pvb698n5udhud8r7juv6ddvhqdaj5.apps.googleusercontent.com',
        facebookOAuth: '2989546164659083',
    },
    contact: {
        'developer_name': 'DesarrolloCreativo',
        'developer_url': 'https://desarrollocreativo.dev',
        'email': 'desarrollocreativodev@gmail.com',
        'facebook': 'https://www.facebook.com/desarrollocreativodev',
        'whatsapp': '+591 60236694'
    },
    copyright: {
        'name': 'DesarrolloCreativo',
        'link': 'https://desarrollocreativo.dev'
    }
}

export const strRandom = (length) => {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
